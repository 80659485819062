
.dealer-overview-container {
  display: flex;  
  flex-wrap: wrap;
  .dealer-overview {
    margin: 0 1rem 1rem 0;
    & .ui.form > .fields > .field.disabled > label:after {
      display: none;
    }

    > .cin.loadable {
      display: flex;
      flex-direction: row;
    }

    .dealer-form-container {
      display: flex;
      flex-grow: 1;
      > .no-disable-box {
        width: 100%;
      }
    }
    .edit-button-container {
      display: flex;
      align-items: start;
    }

    &.details {
      width: 45rem;
      @include breakpoint-sm {
        width: 100%;
      }
    }
    &.logo, &.header-image {
      width: 20rem;
      height: max-content;
      @include breakpoint-sm {
        width: 100%;
        .ui.image {
          width: 100%;
        }
      }
    }
  }
  div.auth-signatories {
    margin-top: 1.5em;
    div.action-bar {
      button {
        margin: 0;
        span {
          &:not(:first-child) {
            text-align: right;
            margin-left: 0.5em;
          }
        }
      }
    }
  }
}
.sheet-summary {
  padding: 0 0.5rem 0.1rem 0.5rem;
  background-color: $color-white;

  .sheet-volume-reduction {
    padding: 0 0.25em 1.25em 0.25em;
    > h4 {
      margin-bottom: 0.25rem;
    }
  }

  & .summary-statement {
    padding: 0.25em 0;
    & .summary-statement-text {
      padding-right: 1em;
      text-align: left;
      line-height: 1rem;
      margin-bottom: 0.25rem;
      font-weight: normal;
    }
    & h5 {
      margin-top: 0;
      margin-bottom: 0.25rem;
      align-self: flex-start;
    }

    @include customBreakpoint("min-width: 1351px") {
      display: flex;
      text-align: end;
      justify-content: space-between;
    }

    @include breakpoint-sm {
      display: flex;
      text-align: end;
      justify-content: space-between;
    }
  }

  .excess-allocation {
    background-color: $colour-ccinfo-yellow-grey-light;
    border-color: darken($colour-ccinfo-yellow-grey-light, 10%);
    border-style: solid;
    border-width: 1px;
    padding: 0.75em 0.75em 0.1em 0.75em;
    margin-bottom: 1em !important;
  }
  & .summary-agreement {
    background: $color-lighter-blue;
    padding: 0.25em;
    margin-bottom: 1em;
    line-height: 1rem;
    &.incentive-info {
      background-color: $colour-ccinfo-yellow-grey-dark;
      border-color: darken($colour-ccinfo-yellow-grey-dark, 10%);
      border-style: solid;
      border-width: 1px;
      padding: 0.75em 0.75em 0.75em 0.25em;

      div.incentive-row {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding-bottom: 0.75em;
        > div {
          width: 7em !important;
        }
        &.wrap {
          flex-flow: column;
        }

        label {
          width: 60% !important;
        }
        > .field {
          max-width: fit-content;
          > .ui.labeled.input {
            > .label {
              padding-bottom: 1.15em !important;
              padding-top: 0.1em !important;
              padding-left: 0.5em !important;
              padding-right: 0.45em !important;
              font-size: 0.85em !important;
            }
          }
          > .ui.input {
            width: 100% !important;
            height: 1.35em !important;
          }
          > .ui.checkbox {
            input:checked ~ label:after {
              color: green !important;
            }
            label {
              font-weight: lighter;
            }
          }
        }
        span {
          &.number-formatter {
            font-weight: 700;
          }
          strong {
            font-weight: 700;
            color: $color-red;
            text-transform: uppercase;
            &:before,
            &:after {
              content: " ";
            }
          }
        }
        .includeExcessAndVolumeCredit {
          display: flex;
          margin-left: 1.5em;
          height: 10px !important;
          > .field {
            margin-right: 5px !important;
            width: 18px;
            margin-left: inherit;
            > .ui.checkbox {
              input:checked ~ label:after {
                color: green !important;
              }
            }
          }

          span {
            margin-top: 7px;
          }
        }
      }
    }
    & .sheet-payment {
      padding-bottom: 0.75em;
    }
    & .cin.number-formatter {
      font-weight: bold;
      font-size: 14px;
    }
    & .sheet-payment {
      display: grid;
    }
    .ui.button {
      margin-left: 0;
    }
  }

  & .cin.number-formatter {
    transition: color 0.25s;
    &.summary-figure-loading {
      display: none;
    }
  }

  span.error {
    margin-top: 0.5em;
  }
}
.ui.active.loader.inline-loader.paragraph {
  display: inline-block;
}

.summary-loader {
  padding-bottom: 5em;
}

.lock-message {
  padding: 0.5rem 1rem !important;
  .icon {
    font-size: 2em !important;
  }
}

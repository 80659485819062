body,
#root {
  background-color: $background-color;
  height: 100%;
  width: 100%;
}

body {
  min-height: 700px;
}

//PAGE CONTENT

.cin.page-content {
  max-width: 1900px !important;
  & > .column:not(.compact) {
    margin: 0.5rem !important;
  }
}

div.ui.modal {
  .ui.form {
    div.field.disabled {
      /*
      * deliberately override Semantic's default `opacity` values
      * for disabled inputs, and don't display the 'required asterisk'
      * when a field is required but disabled
      */
      opacity: 1;
      > label {
        opacity: 1;
        &::after {
          content: "";
        }
      }
    }
  }
  .content > .dimmable.dimmed {
    /*
    * edge-case where an otherwise empty modal will collapse
    * to the point where dimmers used for loading spinners etc.
    * have a height of 0, so no indication to user that it
    * is actually doing something
    */
    min-height: 5em;
  }
}

.large-modal {
  width: 1100px !important;
}

.content-container {
  background: white;
  border-top: 5px solid $color-light-purple;
  border-radius: 5px;
  box-shadow: 5px 5px 22px #0000001a;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  &.not-list {
    padding: 0.5em;
  }

  & footer {
    position: relative;
    & > .results-counter {
      position: absolute;
      left: 0;
      color: $color-standard-blue;
      opacity: 0.62;
      padding-left: 1.5em;
      font-weight: normal;
    }
  }

  & .action-bar.cin {
    margin-bottom: 0;
    align-items: center;
    flex-wrap: wrap;

    & .ui.button.icon {
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
    }

    & h1 {
      margin-bottom: 0;
      margin-right: 2rem;
    }
    & .input.search input[type="text"] {
      border-radius: 20px;
    }
    @include breakpoint-sm {
      & h1 {
        margin-bottom: 1rem;
        width: 100%;
      }
      & .action-bar-item {
        width: 100%;
        & .input.ui.search {
          width: 100% !important;
          margin-bottom: 1rem;
        }
        &.right {
          text-align: left;
          > button {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .action-bar-div {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    flex-wrap: wrap;
  }

  & .ui.selectable.table {
    border: none;
    border-bottom: 1px solid rgba($light-grey, 0.5);
    margin-top: 0;

    & thead {
      border: none;

      > tr > th:first-child {
        padding-left: 2em;
      }
      & th {
        background: $white;
        font-weight: bold;
        font-size: 16px;
        color: $text-color;
        padding-top: 0.25rem;
        padding-bottom: 0.25em;
      }
    }
    & tbody {
      tr {
        transition: background 0.25s ease;

        & td:first-child {
          padding-left: 2em;
        }
        & td {
          border-top-color: transparent;
          padding-top: 0;
          padding-bottom: 0;
        }
        > td.right.aligned.hoverable {
          > .ui.item.dropdown > i {
            font-size: 2em;
            color: $color-light-purple;
            &::before {
              top: 0.1em;
              position: relative;
            }
          }
        }

        &:nth-child(even) {
          background: $table-alternate-row-grey;
        }

        &:hover {
          background: $color-lighter-blue !important;
          color: $text-color !important;
        }
      }
    }
    &.sheets-list {
      tr {
        > td {
          padding-top: 0.25em;
          padding-bottom: 0.25em;
        }
      }
    }
  }

  & .ui.grid {
    margin: 0 1.5rem;
    > .row {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }
}

.cin.notification-stack {
  &.not-empty {
    // Only set properties when there are some items to render, as padding for example causes the notification stack to appear even without any items present.
    z-index: 2050;
    top: calc(50% - 10rem);
    left: calc(50% - 15rem);
    background-color: $color-grey-6;
    opacity: 0.95;
    padding: 1rem;
    border-radius: 5px;
    height: max-content;
    box-shadow: 5px 5px 22px $border-shadow-color;
    position: absolute;
  }

  .ui.error.message {
    border-width: 4px;
    border-style: solid;
    color: $color-error-red !important;
    .header {
      color: $color-error-red !important;
      font-size: 22px;
    }
    p {
      font-size: 18px;
    }
    i {
      color: $color-error-red !important;
    }
  }
}

// List Pages

.principal-list {
  //style for primary buttons w/ icon
  & .ui.icon.primary.button {
    display: flex;
    & img {
      padding-right: 0.5em;
    }
  }

  .cin.action-bar-item.right {
    > .ui.primary.button {
      margin-right: 1.5em;
      margin-left: 0;
    }

    @include breakpoint-sm {
      padding-left: 2rem;
    }
  }

  & .cin.action-bar-item.fluid {
    padding-left: 2em;
  }
}

//LINKS
a {
  cursor: pointer;
  color: $color-light-blue;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    color: $color-blue;
  }
  &.small {
    font-size: 13px;
  }
  &.ui.label,
  &.ui.label:hover {
    border-radius: 0;
    border: 0;
    box-shadow: none !important;
    background: $color-standard-blue;
    color: $white;
    & > .icon.delete {
      opacity: 1;
    }
  }
}

.environment-banner {
  background-color: $red;
  border: 1px solid white;
  color: white;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 36px;
  line-height: 30px;
  opacity: 0.6;
  position: fixed;
  left: -130px;
  text-align: center;
  bottom: 50px;
  transform: rotate(45deg);
  width: 400px;
  z-index: 10000;
  pointer-events: none;
}

.load-more-button {
  width: 100%;

  > .ui.button {
    display: block;
    margin: 0 auto;

    &.disabled,
    &:disabled {
      opacity: 1 !important;
    }
  }
}

//BUTTONS
.ui.button.primary {
  background-color: $color-purple;
  font-size: 17px;
  color: white;
  border-radius: 3px;
  font-weight: normal;
  padding: 0.5em;
  margin: 0.5em;
  display: flex;

  & img {
    padding-left: 0.3em;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $color-pink;
    color: white;
  }

  &.loading > div > img {
    padding-left: 1.5em !important;
  }
}

//pagination
.ui.pagination.menu {
  border: none;
  box-shadow: none;
  min-height: 1.5rem;
  background: transparent;
  margin: 0 auto;

  & .item {
    color: $text-color;
    border: none;
    min-width: 0.3rem;
    padding: 0 0.5rem !important;
    border-radius: 2px;
    transition: none;
    &:before {
      content: none;
    }
    &.active {
      background-color: $color-light-purple;
      color: #ffffff;
      font-weight: bold;
    }
  }
}

.table-pagination {
  display: flex;
}

// side panel
.cin.side-panel.right.visible {
  width: 500px;
  padding: 2em;
  overflow: auto;
  a.disabled {
    color: $color-grey-2;
    cursor: default;
  }
  & .ui.header {
    font-size: 20px;
    font-family: "Barlow";
    color: $text-color;
    font-weight: normal;
  }

  & .ui.basic.circular.icon.button.close-panel > .close.icon {
    color: black;
  }

  @include breakpoint-sm {
    width: 100%;
  }
}

// style titles with a button
div.page-title-with-action {
  display: flex;
  justify-content: space-between;
  align-items: end;
  .ui.button {
    align-items: center;
  }
}

i.icon[role="button"] {
  cursor: pointer;
}

.icon-only-button {
  background-color: unset !important;
  padding: 0em !important;
  > img.ui.image {
    max-width: initial;
  }
}

// datepicker

.react-datepicker {
  font-family: $font-name, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  & .react-datepicker__header {
    background-color: $color-grey-1;
  }
  & .react-datepicker__navigation--previous {
    border-right-color: $color-grey-2;
  }
  & .react-datepicker__navigation--next {
    border-left-color: $color-grey-2;
  }
  & .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: $primary-color;
  }
  & .react-datepicker__triangle,
  .react-datepicker__triangle::before {
    border-bottom-color: $color-grey-1 !important;
  }
}

// fix to temporally disable overflow scroll.
.disable-overflow {
  overflow: unset !important;
  margin-bottom: 0.9em !important;

  &.no-margin {
    margin-bottom: 0em !important;
  }
}

// typeAheadlookup in headers
.header-dropdown {
  display: inline-block;
  max-width: 50rem;
  > div.ui.dropdown.search,
  > div.ui.dropdown.search div.text {
    min-width: 15rem;
  }
  input.search {
    font-family: $font-name, "Helvetica Neue", Arial, Helvetica, sans-serif;
    line-height: 1.75rem;
    font-weight: bold;
  }
}

// force modals to appear above slide out menu
.ui.page.modals.dimmer.transition.visible.active {
  z-index: 2002 !important;
}

//display priority for modals
.displayPriority1 {
  z-index: 2001 !important;
}

.displayPriority2 {
  z-index: 2002 !important;
}

.displayPriority3 {
  z-index: 2003 !important;
}

.displayPriority4 {
  z-index: 2004 !important;
}

.displayPriority5 {
  z-index: 2005 !important;
}

// make actionmenu items smaller
.ultra-compact-action-menu .ui.menu .item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.flex {
  display: flex;
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
}

.auto-margin {
  margin: auto;
}
.cin.side-column {
  > .column {
    padding-right: 0.25em !important;
    padding-left: 0.75rem;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    width: 12rem;

    @include breakpoint-sm {
      width: 0;
      padding: 0 !important;
      margin: 0;
      .cin.nav-menu {
        display: none !important;
      }
    }
  }
  .content {
    padding-left: 0.25em !important;
    @include breakpoint-sm {
      width: 100%;
      margin-top: 2rem;
    }
  }
}

.navigate-back {
  padding-bottom: 0.25em;
  display: flex;
  font-family: "Barlow";

  a {
    font-size: 18px;
    text-decoration: underline;
    font-weight: normal;
    color: $color-dark-purple;
  }

  @include breakpoint-sm {
    padding: 1rem 1.5rem;
    width: 15rem;
  }

  & img {
    padding-right: 1em;
  }
}

.edit-button.ui.icon.button {
  background: inherit;
  padding: 0.25em;
  margin: 0;
  color: black;
}
.cin.number-formatter.negative {
  color: $color-red !important;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 200px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px;
  position: absolute;
  top: -4em;
  left: -4em;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  border: 1px solid $color-grey-2;
}

div.ui.modal.custom-actions {
  div.actions {
    //hide the default action container
    display: none !important;
    * {
      display: inherit !important;
    }
  }
  > div.content {
    padding: 0;
    //*** stolen from Semantic to match the default action container
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;
    //***
    > div.dimmable {
      > *:not(div.dimmer) {
        padding: 1rem 1.5rem;
      }
    }
  }
  div.custom-action-content {
    padding: 1rem 1.5rem;
    button {
      display: inline-block;
    }
    :not(:last-child) {
      padding-bottom: 0.75rem;
    }
    div.custom-action-footer {
      border-top: 1px solid $colour-translucent-dark-grey;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding: 0.75rem 1.5rem 0;
    }
  }
}

.upper {
  text-transform: uppercase;
}

.right-align {
  float: right;
  text-align: right;
}

.right-align button {
  float: right;
}

.clear {
  width: 100%;
  height: 2px;
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.full-width,
textarea.full-width {
  width: 100% !important;
}

.red {
  color: $color-red;
  .field .ui.input input {
    color: $color-red;
  }
}

.red-button {
  background-color: $color-red !important;
}

.center-align {
  text-align: center !important;
}

.vertical-scrollbar {
  overflow-y: auto;
  &.small {
    max-height: 170px;
  }
  &.medium {
    max-height: 250px;
  }
  &.large {
    max-height: 350px;
  }
  .ui.table thead {
    position: sticky !important;
    top: 0px;
    z-index: 1;
  }
}

tr {
  &.green-highlight td {
    background-color: $background-green;
  }
  &.purple-highlight td {
    background-color: $color-bg-lighter-purple;
  }
  &.blue-highlight td {
    background-color: $background-blue;
  }
  &.grey-highlight td {
    background-color: $color-grey-0;
  }
  &.red-highlight td {
    background-color: $color-highlight-red;
  }
}

.ui.dropdown .menu .selected.item {
  background-color: $color-light-blue-alt;
}

.align-text-left {
  text-align: left;
}

.ui.button.primary.authenticator-button {
  width: 100%;
  margin-left: 0;
  display: block !important;
}

.ui.form .disabled.field {
  opacity: 1 !important;
}
.ui.message p {
  white-space: pre-line !important;
}

.content-container.sheet-step.paper-step {
  border-top-color: $color-paper-inclusive;
}

.paper-inclusive-step { 
  .sheet-step-header {
    h5 {
      margin: 0 0 0.5em 0;
    } 
    span.included {
      margin-left: 2em !important;
      font-size: 14px;
      color: $color-green;
    }  
  }  

  div.content {
    padding-top: 0 !important;
  }
  .field {
    margin-bottom: 0 !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

.overflow {
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $scrollbar-color;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
}
  .paper-inclusive-subgrid {
      
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $scrollbar-color;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0);
    }
    .ui.selection.dropdown {
      line-height: 0.8em;
    }
  }

  .customer-current-spend {    
    h5 {
      font-weight: bold;
      text-decoration: underline;
      margin-left: 2em;
    }
    .headers {
      margin-left: 2em;
      display: flex;
      &.no-justify {
        justify-content: unset !important;
      }
      .description, .sku {
        width: 12.6em !important;
        min-width: 12.6em !important;
      }      
      .note {
        width: 15.6em !important;
        min-width: 15.6em !important;
      }
      .charges {
        width: 9.6em !important;
        min-width: 9.6em !important;
      }
      .reams-per-box {
        padding-left: 0.25em;
      }
      div {
        text-align: center;
        width: 8em;
        min-width: 8em;
        margin: 0 12px 5px 0;
      }
      div.small {
        text-align: center;
        white-space: nowrap;
        width: 6em;
        min-width: 6em;
        margin: 0 12px 5px 0;
      }
    }
    .current-spend-container {
      display: flex;
      .paper-size {
        max-width: 3em;
        min-width: 2em;
        font-weight: bold;
        margin-right: 5px;
        width: 3em;        
      }
      .current-spend-columns {
        display: flex;
        .charges {
          width: 9.6em !important;
        }
       
        .value {
          width: 8em;
          margin: 0 12px 5px 0;
          .ui.dropdown, .ui.disabled.input, .ui.input {
            width: 8em !important;
          }
          .ui.disabled.input,          

          .ui.disabled.input.full-opacity{
            opacity: 1!important;
          }
        }
        .value.small {
          width: 6em;
          margin: 0 12px 5px 0;
          .ui.dropdown, .ui.disabled.input, .ui.input {            
            width: 6em !important;
            &.labeled.with-right-label {
              width: 6.5em !important;
            }
          }          
        }
         .description {
          width: 13.6em !important;
          display: flex;
          .ui.input, .ui.disabled.input, .ui.dropdown {
            width: 12em !important;
          }
          .icon.close {
            margin: 0.25em 0 0 0.25em;
            cursor: pointer;

          }
        }

        .sku {
          width: 12.6em !important;
          min-width: 12.6em !important;
          .ui.input, .ui.disabled.input, .ui.dropdown {
            width: 11.25em !important;
          }
        }

        .note {
          display: flex;
          width: 15.6em !important;
          .ui.input, .ui.disabled.input, .ui.dropdown {
            width: 13.6em !important;
            > input {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .disabled.field {
            opacity: 1;
          }
          i.icon.info {
            padding: 0.25em 0 0 0.25em;
            cursor: pointer;
          }
        }
      }
    }
  }

  // being used
  .units-bar {
    display: flex;
    justify-content: space-between;
    background: $sheet-customer-view-model;
    color: white;
  }
  .pink-bar {
    margin: 0.5em 0 1em 0;
    height: 5px;
    background: pink;
  }
  .radio-groups {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: bold;
    }
    .left-side {
    }
    .right-side {
      display: flex;
      .sheet-radio-title {
        p {
          color: $color-admin-green;
        }
      }
      .sheet-radio-group {
        &.min-logic {
          margin-left: 0.4em !important;
        }
      }
    }
  }
  .backdated-rewrites {
    margin: 5px 0 5px 0;
    color: $paper-inclusive-dark-red;
    text-decoration: underline;
    font-size: 18px;
    font-weight: bold;
  }

  .delivery-charge {
    display: flex;
    margin: 1em 0 2em 0;
    align-items: center;
    .field {
      margin: 0 1em 0 1em;
      width: 8em;
    }
  }
  .additional-totals {
    p {
      width: 25em;
      .cin {
        float: right;
      }
    }
  }

  table.paper-adjustments {
    tr {
      td {
        padding-bottom: 0.25em;
        &:nth-child(2) {
          padding-left: 2em;
        }
        &:nth-child(3) {
          padding-left: 4em;
        }
      }
    }
  }
  .contracted-managed-pi {
    h5 {
      font-weight: bold;
      text-decoration: underline;
      margin-left: 1.8em;
    }
  }
  .paper-income {
    width: fit-content;
    label {
      color: green;
    }
  }
  .additional-costs {
  
    .additional-terms {
      min-width: 30em !important;
      width: 30em !important;
      textarea {
        border: 1px solid $color-pink;
      }
      textarea:focus {
        border: $color-light-blue-hover 1px solid;
      }
    }

    .add-cost-headers {
      display: flex;
      margin-left: 1em;
      div {
        text-align: center;
        min-width: 10em;
        width: 10em;
        margin: 0 10px 0 10px;
      }
    }
    .add-cost-all-fields {
      display: flex;
      .array {
        min-width: 47.5em;
        justify-content: space-between;
        .add-cost-fields {
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between;
          .remove-container {
            width: 2em;
            align-self: center;
            .button {
              background: transparent;
              padding: 0;
              vertical-align: middle;
            }
          }
          .field {
            margin: 0 10px 0 0;
            width: 10em !important;
            .ui.input {
              width: 10em !important;
            }
          }
        }
      }
      .text-area-wrapper {
        height: calc(100% - 5px);
      }
    }
    .additional-cost-adder {
      .ui.button {
        width: max-content;
        color: $color-light-blue !important;
        font-weight: normal !important;
        background-color: transparent !important;
        padding: 0 !important;
        height: 1.5rem;
      }
    }
  }

  .ui.dropdown,
  .ui.input > input {
    border: 1px solid $color-pink;
    border-radius: 5px;
  }
  .field {
    font-size: 16px;
  }
  .top {
    display: flex;
    justify-content: flex-start;
    .field {
      margin-right: 0;
      margin-bottom: 0;
    }
    .fix-bool-container {
      .paper-inc-not-grey-out {
        opacity: 1 !important;
      }
      .fixed-input-indicator {
        margin-right: 1em;
      }
    }
  }

  & .ui.grid.paper-inclusive-subgrid {
    margin-left: 0;
    margin-top: -0.5rem;
    > div.column {
      padding: 0 0.5rem;

      &:first-child {
        font-weight: bold;
        font-size: 14px;
      }

      > div.row {
        min-height: 1.85rem;
        display: flex;
        align-items: center;
        > div.field {
          max-width: 100%;
        }
        & sup {
          margin-left: 0.1rem;
        }

        &:first-child {
          font-weight: bold;
          min-height: unset;
          justify-content: center;
        }
      }
    }
    // only required at x small
    @include customBreakpoint("max-width: 575px") {
      overflow-x: auto;
      width: 100%;
      flex-wrap: nowrap;
      > .column {
        min-width: 10rem;
      }
    }
  }

  & div.terms-and-totaliser {
    width: 60%;
    margin-top: 0.5em;
    @include breakpoint-not-desktop {
      width: 100%;
    }

    * {
      margin-bottom: 0 !important;
    }

    & textarea {
      width: 100% !important;
    }
  }

  & .calculable-field {
    transition: color 0.25s;
    &.value-loading {
      display: none;
    }
  }

  p > .ui.loader.inline-loader,
  .paper-totaliser .inline-loader,
  .paper-totaliser .calculable-field {
    float: right;
  }

  @include laptop {
    .ui.grid.paper-inclusive-subgrid {
      max-width: 36rem;
      .field {
        width: 10rem;
      }
    }
  }
  .ui.grid > .row {
    padding-top: 0.25rem;
  }

  // Toggle (a kind of checkbox)
  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before,
  .ui.toggle.checkbox input:checked:focus ~ .box:before,
  .ui.toggle.checkbox input:checked:focus ~ label:before {
    content: "Yes";
    padding: 0.25rem 0 0 0.65rem;
  }

  .charge-type{
    padding-right: 1em;  
    padding-top: 0.2em;
  }

  .step-edit-button{
    padding-top: 0.2em !important;
  }

}
.paper-units-totaliser {
  margin: 1em 0 0 0 !important;
  color: white;
  background: $sheet-customer-view-model !important;
  > span.paper-units {
    padding-left: 0.5em;
    width: 80%;
  }
}
span.calc-error {
  font-size: 1rem;
}
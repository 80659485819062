.content-container.sheet-step.capital-step {
  border-top-color: $color-capital;
}

.capital-step {
  .field.fix-input-container > .ui.labeled.input.fix-input-wrapper {
    width: 7em !important;
  }
  .field.fix-input-container.tiny > .ui.labeled.input.fix-input-wrapper {
    width: 3.5em !important;
  }
  .field.fix-input-container.tiny
    > .ui.fix-input-hidden.input.fix-input-wrapper {
    width: 3em !important;
  }
  .field.fix-input-container.small > .ui.labeled.input.fix-input-wrapper {
    width: 5em !important;
  }
  .field.fix-input-container.smaller > .ui.labeled.input.fix-input-wrapper {
    width: 4em !important;
  }
  .field.fix-input-container.small
    > .ui.fix-input-hidden.input.fix-input-wrapper {
    width: 4em !important;
  }
  .field.fix-input-container.medium > .ui.labeled.input.fix-input-wrapper {
    width: 9em !important;
    margin-right: 0.5em;
  }
  .field.fix-input-container.large > .ui.labeled.input.fix-input-wrapper {
    width: 11em !important;
  }

  .field.fix-input-container.small-text > .ui.labeled.input.fix-input-wrapper {
    font-size: 12;
  }

  // Move top fields up to reduce white-space, but prevent overlap getting in the way of toggling expand/collapse in header.
  .sheet-step-header {
    position: relative;
    z-index: 1;
    max-width: 72em;
    > h5:first-child {
      width: 50%;
    }
    div.header-action-bar {
      width: 40rem;
      max-width: 80%;
      > button {
        float: right;
        margin: 0;
      }
    }
  }
  &:not(.collapsed) {
    .ui.form {
      margin-top: -1rem;
      z-index: 0;
    }
  }

  .funder-types {
    min-width: 20em !important;

    display: block !important;
    margin-bottom: 0.25rem !important;
    margin-top: 1.3em !important;
    > .funder-type-label {
      display: flex;
      margin-left: 1.5em;
      > p {
        margin-bottom: 0;
        font-weight: bold;
      }
    }

    > .funder-type-options {
      display: flex;

      > div {
        display: flex;
        align-items: center;

        & .funder-type-option {
          justify-content: left;
          display: flex;
          padding-left: 1.5em;

          > .field {
            padding-left: 0;
            display: flex;
            align-items: center;
            > .ui.checkbox {
              padding-left: 0;
              margin-bottom: 0;
              margin-top: 0;
              & label {
                padding-left: 1.5rem;
                font-weight: normal;
                &:before {
                  border-color: $color-pink;
                }
                &:after {
                  color: $color-light-purple;
                  border-color: $color-pink;
                }
              }
            }
          }
        }

        &:first-child {
          min-width: 50%;
        }

        @include breakpoint-sm {
          overflow-x: auto;
          overflow-y: hidden;
        }
      }

      & span {
        font-size: 16px;
        color: $color-purple;
        font-weight: normal;
      }

      & div.total-section {
        width: 50%;
        padding: 0.5em 1em 0.5em 1em;
        & span {
          color: $black;
        }
      }

      & p {
        margin-bottom: 0;
        padding-top: 0.3em;
      }
    }
  }

  // FundingSection

  .funding-sections {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .funder {
      background-color: $sheet-funder-background;
      border-radius: 5px;
    }
    .supplier {
      background-color: $sheet-supplier-background;
      border-radius: 5px;
    }
    .cofunded {
      background-color: $sheet-co-funded-background;
      border-radius: 5px;
    }
    .funder-type-details {
      padding: 0em 0.5em;
      margin-right: 0.5rem;
      border-radius: 5px;
      width: $funder-section-width;
      margin-bottom: 0.5rem;
      @media only screen and (max-width: 1064px) {
        margin-right: 0rem;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
      }

      &:last-child {
        margin-right: 0;
      }

      .settlements {
        p {
          margin: 0em 0;
        }
        .settlement-array {
          .settlement-array-section {
            display: flex;
            margin-top: 2.5px;
            div.inline-checkbox {
              display: inline-flex;
              flex-flow: row;
              justify-content: space-evenly;
              margin-bottom: 0.25rem;
              > label {
                font-size: 13px;
                font-weight: 500;
                margin: auto 0.25em !important;
              }
              > div.field {
                margin: auto;
                > div.ui.checkbox {
                  margin: auto;
                }
              }
            }
            div.settlement-detail {
              display: flex;
              width: 40%;
              @include mobile {
                width: 100%; //flex item flow handles this nicely
              }
              div > p {
                font-size: 13px;
                font-weight: normal;
                margin-right: 0.25em;
              }
              .blue-text > div > p {
                color: $color-light-blue;
              }
              .green-text > div > p {
                color: $color-admin-green;
              }
              .ips-color-text > div > p {
                color: $color-ips-buttons;
              }
            }
            .field {
              .ui.input {
                input {
                  width: 70%;
                }
                &:not(.right-label--indicator) {
                  width: 5.5rem !important;
                  > input {
                    padding: 0.225em 0.5em !important;
                  }
                }
                &.right-label--indicator {
                  width: 6rem !important;
                }
              }
              &.fix-input-container.value > .ui.input.fix-input-wrapper {
                width: 6.75rem !important;
                &.right-label--indicator {
                  width: 5.25rem !important;
                }
              }
            }
            > button.ui.icon.button.icon-only-button {
              margin-left: 0.25rem;
            }
          }
        }
      }

      .funder-amounts .field,
      .funder-amounts .field > .fixed-input-responsive-label p,
      .capital-section-title p,
      .capital-section-title button,
      .settlements,
      .settlements .settlement input,
      .coterminus-rentals,
      .coterminus-rental .field > label,
      .rrp-fields,
      .rrp-fields .field,
      .rrp-fields .field > .fixed-input-responsive-label p {
        font-size: 13px;
      }

      & h5 {
        font-weight: bold;
      }

      .rrp-fields {
        justify-content: space-between;
        align-items: center;
        font-family: "Barlow";
        max-width: 16rem;
        margin-top: 1em;
        .total-costs,
        .max-allowed {
          padding-right: 0.25em;
          margin-top: -2px;
          .total-costs-label,
          .max-allowed-label {
            font-weight: bold;
          }
          .total-costs-value,
          .max-allowed-value {
            border-radius: 5px;
            padding: 0em 0.1em;
            font-weight: 500;
            height: 1.75em;
            margin-bottom: 1px;
            text-align: center;
          }
        }
        .calculable-field {
          transition: color 0.25s;
          &.value-loading {
            display: none;
          }
        }
      }

      .funder-persistent {
        .funder-persistent-details {
          display: flex;
          text-align: center;
          .funder-persistent-field-details {
            text-align: left;
            width: 9.5em;
            margin-right: 5px;
          }
        }
      }

      .funder-dealer {
        .funder-dealer-details {
          display: flex;
          text-align: center;
          .small-text {
            font-size: 12px;
          }
          .funder-dealer-field-details {
            text-align: right;
            width: 14em;
            margin-right: 5px;

            .ips-color-text {
              color: $color-ips-buttons;
            }
          }
        }
        .top-margin {
          margin-top: 10px;
        }
      }

      .cofunded-dealer {
        .cofunded-section {
          display: flex;
          .cofunded-dealer-details {
            display: flex;
            text-align: center;
            .small-text {
              font-size: 12px;
            }
            .cofunded-dealer-field-details {
              text-align: right;
              width: 6.8em;
              margin-right: 5px;
            }
            .wider {
              width: 10em !important;
            }
            .ips-color-text {
              color: $color-ips-buttons;
            }
            .thin-box {
              width: 5em;
            }
          }
        }
        .top-margin {
          margin-top: 10px;
        }
      }

      .funder-amounts {
        display: flex;
        width: auto;
        justify-content: space-between;
        .not-bold {
          p {
            font-weight: normal;
          }
        }
        .field {
          margin: 0;
          line-height: 1em;
          display: flex;
          &:not(:first-child) {
            padding-right: 2.25em;
            div.fix-input-wrapper {
              max-width: 4em;
            }
          }
          > .fixed-input-responsive-label {
            min-height: 2em;
            margin-right: 0.34em;
          }
          & div.fix-input-container {
            div.ui.labeled.input.fixed-input-wrapper > input.text {
              padding: 0.5rem !important;
            }
          }
          &.finance-selection {
            min-width: 13em;
            display: flex;
            .fixed-input-responsive-label p {
              font-weight: normal;
            }
            > .ui.input.fix-input-wrapper {
              width: 3.5em !important;
              > input {
                width: 2em !important;
              }
              &.fix-input-hidden {
                width: 3.5em !important;
              }
            }
            > .ui.input.fix-input-wrapper:not(.labeled) > input {
              width: 5em;
            }
            &:first-child {
              > div.fixed-input-responsive-label {
                width: 9.5rem;
              }
            }
          }
          &.product-capital-costs {
            width: 7.5em;
          }
        }
      }

      div.field.fix-input-container
        div.ui.labeled.input.fix-input-wrapper
        input.text {
        padding: 0.5rem !important;
      }

      & .total-section {
        width: 100%;
      }

      .settlements,
      .coterminus-rentals {
        overflow-y: hidden;
        overflow-x: auto;
        margin-bottom: 0;
        .array-error {
          margin-bottom: 0;
        }
        .CFA-headers {
          display: flex;
          text-align: center;
          .payment {
            width: 10em;
          }
          .cfa {
            width: 11em;
          }
          .min {
            width: 5em;
          }
        }
      }

      .settlement,
      .coterminus-rental {
        padding: 0.25em 0 0.25em 0;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        > div {
          padding: 0 0.5em 0 0.5em;
          width: 26em;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          &.green {
            background-color: $co-terminus-green;
          }
          &.blue {
            background-color: $co-terminus-blue;
          }
        }
      }

      .settlement {
        .field {
          margin-bottom: 0.25rem;
          label {
            margin-right: 0.25em;
          }
        }

        > div:first-child {
          padding-left: 0;
        }

        > div.inline-field {
          margin-bottom: 0rem !important;
        }

        > .inline.field:nth-child(1) > .ui.input {
          width: 8rem !important;
          > input {
            padding: 0.5em 0 !important;
          }
        }
        > .inline.field:nth-child(2) > .ui.input {
          width: auto !important;
          > input {
            width: 5rem !important;
            padding: 0.5em 0 !important;
          }
        }
        > button {
          padding: 0.25rem !important;
          display: flex;
          flex-direction: column;
        }
      }

      .coterminus-rental {
        button {
          padding: 0 !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .coterminus-rental-details {
          display: flex;
          margin-top: 2.5px;
          .not-bold {
            font-size: 14px;
            font-weight: normal;
            margin-right: 0.2em;
          }
          .green-text {
            color: $color-admin-green;
          }
          .ips-color-text {
            color: $color-ips-buttons;
          }
          .fix-input-hidden {
            width: 4em !important;
          }
          &.min-quarters {
            width: 4em !important;
            .ui.input > input {
              padding-left: 0.25em !important;
            }
          }
        }
        .field {
          margin: 0em !important;
          display: flex;
          max-height: 2.5em;
          line-height: 1em;
          > label {
            white-space: normal;
            display: flex;
            align-self: center;
            justify-content: right;
            color: $color-light-red !important;
            font-weight: normal;
            margin-right: 0.1em;
          }
          &:not(.fix-input-container) {
            input {
              width: 4rem;
            }
          }
          &.fix-input-container {
            .ui.input > input {
              padding: 0.5em 0 !important;
            }
          }
          &.agreement-number {
            label {
              width: 3em !important;
            }
            .ui.input > input {
              width: 4em !important;
            }
          }
        }
      }

      .bold {
        font-weight: bold;
      }
      .capital-section-title {
        display: flex;
        align-items: center;
        margin: 0 0 1em;
        & p {
          margin-top: 0.25em;
          margin-bottom: 0.25em;
        }
        .ui.button.wizard-title-button {
          padding-bottom: 0;
          margin: 0 1.25em 0 0;
        }
        @include mobile {
          flex-wrap: wrap;
          margin-bottom: 2rem;
        }
      }
    }
  }

  .funder-type-options > .total-section,
  .funder-type-details > .total-section {
    display: flex;
    background-color: $color-light-purple-hover;
    border-radius: 5px;
    padding: 1em 1em 0 1em;

    & p {
      width: 95%;
    }
  }

  .ui.grid.capital-grid {
    margin: 0;
    > .column {
      padding-top: 0;
      padding-left: 0;
    }
    .field > label {
      font-size: 16px !important;
    }

    @include breakpoint-md {
      // force widths of columns.
      // change this value if we
      // add/remove columns from
      // capital-grid
      > .column {
        width: 33% !important;
      }

      .capital-step .funding-sections .funder-type-details {
        width: 100% !important;
      }
    }
  }
}
div.capital-step
  > .ui.form
  > div.overall-values
  div.funding-sections
  .funder-type-details
  .funder-amounts
  .field.fix-input-container
  .ui.labeled.input.fix-input-wrapper
  > input {
  padding: 0.5em !important;
}

@media only screen and (max-width: 1064px) {
  .funder-types {
    .funder-type-options {
      .totaliser {
        margin-top: 0.5rem !important;
      }
    }
  }
  .funding-sections {
    .rrp-fields {
      max-width: 27.75em !important;
    }
  }
  .capital-step .funding-sections {
    .funder-type-details {
      width: 100% !important;
      .settlements,
      .coterminus-rentals {
        max-width: 30em;
      }

      .funder-amounts .field,
      .funder-amounts .field > .fixed-input-responsive-label p,
      .rrp-fields .field,
      .rrp-fields .field > .fixed-input-responsive-label p,
      .total-costs-label,
      .total-costs-value,
      .capital-section-title p,
      .capital-section-title button,
      .settlements,
      .settlements .settlement input,
      .coterminus-rentals,
      .coterminus-rental .field > label {
        font-size: 16px;
      }
      .settlement {
        > .inline.field:nth-child(1) > .ui.input {
          width: 9rem !important;
        }
        > .inline.field:nth-child(2) > .ui.input {
          > input {
            width: 6rem !important;
          }
        }
      }
    }
  }
}
.capital-step .sheet-step-header div.header-action-bar > button {
  margin-left: 1em !important;
}
div.capital-calc.modal {
  width: 75vw !important;
  div.active.dimmer {
    //prevent the modal close buttons being obscured by dimmer
    border-radius: 4px;
    z-index: 999;
    ~ div.custom-action-container {
      position: relative;
      z-index: 1000;
    }
  }
  div.panel {
    padding: 0.8em 1em;
    border: $colour-ccinfo-grey-dark solid 1px;
    border-radius: 4px;
    h5 {
      font-weight: 700;
    }
  }
  div.panel.dealer-calc-info,
  div.panel.cofund-calc-info {
    .row {
      margin: 1% 0;
      > .col {
        width: 17%;
        display: inline-block;
        span:last-of-type {
          margin-left: 0.5em;
          font-weight: 700;
        }
        @include customBreakpoint("max-width: 1600px") {
          width: 25%;
        }
        @include breakpoint-md {
          width: 50%;
        }
        @include breakpoint-sm {
          width: 100%;
        }
      }
      > .col.wide {
        @include customBreakpoint("min-width: 1600px") {
          width: 20%;
        }
      }
      > .panel {
        display: inline-block;
        vertical-align: top;
        padding: 0.2em;
        margin: 0 0.5%;
        table {
          background-color: inherit;
          border: none;
          th {
            padding-top: 0;
          }
          td,
          tr,
          th {
            border: none;
            background-color: inherit;
          }
          td:first-of-type {
            text-align: right;
          }
        }
      }
    }
  }
  div.panel.dealer-calc-info {
    background-color: $sheet-supplier-background;
    .row {
      > .panel {
        background-color: $sheet-supplier-background-dark;
        width: 39%;
        thead {
          display: none;
        }
        @include breakpoint-not-desktop {
          width: 55%;
          margin-top: 1%;
        }
        @include breakpoint-sm {
          width: 100%;
        }
      }
      > .panel:nth-of-type(2) {
        width: 19%;
        table td {
          text-align: left;
        }
      }
      > .panel:last-of-type {
        width: 30%;
      }
      > .panel:not(:first-of-type) {
        @include breakpoint-not-desktop {
          width: 40%;
          margin-top: 1%;
        }
        @include breakpoint-sm {
          width: 100%;
        }
      }
      > .panel:first-of-type tr:nth-of-type(2n) td:not(:first-of-type),
      > .panel:nth-of-type(2) tr:nth-of-type(n + 3) td:not(:first-of-type) {
        color: $colour-ccinfo-pink-bright;
      }
      > .panel:nth-of-type(2) tr:nth-of-type(3) td:first-of-type {
        font-weight: 700;
        transform: translate(25%);
      }
    }
  }
  div.panel.cofund-calc-info {
    margin-top: 1em;
    background-color: $sheet-co-funded-background;
    .row {
      > .col {
        @include desktop {
          width: 19%;
        }
      }
      > .panel {
        background-color: $sheet-co-funded-background-dark;
        width: 23%;
        @include customBreakpoint("max-width: 1150px") {
          width: 60%;
        }
        @include breakpoint-sm {
          width: 100%;
        }
      }
      > .panel:last-of-type {
        width: 75%;
        @include customBreakpoint("max-width: 1150px") {
          width: 100%;
        }
        table {
          tr:first-of-type > td {
            padding-bottom: 0;
          }
          tr:nth-of-type(4),
          tr:nth-of-type(5) > td:nth-of-type(-n + 2),
          .funder-rental > div:first-of-type {
            font-weight: 700;
          }
          tr:nth-of-type(5) {
            vertical-align: bottom;
          }
          tr:nth-last-of-type(3) {
            > td:nth-of-type(3) {
              width: 13% !important;
              padding-left: 0;
              padding-right: 0;
              font-size: 0.9em;
            }
            > td:nth-last-of-type(n + 3) {
              color: $colour-ccinfo-green-bright;
            }
          }
          tr:nth-last-of-type(2) > td:nth-of-type(-n + 2),
          tr:nth-last-of-type(-n + 3) > td:last-of-type,
          .dealer-payout {
            color: $colour-ccinfo-blue-bright;
          }
          tr:last-of-type > td:nth-of-type(-n + 2),
          .ips-payout {
            color: $colour-ccinfo-pink-bright;
          }
          td {
            padding-top: 0;
          }
          td:first-of-type {
            width: 25%;
          }
          td:nth-of-type(n + 2):nth-of-type(-n + 4) {
            width: 12%;
          }
          td:nth-last-of-type(2) {
            text-align: right;
          }
          .ips-payout,
          .dealer-payout {
            font-weight: 700;
            text-align: center;
          }
          .total-payout {
            font-weight: 500;
            text-align: center;
            border-top: $colour-ccinfo-grey-light solid 2px;
            border-bottom: $colour-ccinfo-grey-light solid 2px;
            padding-bottom: 0.1em;
            width: 60%;
            transform: translate(8em);
            div {
              padding-bottom: 0.1em;
              border-bottom: inherit;
            }
            @include breakpoint-not-desktop {
              width: revert;
              transform: translate(3em);
            }
            @include breakpoint-sm {
              width: unset;
              transform: none;
            }
          }
        }
      }
    }
  }
}
.cfa-view-capital-calc {
  .bold {
    font-weight: bold;
  }

  margin-bottom: 1em !important;
  .ui.grid {
    margin: 1em !important;
  }
  .ui.grid .row {
    padding: 0 !important;
  }
  .ui.grid .row > .column {
    padding: 0 1em 1em 1em !important;
  }

  .ui.grid .row .border-funder {
    border-color: darken($sheet-funder-background, 10%) !important;
  }
  .ui.grid .row .border-supplier {
    border-color: darken($sheet-supplier-background, 10%) !important;
  }
  .ui.grid .row .border-co-funded {
    border-color: darken($sheet-co-funded-background, 10%) !important;
  }
  .ui.grid .row .top {
    border-left: 1px solid;
    border-top: 1px solid;
    border-right: 1px solid;
  }
  .ui.grid .row .middle {
    border-left: 1px solid;
    border-right: 1px solid;
  }
  .ui.grid .row .middle-left {
    border-left: 1px solid;
  }
  .ui.grid .row .middle-right {
    border-right: 1px solid;
  }
  .ui.grid .row .bottom {
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
  }
  .ui.grid .row .bottom-left {
    border-left: 1px solid;
    border-bottom: 1px solid;
  }
  .ui.grid .row .bottom-right {
    border-bottom: 1px solid;
    border-right: 1px solid;
  }
  .ui.grid .header {
    font-weight: bold;
  }
  .ui.grid .dealer-calc-header {
    padding-top: 1em;
  }
  .funder {
    padding: 1em;
    background-color: $sheet-funder-background;
    border-radius: 5px;
  }
  .supplier {
    padding: 1em;
    background-color: $sheet-supplier-background;
    border-radius: 5px;
  }
  .co-funded {
    padding: 1em;
    background-color: $sheet-co-funded-background;
    border-radius: 5px;
  }
  .purple-text {
    color: $color-old-ip-purple;
  }
  .blue-text {
    color: $color-blue;
  }
  .red-text {
    color: $color-red;
  }
}
.cfa-view-header-grid {
  margin-bottom: 1em !important;
  border-radius: 5px;
  border: 1px #333333 solid;
  padding: 1em;
  .ui.grid .row {
    padding-top: 0 !important;
  }
  .inner-grid {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    .total {
      font-weight: bold;
      border-top: 1px $color-purple solid;
      border-bottom: 1px $color-purple solid;
      padding-top: 2px;
      padding-bottom: 3px;
    }
    .cfa-charges-row-right-text {
      text-align: right;
    }
  }
  .bold {
    font-weight: bold;
  }
  .purple-text {
    color: $color-old-ip-purple;
  }
  .orange-text {
    color: $color-orange;
  }
  h2 {
    margin-bottom: 1.5em;
    font-size: 25px;
  }
}

.mini-grid {
  .padding-row {
    margin: 1em !important;
  }
  .row {
    padding-bottom: 0 !important;
  }
  .header {
    font-weight: bold;
  }
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.mini-grid-funder {
  padding: 0.5em;
  background-color: $sheet-funder-background;
}
.mini-grid-supplier {
  padding: 0.5em;
  background-color: $sheet-supplier-background;
}
.mini-grid-co-funded {
  padding: 0.5em;
  background-color: $sheet-co-funded-background;
}
.cfa-view-header-bar {
  color: $color-red;
  font-weight: bold;
}
.cfa-view-header-extra {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  h2 {
    margin-bottom: 0.5em;
    font-size: 19px;
  }
}

.cfa-funding-tickbox {
  padding-right: 0.2em;
}

.cfa-funding-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.cfa-funding-amount {
  font-size: 16px;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.finance-rounding-per-quarter {
  font-weight: bold;
  color: $color-red;
  &.numeric-value {
    display: contents !important;
  }
}

/* Numeric values */

.negative {
  color: $color-red;
}

.never-break {
  overflow: visible;
  word-break: keep-all;
  overflow-wrap: normal;
  hyphens: none;
  line-break: strict;
  word-wrap: normal;
}

/* General */

.right-align {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5,
div.header {
  font-family: "Barlow", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

h1 {
  font-size: 44px;
  color: white;
}

h2 {
  font-size: 36px;
  color: $color-purple;
}

h3 {
  font-size: 26px;
  color: $color-purple;
}

h4 {
  font-size: 22px;
  color: $text-color;
}

h5 {
  font-size: 18px;
  font-weight: normal;
  color: $text-color;
}

p {
  font-size: 16px;
  color: $text-color;
}
small {
  font-size: 12px;
  color: $text-color;
}

.field-text {
  font-size: 16px;
  color: $text-color;
  font-weight: normal;
}

label {
  font-size: 13px;
  font-weight: bold;
  color: $text-color;
}

.placeholder {
  font-size: 16px;
  color: #ccc;
}

textarea {
  font-family: $font-name, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.bold {
  font-weight: bold;
}

.red-text {
  color: $color-red;
}

.red-label {
  font-size: 13px;
  font-weight: bold;
  color: $text-color;
}

.client-overview {
  max-width: 35rem;

  & .ui.form > .fields > .field.disabled > label:after {
    display: none;
  }

  > .cin.loadable {
    display: flex;
    flex-direction: row;
  }

  .client-form-container {
    display: flex;
    flex-grow: 1;
    > .no-disable-box {
      width: 100%;
    }
  }
  .edit-button-container {
    display: flex;
    align-items: start;
  }
}

.client-auth-sig {
  max-width: 65rem;
}

.client-form {
  .fields .single {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  &:not(.no-disable-box) {
    .fields .single {
      width: 23em;
    }
  }
  div.invoice-emails-list {
    > div.list-item {
      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
      > * {
        display: inline-block;
        &:not(:first-child) {
          margin-left: 1em;
        }
      }
      > div.field {
        &:not(.error) {
          p.form-error {
            display: none;
          }
        }
      }
      > button {
        padding: 0.5em;
        vertical-align: top;
        margin-top: 0.25em;
        img {
          display: inline;
          ~ span {
            margin-left: 0.5em;
          }
        }
      }
    }
    &.disabled {
      display: inline-block;
      > div.list-item {
        display: inline;
        margin-right: 0.5em;
        &:not(:nth-last-child(-n + 2)) {
          &::after {
            content: ',';
          }
        }
      }
    }
  }
}

.ui.popup.modal-styled-popup-container {
  padding: 0;
  div.modal-styled-popup {
    width: 25rem;    
   
    > .header {
      font-size: 1.3em;
      padding: 1.25rem 0.5rem;
    }
    > .content {
      padding: 0.25rem 1rem;
    }
    > .actions {
      padding: 1rem;
      > .ui.negative.button {
        margin-left: -0.95rem;
      }
    }

    .fixed-fields-group {
      .fixed-fields-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.25rem;
        min-height: 1.8rem;
        .fixed-field-col {
          width: 50%;
          display: flex;
          justify-content: flex-start;
          .field {
            margin-bottom: 0;
            &:nth-child(2) {
              margin-left: 0.75rem;
            }

            .ui.radio.checkbox,
            .ui.ui.radio.checkbox > label {
              margin: 0;
              font-weight: normal;
            }
          }
          textarea {
            width: 12rem !important;
          }
        }
      }
    }

    &.service {
      width: 30rem;
      .fixed-fields-group {
        .fixed-fields-row {
          .fixed-field-col:nth-of-type(1) {
            width: 40%;
          }
          .fixed-field-col:nth-of-type(2) {
            width: 60%;
            .field {
              width: 100%;
              .ui.search.dropdown {
                font-weight: normal;
              }
            }
          }
        }
      }
    }

    .ui.dropdown.fix-paperweight-dropdown {
      width: 7em !important;
      .text {
        height: 1.1em;
      }
    }    
  }

  &.services {
    @include breakpoint-not-sm {
      top: 10rem !important;
    }
  }
}

div.ui.modal.visible,
div.ui.modal.visible .content .dimmable,
div.modal-styled-popup {
  > div.header {
    border-bottom: none;
    font-weight: normal;
    padding-top: 1rem;
    padding-bottom: 0;
    border-top: 4px solid $color-light-blue;
  }
  > div.content {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
  }
  > div.actions, div.custom-action-container {
    border-top: none;
    padding-bottom: 1.25rem;
    & > .ui.button {
      // reverse 'confim'/'ok' icon position w/o access to props
      &.primary {
        margin: 0 0.5rem;
      }
      &.positive {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 0.7rem 0.8rem;
        font-weight: normal;
        font-size: calc(1rem + 1px);
        > i.icon.checkmark {
          float: right;
          margin-left: 0.43em;
          margin-right: -0.21em;
        }
        > .form-array {
          margin: -0.7em -0.8em;
          background-color: unset;
          color: white;
        }
      }
      // style the 'cancel'/'no' button
      &.negative {
        background: transparent;
        color: $text-color;
        font-weight: normal;
        font-size: calc(1rem - 1px);
        > i.icon.remove {
          display: none;
        }
      }
    }
  }

  form.ui.form {
    label {
      font-size: 1rem;
      margin-bottom: 0.65rem;
    }
  }
}

.save-as-modal{
   .content .dimmable{
    .actions{
      display: inline;
      float: right;
      margin-top: 20px;
      .save-button img {
        display: inline-block;
        padding-bottom: 5px;
        padding-left: 0.5em;
        }
      }
  }
  .actions{
    display: none;
  }
}


.large-format-edit-modal{
  div.custom-action-container {
    max-width: 600px;
    float: right;
    border-top: none;
    padding-bottom: 1.25rem;
    & > .ui.button {
      float: left;
    }

  }
  .actions{
    display: none;
  }
}
.defaults-page-title {
  button.ui.button {
    margin-right: 0 !important;
    flex-direction: row-reverse;
    &.positive-title-action {
      background-color: $positive-color;
    }
    &.cancel-title-action {
      background-color: transparent;
      color: $text-color;
    }
    > img.ui.image {
      padding-left: 0.5em;
      padding-right: 0;
    }
  }
}

.defaults-grid {  
  max-width: 41rem;
  width: unset !important;

  @include breakpoint-not-sm {
    min-width: 30rem;
    margin-right: 2rem;
  }
  .ui.input {
    > input {
      text-align: right;
    }
  }
  .field label,
  .field p {
    font-size: 16px !important;
    color: $text-color !important;
  }

  .column.defaults-clause > .field {
    > .ui.input > input {
      text-align: left;
    }
    > .read-only {
      max-width: unset !important;
    }
  }
}

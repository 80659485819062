div.side-panel.audit-list {
  width: 600px!important; //let default styles handle breakpoints
  table {
    border: none;
    text-align: center;
    > thead {
      > tr {
        > th {
          font-weight: 500;
          font-size: 1.2em;
        }
      }
    }
    > tbody {
      > tr {
        background: $background-blue;
        &.admin {
          background: $background-green;
        }
        &.ips {
          background: $color-light-purple-hover;
        }
        > td {
          > span {
            display: block;
            &:not(:first-of-type) {
              font-style: italic;
            }
          }
        }
      }
    }
  }
  footer {
    &.table-pagination {
      > div.pagination.menu {
        margin-right: 0;
      }
    }
  }
}

.product-schedule-h4-title {
  margin-top: 0;
}

.product-schedule-wrapper {
  max-width: fit-content;

  .ui.form.product-schedule-list {
    max-width: unset;
    .ps-content-body-container {
      max-height: 30em;
      min-height: 20em;
      overflow: auto;
      .product-schedule-list-headings {
        position: sticky !important;
        top: 0px;
        z-index: 2000;
        background-color: $white;
      }
    }

    .form-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin: 0.5rem 0;
      .toggle-container {
        width: 35em;
      }
      button.ui.primary.button.ps-save-button {
        margin: 0 !important;
      }
    }
    .product-schedule-modal-buttons {
      display: flex;
      justify-content: flex-end;
    }
    .non-removed-machines {
      max-width: 117em;
      .array-error:last-child {
        margin-bottom: 0;
      }
    }
    .removed-machines {
      padding-bottom: 1em;
      .product-schedule-list-item {
        background-color: $color-grey-1;
        &.sch-status-legend {
          padding: 0.5em 0 2em 11em;
          width: 100%;
          @include mobile {
            padding: 0;
          }
        }
      }
      .array-error {
        margin-bottom: 0;
      }
    }
    .product-schedule-add-and-modal {
      display: flex;
      width: 100%;
      min-height: 0.5em;
      .product-schedule-modal-buttons {
        display: flex;
        position: absolute;
        right: 8px;
      }
    }
    .product-schedule-list-headings {
      display: flex;
      text-align: center;
      font-weight: bold;
    }
    @media (max-width: 1900px) {
      font-size: 14px;
      p {
        font-size: 14px;
      }
    }
    @media (max-width: 1700px) {
      font-size: 13px;
      p {
        font-size: 13px;
      }
    }

    .product-schedule-list-item {
      display: inline-flex;
      border: 1px solid;
      border-bottom-style: none;
      &.last-row {
        border-bottom-style: solid;
      }
      .ps-enum-dropdown {
        max-width: 8em !important;
        width: auto !important;
        align-items: center;
      }
      .field.disabled,
      .field.disabled .ui.disabled.dropdown {
        opacity: 1;
      }
    }
    .ps-remove-icon {
      max-height: 2em;
      padding-right: 10px;
    }
    .green {
      background-color: $background-green;
    }
    .blue {
      background-color: $background-blue;
    }
    .rm-mach {
      margin-left: 3em;
      font-weight: bold;
      font-size: 16px;
    }
    .ps-col {
      padding-right: 5px;
      padding-left: 5px;
      text-align: center;
      text-align-last: center;
      border-right: 1px solid;
      border-color: rgba(121, 107, 107, 0.151);
      .input {
        width: 100% !important;
        padding-top: 3px;
        padding-bottom: 3px;
      }
      &.ps-col-0 {
        min-width: 3.8em !important;
        width: 3.8em !important;
        padding-top: 3px;
        justify-content: space-between;
      }
      .ps-col-1 {
        min-width: 12em !important;
        width: 12em !important;
        &.product-schedule-app-items {
          margin-left: -1px;
        }
      }
      &.ps-col-2 {
        min-width: 16em !important;
        width: 16em !important;
      }
      &.ps-col-3 {
        padding-top: 3px;
        min-width: 7em !important;
        width: 7em !important;
      }
      &.ps-col-4 {
        min-width: 12em !important;
        width: 12em !important;
      }
      &.ps-col-5 {
        min-width: 5em !important;
        width: 5em !important;
        padding-top: 3px;
        .ps-enum-dropdown {
          width: 3em !important;
          .menu {
            text-align-last: left;
            width: 15em;
          }
        }
      }
      &.ps-col-6 {
        min-width: 8em !important;
        width: 8em !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .field {
          margin-bottom: 0.2em !important;
        }
        .select-location-container {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          .ui.icon.button {
            padding: 0.25em !important;
            background: transparent;
            i {
              color: $color-error-red;
            }
          }
        }
      }
      &.ps-col-7 {
        min-width: 7em !important;
        width: 7em !important;
        padding-top: 3px;
        .dropdown {
          width: auto !important;
        }
      }
      &.ps-col-8 {
        min-width: 6em !important;
        width: 6em !important;
        padding-top: 3px;
        .dropdown {
          width: auto !important;
        }
      }
      &.ps-col-8b {
        min-width: 6.5em !important;
        width: 6.5em !important;
        padding-top: 3px;
        .dropdown {
          width: auto !important;
        }
      }
      &.ps-col-9 {
        min-width: 7.5em !important;
        width: 7.5em !important;
        &.text-left {
          text-align: left;
          text-align-last: left;
        }
        .text-green {
          color: green;
        }
        .meter-codes {
          display: flex;
          width: 100%;
          font-size: 12px;
        }
      }
      &.ps-col-9b {
        min-width: 9em !important;
        width: 9em !important;
        .input {
          margin-left: -1em;
          width: 80% !important;
        }
      }
      &.ps-col-10 {
        min-width: 8em !important;
        width: 8em !important;
      }
      &.ps-col-11 {
        min-width: 5em !important;
        width: 5em !important;
      }
      &.ps-col-12 {
        min-width: 6em !important;
        width: 6em !important;
        padding-top: 3px;
        .dropdown {
          width: auto !important;
        }
      }
      &.ps-col-13 {
        min-width: 2em;
        width: 2em;
      }

      @media (max-width: 1900px) {
        &.ps-col-5 .ps-enum-dropdown {
          width: 3.3em !important;
        }
      }
      @media (max-width: 1700px) {
        &.ps-col-5 .ps-enum-dropdown {
          width: 3.4em !important;
        }
        &.ps-col-12 .dropdown {
          width: 4.5em !important;
          .dropdown.icon {
            right: 0em;
          }
        }
      }

      @media (max-width: 1600px) {
        .ps-col-1 {
          min-width: 9em !important;
          width: 9em !important;
        }
        &.ps-col-2 {
          min-width: 9em !important;
          width: 9em !important;
        }
        &.ps-col-9 {
          min-width: 4em !important;
          width: 4em !important;
        }
      }

      @media (max-width: 1366px) {
        &.ps-col-4 {
          min-width: 6em !important;
          width: 6em !important;
        }
        &.ps-col-5 {
          min-width: 4em !important;
          width: 4em !important;
          padding-top: 3px;
        }
        &.ps-col-6 {
          min-width: 6em !important;
          width: 6em !important;
        }
        &.ps-col-9b {
          min-width: 7em !important;
          width: 7em !important;
          .input {
            margin-left: -2em;
            width: 90% !important;
          }
        }
        &.ps-col-10 {
          min-width: 6em !important;
          width: 6em !important;
        }
      }
    }
    .product-schedule-app-items {
      display: flex;
      flex-wrap: wrap;
      .product-schedule-app-item {
        width: 4em;
        display: flex;
        padding-bottom: 2px;
        .ps-item-dropdown {
          width: 4em !important;
          align-items: center;
          padding: 0.25em 0 0.25em 0.25em !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > .text {
            width: 3.1em;
          }
          > i.icon.dropdown {
            padding: 0.5em 0.1em;
          }
          &.test .menu div:first-child {
            font-size: small;
            font-style: italic;
            min-height: 2em;
          }
        }
        .button-padding {
          margin-left: 4px;
        }
      }
    }
    .ps-action-bar-item,
    .removed-machines {
      display: inline;
      div.sch-status-legend {
        padding-left: 10rem;
        label,
        dl,
        dt,
        dd {
          display: inline-block;
        }
        label {
          cursor: text;
          font-weight: 500;
          font-size: 16px;
          text-transform: uppercase;
          &::after {
            content: ":";
          }
        }
        dl {
          margin: 0;
        }
        dt {
          margin-left: 0.25em;
          &::first-letter {
            font-weight: 700;
          }
          &:not(:last-of-type) {
            &::after {
              content: ",";
            }
          }
        }
      }
    }

    .product-schedule-index {
      font-size: medium;
      margin-top: -3px;
    }
  }
}

.meter-reading-modal {
  width: 60em !important ;
  max-width: 95%;
  .meter-reading-list-container {
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $scrollbar-color;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }
    .meter-reading-list {
      min-width: 68rem;
      .ui.grid.meter-item-group {
        margin: 0 !important;
        .row {
          &.meter-reading-item {
            margin: 0 !important;
            padding: 0 !important;
            display: flex;
            align-items: center;
            &.header {
              font-weight: bold;
              .column {
                font-size: 15px;
              }
            }
            .field {
              .ui.input > input {
                margin: 0.2em 0;
                padding: 0.1em 0.25em !important;
                font-size: 15px;
              }
              .read-only {
                max-width: 100% !important;
                p {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  font-size: 13px;
                  letter-spacing: -0.5px;
                }
              }
            }
          }
          &.even {
            background-color: $background-meter-modal-green;
          }
        }
        .column {
          padding: 0 0.1em !important;
          &.meter-code-col {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            .field {
              margin-bottom: 0;
            }
            .ui.primary.button {
              margin: 0;
              padding: 0.3em 0.5em;
              font-size: 14px;
            }
          }
        }
      }
      .field.array-error {
        margin: 0 !important;
      }
    }
  }
}

.meter-reading-modal {
  width: 75em !important ;
  .meter-reading-list {
    .meter-reading-item {
      display: flex;
      .meter-data-item {
        display: flex;
      }
    }
  }
}

.select-location-modal {
  .ui.form {
    width: 35rem;
    .ui.selection.dropdown {
      min-height: 2em;
    }
    .field {
      margin-bottom: 0.5em;
      &.inline {
        display: flex;
        > label {
          width: 10em;
          text-align: right;
        }
      }
    }
  }
  .location-modal-menu {
    display: flex;
    justify-content: space-between;
    .allocate-to-all {
      margin-top: 2em !important;
      label {
        font-weight: normal !important;
      }
    }
  }
}

.gold-colour {
  color: $colour-gold;
}

.yield-bands-grid {
  max-width: 57em;
  @include breakpoint-sm {
    width: 100%;
  }

  $row-height: 2em;

  .content-container {
    padding: 0;
    .defaults-page-title {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .table-title {
    width: 400px;

    @include breakpoint-sm {
      width: 100%;
    }

    > div {
      padding-left: 0.5em;
      width: 9em;
    }
  }

  .yield-band-column {
    width: 22em;

    > .field {
      display: flex;
      justify-content: center;
      width: 100%;
      width: 8em;
      margin-bottom: 0 !important;
      &:first-child {
        font-weight: bold;
        > div:not(.read-only) > p {
          display: none;
        }
      }
      > .read-only {
        align-items: center;
      }

      > .ui.input {
        width: 100% !important;
        height: 1.813em;
      }
      &.error {
        display: block !important;
      }
    }
  }

  .table-title,
  .yield-band-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    > * {
      height: $row-height;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    div:nth-child(2) {
      background-color: $light-grey;
    }
  }

  .yields-content {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1em;
  }
}

.header-dropdown-rates {
  display: flex;
  align-items: center;
  label {
    margin-right: 1rem;
  }
  margin: 1rem 1rem 1rem 0;
  &.rate-table-group {
    margin-top: 0;
    max-width: 80rem !important;
  }
  &.rate-table-type {
    .ui.dropdown .menu .item {
      padding: 0.2em 0.4em !important;
    }
  }
  .ui.dropdown > .text {
    min-width: 20em;
    font-weight: bold;
  }
}
.defaults-dropdown {
  min-width: 25rem !important;
  margin-right: 1rem;
  padding: 0.15rem 0.15rem 0.15rem 0.5rem;
  border: $color-grey-1 1px solid;
  background-color: white;
  border-radius: 3px;
}

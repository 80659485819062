.sort-header {
    display: inline-flex;
    cursor: pointer;
  
    &.disabled {
      cursor: auto;
    }
    .asc-arrow {
      transform: rotate(180deg);

    }
    .asc-arrow, .desc-arrow{
        opacity: 0.5 ;
        line-height: 0.8em;
    }
    .sort-arrows {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-left: 0.5rem;
      user-select: none;
      &.desc .asc-arrow {
        visibility: hidden;
      }
      &.asc .desc-arrow {
        visibility: hidden;
      }
      &.desc .desc-arrow ,  &.asc .asc-arrow  {
        opacity: 1;
      }
    }
  }
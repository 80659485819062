.ui.button.notes-to-view {
  border-color: $color-red;
  border-width: 2px;
  border-style: solid;
  line-height: 12px;
  white-space: nowrap;
  &::before {
    content: "!";
    width: 12px;
    height: 12px;
    background-color: $color-red;
    border-radius: 6px;
    color: $color-white;
    font-size: 8px;
    position: relative;
    right: 15px;
    top: -14px
    }
    @include breakpoint-sm {
      left: 3px;
    }
    top: 6px;
    .notes-trigger-word{
      margin-left: -6px; 
    }
  }


div.notes-side-panel {
  & > div.dimmable {
    & > div.ui.header {
      margin-bottom: 0.5rem !important;
    }
  }
}

.sheet-notes {
  .add-note-container {
    display: flex;
    flex-direction: row-reverse;
    .ui.button {
      color: $color-light-blue;
      background-color: transparent;
      padding-top: 0;
      display: flex;
      & > .ui.image {
        margin-right: 0.75rem;
      }
      &:hover {
        color: $color-blue;
      }
    }
  }

  .tab-menu {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .tab-menu-item {
      cursor: pointer;
      display: inline-block;
      padding: 0.25rem 0.65rem;

      &.active {
        border-bottom-width: 2px;
        border-bottom-color: $color-light-blue;
        border-bottom-style: solid;
      }

      &.disabled {
        color: $color-grey-2;
        cursor: not-allowed;
      }
    }
  }

  .note-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;

    .funder {
      background-color: $sheet-funder-background !important;      
    }
    .supplier {
      background-color: $sheet-supplier-background !important;      
    }
    .ipsAdmin {
      background-color: $sheet-ipsAdmin-background !important;      
    } 
    .accountManager {
      background-color: $sheet-account-manager-background !important;      
    } 

    div.inline-divider {
      display: inline-block;
      border-radius: 1px;
      height: 2px;
      width: 1px;
      border-style: solid;
      border-color: $text-color;
      border-width: 1px;
      margin: 0.2rem 1rem;
    }

    .note-item {
      width: 100%;
      overflow: hidden;
      margin: 0.5rem 0rem;
      background-color: $background-color;
      padding: 0.5rem 1rem;
      border-radius: 3px;
      box-shadow: 3px 3px 8px #0000001a;
      overflow: visible;

      .body {
        display: flex;
        justify-content: space-between;

        .ui.item.dropdown {
          height: 26px; // prevents long note pulling menu downwards
          > i {
            color: $primary-color;
          }
        }
      }

      .text {
        white-space: pre-wrap;
        margin-bottom: 0.25rem;
      }

      .footer {
        font-size: 13px;
        color: $color-grey-3;
      }

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        background-color: $background-color;
        transform: rotate(45deg);
        position: absolute;
        left: 54px;
        margin-top: 4px;
        border-style: solid;
        border-color: darken($background-color, 0%);
        border-width: 1px;
        border-top-style: none;
        border-right-style: none;
      }
    }

    .note-profile {
      width: 2.5rem;
      height: 2.5rem;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
      flex-shrink: 0;
    }
  }
}

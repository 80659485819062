div.pdfjs-inline {
  display: flex;
  flex-flow: column wrap;
  div.canvas-container {
    $drop-shadow: 3px 3px 5px $color-dark-grey;
    $inset-shadow: inset 0 0 3px $color-dark-grey;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 100%;
    background: $color-grey;
    border: $color-dark-grey thin solid;
    border-radius: 5px;
    box-shadow: $inset-shadow;
    div.zoom-boundary {
      overflow: auto;
      .document-canvas-container {
        margin: 1em;
        box-shadow: $drop-shadow
      }
    }
    div.tray {
      display: flex;
      width: 100%;
      padding: 0.5em;
      background: transparent;
      &[data-align-controls~='left'] {
        justify-content: left;
      }
      &[data-align-controls~='center'] {
        justify-content: center;
      }
      &[data-align-controls~='right'] {
        justify-content: right;
      }
      div.canvas-controls {
        display: flex;
        flex-flow: row nowrap;
        width: max-content;
        padding: 0.25em 0;
        background: $background-color;
        border-radius: 5px;
        box-shadow: $drop-shadow;
        .ui.input {
          margin: 0 0.5em;
          &.error {
            input, i.icon {
              color: $color-red;
              opacity: 1;
            }
          }
          input[type='text'] {
            padding: 0 0.75em;
            max-width: 6em;
          }
          button {
            margin: 0;
            padding: 0.5em;
            vertical-align: middle;
            &:not(:first-child) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            &:not(:last-child) {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }
  }
}

.content-container.sheet-step.customer-view-step {
  border-top-color: $color-system-schedule;
}

.sheet-customer-view {
  .array-error {
    margin: 0 !important;
  }

  .customer-device-qty {
    div.read-only {
      margin-left: 4rem;
      margin-bottom: 1rem;
    }
  }

  .customer-schedule-items {
    max-width: 100%;
    overflow: auto hidden;
    form.ui.form {
      div.field {
        margin: 0 !important;
      }
    }
    &.empty {
      padding-bottom: 0;
      min-height: 1em;
      overflow: hidden;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $scrollbar-color;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    .customer-headers {
      align-items: flex-end !important;
      display: flex;
      > * {
        text-align: center;
      }
    }
    .col-1 {
      width: 8rem;
      min-width: 8rem;
    }
    .col-2 {
      width: 14rem;
      min-width: 14rem;
    }
    .col-3 {
      width: 10rem;
      min-width: 10rem;
      .field {
        margin-right: 1em;
        .ui {
          text-align: left;
          width: auto;
        }
      }
    }
    .col-4 {
      width: 8rem;
      min-width: 8rem;
    }
    .col-5 {
      width: 8rem;
      min-width: 8rem;
    }
    .col-6 {
      width: 14rem;
      min-width: 14rem;
      &.quart-align {
        padding-left: 3rem;
      }
    }
    .col-7 {
      width: 14rem;
      min-width: 14rem;
    }
    .col-model {
      width: 16rem;
      min-width: 16rem;
    }
    .customer-view-tiers {
      //text-align: right;
      .col-tier-1 {
        min-width: 34rem;
      }
      .col-tier-2 {
        min-width: 14rem;
      }
      .col-tier-3 {
        min-width: 14rem;
      }
      .col-tier-4 {
        min-width: 14rem;
      }
      .customer-view-tier {
        margin-right: 1em;
      }
    }
    .customer-view-item {
      background-color: rgb(238, 238, 238);
      padding: 0 !important;
      margin: 5px 0 5px 0 !important;
      height: 2rem;
      display: flex;
      width: max-content;
      &.height-centred {
        align-items: center;
      }
      > div.col {
        display: flex;
        align-items: center;
        &:not(div.quart-align) {
          > div, strong {
            margin: 0 auto;
          }
        }
      }  
      .customer-view-model {
        color: $sheet-customer-view-model;
        text-decoration: underline;
      }
      .bold {
        font-weight: bold;
      }
      .centered {
        text-align: center;
        .cost-per-unit {
          justify-content: center;
        }
        .fix-bool-container {
          justify-content: center;
        }
      }
      .customer-view-cpu {
        .cost-per-unit {
          display: flex;
          .customer-view-at {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
  .customer-view-metered-service {
    display: grid !important;
    margin-top: 3rem;
    .metered-service-header {
      display: flex;
      strong {
        margin-right: 0.5em;
      }
    }
    div.metered-service {
      > strong {
        margin-right: 0.25em;
        &::after{
          content: ':';
        }
      }
    }
    .underline {
      text-decoration: underline;
      margin-bottom: 1rem;
    }
  }
  .customer-view-non-metered-services {
    display: grid !important;
    strong {
      text-decoration: underline;
      margin: 1rem 0 1rem 0;
    }
    div.field {
      margin: 0 !important;
    }
  }
}

.fixed-field .fixed-warning,
.fixed-field .initial-fixed-warning,
 {
    width: auto;
    left: 98%;
    border: 1px solid $color-light-red;
    padding:0.3em 0.5em !important;
    color:$color-light-red;
    background-color:$white;
    font-weight:normal;
    font-size:1em !important;
    line-height: 1.1em !important;
    position:absolute;
    z-index:99999999;
    text-align: left;
    display:none;
}

.fixed-field.show .fixed-warning {
    display:block;
    cursor: pointer;    
}

.fixed-field.show-initial-warning .initial-fixed-warning {
    display:block;
    cursor: pointer;    
}

.fixed-warning span,
.initial-fixed-warning span{
    white-space: nowrap;
}

div.cin.side-panel.comparison-panel {
  max-width: 1417.5px !important;
  width: 73% !important;
  padding: 1rem !important;

  .ui.header {
    margin-bottom: 0.5rem;
  }

  @include breakpoint-md {
    width: 100% !important;
  }

  .cin.number-formatter.negative,
  span.negative {
    color: $color-red;
  }

  .comparison-sheet-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    font-size: 14px;
    .ui.form {
      font-size: 14px;
      margin-bottom: 0.25rem;
    }
  }

  .table-container {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .current-quarter-agreed {
    width: 28rem;

    .ui.input > input {
      padding: 0.25rem 0.5rem;
    }
  }

  .proposed-volumes {
    width: 32rem;

    .ui.table {
      margin-bottom: 0.25rem !important;
    }

    .additional {
      text-align: right;
    }
    .row.body-row {
      min-height: 2.2em;
    }

    .totaliser-grid {
      .row {
        padding-bottom: 0;
        .totaliser {
          padding: 0.5rem;
        }
      }
    }
  }

  .ui.grid.table {
    background-color: $light-grey;
    border-style: none;

    .table-title-row {
      padding-bottom: 0;
      .table-title {
        font-size: 20px;
      }
    }

    .row:last-of-type {
      border-style: none !important;
    }

    .row {
      border-bottom: $color-grey-1;
      border-bottom-style: solid;
      border-bottom-width: 1px;

      &:last-child,
      &:first-child,
      &:last-of-type {
        border-style: none;
      }

      padding-top: 0.1rem !important;
      padding-bottom: 0.1rem !important;

      .column {
        padding-left: 0.1rem !important;
        padding-right: 0.1rem !important;
      }

      div.unit-label-container {
        width: 3rem;
        display: flex;
        justify-content: flex-start;
        .ui.image {
          display: inline;
        }
      }
      .unit-label {
        margin-left: 1rem;
      }
    }
  }
  .table-header {
    font-weight: bold;
  }

  div.unit-icon {
    height: 0.8em;
    width: 0.8em;
    border-radius: 0.4em;
    border: 1px solid #404040;
    display: inline-block;
    margin-top: 0.4rem;
  }

  div.unit-icon.black {
    background: transparent linear-gradient(90deg, #ffffff 0%, #000000 100%) 0%
      0% no-repeat padding-box;
  }
  div.unit-icon.colour {
    background: transparent
      linear-gradient(
        180deg,
        #ff0000 0%,
        #de5dec 21%,
        #0019ff 40%,
        #00dcff 52%,
        #00ff44 65%,
        #b3ff00 73%,
        #b4b400 86%,
        #ff7700 100%,
        #ff0000 100%
      )
      0% 0% no-repeat padding-box;
  }
  div.unit-icon.utility {
    background-color: $color-purple;
  }

  .ui.grid.totaliser-grid {
    margin-top: 0;
    .row {
      padding-top: 0;
    }
    .totaliser {
      background-color: $color-lighter-blue;
      padding: 0.25rem;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .totaliser-value {
        font-weight: bold;
      }
    }
  }

  .calculable-field {
    transition: color 0.25s;
    &.value-loading {
      display: none;
    }
  }

  .button-group {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .cancel {
      background-color: unset;
    }
  }
}

.predicted-volume-entry-modal {
  .button {
    background-color: $color-purple !important;
    color: white !important;
  }

  .disabled.button {
    background-color: $color-white !important;
    color: white !important;
  }
}

.comparison-snapshot-modal {
  width: 1100px !important;

  .actions {
    padding: 0 !important;

    button {
      display: none;
    }
  }   
    
  .action-buttons 
  { 
    display: flex;
    justify-content: flex-end;
    
    button {
      background-color: $color-purple !important;
      color: white !important;
      padding: 0.75rem !important;
      border-radius: 0.28571429rem !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0.25em !important;
      font-size: 16px !important;
      font-weight: 700 !important;
    }
  }
}

.comparison-sheet-modal {

  .comparison-input-field {
    padding-right: 0.6em !important;
    text-align: center;
  }

  .centered {
    text-align: center;
  }
  .numeric-right-aligned {
    padding-right: 4em !important;
    text-align: right;
  }
  .current-quarter-agreed {
    .table-header {
      font-weight: bold;
      text-align: center;
      align-items: flex-end;
    }
    .quarter-agreed-body-row {
      background-color: $light-grey;
      padding: 3px !important;
      margin: 5px !important;
      text-align: right;
      .column {
        padding-left: 0em !important;
      }
      .ui.input {
        width: 8em !important;
      }
    }
  }

  .proposed-volumes {
    .body-row {
      background-color: $light-grey;
      padding: 3px !important;
      margin: 5px !important;
    }
    .table-header {
      font-weight: bold;
      text-align: center;
    }
  }
  .section-header {
    border-top: 4px solid #3caae6 !important;
    margin-bottom: 1em !important;
    border: 1px solid $color-grey-1;
    border-radius: 00.25rem;
  }
  .additional-info-section {
    margin-top: 1em !important;
    padding-left: 5px;
  }
  .underline {
    text-decoration: underline;
  }
  .additional-item-container {
    margin: 1em 0 1em 0 !important ;
  }
  .comparison-snapshot-tandc {
    display: flex;
    justify-content: space-between;
  }
  .comparison-modal-additional-terms-body {
    border: 1px solid black;
    margin: 2px !important;
    padding: 2px !important;
    margin-bottom: 0.5em !important;
  }
  .comparison-totals {
    text-align: right;
    margin-left: auto;
    .totaliser {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      display: flex;
      justify-content: right;
      .totaliser-value {
        margin: 0.5em !important;
      }
    }
    .comparison-total-value {
      min-width: 10em !important;
    }
  }
  .comparison-total-field {
    padding-right: 1em !important;
    text-align: right;
  }

  .comparison-paper-spend {
    .comparison-paper-spend-title {
      padding: 1em !important;
    }
    .comparison-paper-spend-body {
      font-weight: bold;
      .comparison-paper-spend-headings {
        display: flex;
        font-weight: bold;
        text-align: center;
        div {
          margin: 5px;
        }
      }
      .comparison-paper-spend-data-container {
        display: flex;
        text-align: center;
        .comparison-paper-spend-data {
          display: flex;
          justify-content: space-between;
          div {
            margin: 0px 5px 0 5px;
          }
          background-color: $light-grey;
          margin: 0.5em 0.05em 0.5em 0.2em;
        }
      }
      .col-1 {
        width: 3em;
      }
      .col-2 {
        width: 6em;
      }
      .col-3 {
        width: 9em;
      }
      .col-4 {
        width: 7em;
      }
      .col-5 {
        width: 12em;
      }
      .col-6 {
        width: 12em;
      }
      .col-7 {
        width: 12em;
      }
      .comparison-paper-spend-delivery {
        display: flex;
        align-self: center;
        div {
          margin: 0px 2px 0 2px;
        }
        background-color: $light-grey;
        margin: 0.5em 0 0.5em 0.1em;
      }
      .comparison-paper-spend-totals {
        display: flex;
        justify-content: right;
        margin-top: 2em;
        margin-right: 1em;
        text-align: right;
        div {
          font-weight: normal;
        }
        strong {
          min-width: 10em;
        }
      }
    }
  }
}

.content-container.sheet-step.rates-step {
  border-top-color: $color-rates;
}

.rates-step {
  h5 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .terms-textarea-container {
    margin-bottom: 0 !important;
    > h5 {
      margin-bottom: 0 !important;
    }
  }
  div.rates-step-grid {
    .rates-step-field-row {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-width: 100%;
    }

    .rates-step-field-group {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 100%;

      &.percentage-group {
        .field > .ui.input,
        .field > .ui.selection.dropdown,
        .field > .base-picker {
          width: 5rem !important;
        }
        label {
          color: $color-admin-green !important;
        }
      }
      .payment-method-radio {
        .inline {
          display: flex;
        }
      }
      .sheet-radio-title {
        display: block;
        &.green p {
          color: $color-admin-green;
        }
      }
      &.group-1 {
        width: 60em;
      }
      &.group-2 {
        width: 25rem;
        > .field {
          width: 8rem;
        }
      }
      &.group-3 {
        margin-left: 2em;
      }
      &.group-4 {
        width: 40rem;
      }
      &.group-5 {
        width: 18rem;
        margin-right: 1rem;
      }
      &.group-6 {
        width: 28rem;
        > .field {
          width: 100%;
        }
      }
      &.group-7 {
        margin-right: 0.67rem;
        > .field {
          width: 23.5rem;
        }
      }
      &.group-8 {
        margin-right: 0.67rem;
        > .field {
          width: 23.5rem;
        }
      }
      &.group-9 {
        width: 23.67rem;

        .field-container {
          width: 100%;
          display: grid;
          grid-template-columns: 2.5em auto;
          align-items: center;

          > .field {
            margin-left: 1rem;
          }
        }

        > div:first-child {
          p {
            font-weight: bold;
            font-size: 0.9em;
          }
        }
      }
      &.group-10 {
        justify-content: flex-start;

        .fmv-section:nth-of-type(3) {
          @include customBreakpoint("max-width: 1568px") {
            margin-top: 0.5rem;
          }
        }

        .fmv-section:nth-of-type(2) {
          margin-top: 0.5rem;
          margin-right: 0.5rem;

          @include customBreakpoint("max-width: 1052px") {
            margin: 0.5rem 0.5rem 0rem 0rem;
          }
        }
      }
    }

    .fmv-section {
      width: $funder-section-width;
      padding: 0.25rem 0.5rem;
      border-radius: 5px;
      &.rate-type-region {
        min-height: 213px;
      }

      .larger-page-loader {
        min-height: 211px;
      }

      &.funder {
        background-color: $sheet-funder-background;
        margin-right: 0.5em;
      }
      &.dealerfunded {
        background-color: $sheet-supplier-background;
        margin-right: 0.5em;
      }
      &.cofunded {
        background-color: $sheet-co-funded-background;
      }

      .rate-type-radio {
        background-color: white;
        border: 1px black solid;
      }

      h4,
      h5 {
        margin-bottom: 0;
      }
      h4 {
        font-size: 16px;
        &.reduce-top-margin {
          margin-top: 0.5rem !important;
        }
      }
      h5 {
        text-decoration: underline;
        font-size: 13px;
        font-weight: 500;
      }

      .page-loader {
        justify-content: center;
      }

      .highlighted {
        color: $color-alternate-purple;
      }

      .ui.grid.adjusted,
      .ui.grid.future,
      .ui.grid.rrp,
      .ui.grid.figures {
        font-size: 12px;
        margin-bottom: 0.5rem !important;

        .row,
        .column {
          padding: 0;
        }
        .row {
          height: 1rem;
        }
      }

      .ui.grid.adjusted {
        width: 100%;
        font-size: 12px;
        letter-spacing: -0.25px;
        .row {
          &.header {
            font-weight: bold;
          }
          .column {
            text-align: center;
            white-space: nowrap;
          }
          .column:first-child {
            text-align: left;
          }
        }
      }

      .ui.grid.future {
        width: 12rem;
      }

      .rrp-allowance {
        display: flex;
        justify-content: space-between;
        width: 12rem;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        .rrp-title {
          text-decoration: none;
        }
      }

      .rrp-container {
        .future-value-row {
          width: 100%;
          height: 1em;
          div.rrp-type {
            display: inline;
            &:not(:first-child) {
              margin-left: 0.25em;
            }
          }
        }

        .ffv-numbers-row {
          display: flex;
          width: 100%;
          margin-bottom: 1rem;
          .col-1 {
            width: 20%;
            div {
              height: 1rem;
              margin-bottom: 0.05rem;
            }
          }
          .col-2 {
            width: 45%;
            div {
              height: 1rem;
              margin-top: 0.1rem;
            }
          }
          .col-3 {
            width: 35%;
            div.rrp-type-container {
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
            }
          }
        }

        .ui.grid.rrp {
          width: 13rem;
        }

        .rrp-type-container {
          .rrp-type {
            margin: 0 0 0 0;
            label {
              letter-spacing: -0.5px;
            }
          }
        }

        .figures-used {
          display: flex;
          justify-content: space-between;

          font-size: 13px;
          font-weight: bold;
          color: $color-alternate-purple;
        }
      }
      .rate-type {
        color: $color-admin-green;
        font-weight: bold;
      }
    }

    margin: 0;
    > .row {
      padding-top: 0;
      padding-bottom: 0.1rem;
      > .column {
        padding-left: 0;
      }
    }
    .field {
      margin-bottom: 0;
      > .ui.input.percentage {
        width: 5rem !important;
      }
      > .ui.payment {
        width: 13rem !important;
      }
      > .ui.payment:last-child {
        width: 30rem !important;
      }
    }
    .field > label {
      font-size: 14px;
      margin-bottom: 0;
    }
    .field > .ui.input,
    .field > .ui.selection.dropdown,
    .field > .base-picker {
      width: 7rem !important;
    }

    .sheet-type > .field > .ui.selection.dropdown {
      width: 15rem !important;
    }

    .date-applicable-from > .field,
    .sheet-radio-title {
      text-align: center;
      margin-right: 0 !important;
    }
    .sheet-radio-group.payment > label {
      letter-spacing: -0.5px;
    }
    .rates-step-field-group .field.fix-input-container {
      label {
        text-align: center;
      }
    }

    .introduction-payment-total {
      padding-top: 0.9em;
      position: absolute;
      right: 14%;
      .label {
        font-weight: bold;
        margin-right: 1em;
      }
    }
  }
  .terms-payment-method {
    margin-bottom: 0 !important;
    * {
      margin-bottom: 0 !important;
    }
  }

  & .terms-textarea-container > .field,
  .terms-payment-method > .field {
    width: 100%;
    padding: 0 1.5rem !important;
    margin-bottom: 0;

    .ui.radio.checkbox {
      margin: 0em 0 0.5em 0;
    }

    // breakpoint matches semantic ui's ui.stackable.grid
    @include customBreakpoint("min-width: 767px") {
      padding: 0 0.5rem !important;
      > textarea {
        max-width: 50rem;
      }
    }
    label {
      font-size: 14px;
    }
  }

  .introduction-payment {
    .columns {
      display: grid;
      grid-template-columns: auto auto auto;
    }
    .header {
      font-weight: bold;
      min-width: 10em !important;
    }
    .payment {
      margin-left: 2em;
      margin-right: 1em;
    }
    .ui.checkbox {
      margin: 0 0 0.5em 0 !important;
      padding-top: 3px;
      label {
        font-weight: normal !important;
        padding: 1px 0 0 23px;
      }
    }
  }
}

.defaults-clause > .field {
  width: unset !important;
}

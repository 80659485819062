 div.ui.modal.visible.no-internet-modal{
    
   div.header{
        border-top: 4px solid red !important;
        background-color: pink;
        font-size: 20px;
        font-weight: 700;
        margin:none;
        padding: 1em;
    }
    
    .actions{
        display: none;
    }
    .wifi{
        margin-left: right;
    }
    .content{
        padding: 1.2em;
        .dimmable{
            display: flex;
            justify-content: space-between;
        }
    }
}

.large-format-view-modal{
    .ui.disabled.input{
        width: 100%!important;
    }
    .ui.disabled.input,.ui.disabled.checkbox,
    .ui.checkbox input[disabled] ~ label{
        opacity: 1!important;
        margin-bottom: 0em;
      }
      .ui.form {
        .field, .field.disabled > label{
        opacity: 1!important;    
        margin-bottom: 0.5em;
      }   
    } 
    .ui.positive.button{
        display: none;
    }
    .ui.negative.button{
        background-color: $color-red !important;
        color: $color-white !important;
    }
    TextArea{
        min-height: 4em !important;
        width: 100% !important;
    }
}

.usage-type-edit-modal{
    display: flex;
    width: 100%;
    .usage-type-image{
        text-align: center;
        width: 100%;
        align-self: center;
        .image{
            display: inline-block !important;
        }
    } 
}
.sheet-array-remove-button{
    display: flex !important;
    padding: 0.7em !important;
}

.maintenance-banner {
    background-color: #ff0000;
    color: white;
    font-size: 16px;
    letter-spacing: 2px;
    text-align: center;
    padding: 0.25em 0;
    position: relative;
    z-index: 10000;
}
// Permission tree - font weight normalise
div.cin.tree-view.permissions-tree label {
  font-weight: normal;
}

.field > .ui.radio.checkbox > label {
  font-weight: normal;
}

// highlighted inputs - ensure input fields have pink borders
.ui.input.highlighted-input,
.ui.input.highlighted-input:focus,
.ui.dropdown.input.highlighted-input,
.ui.form textarea.highlighted-input,
.ui.form.highlighted-inputs .ui.input > input[type="text"],
.ui.form.highlighted-inputs .ui.input > input[type="text"]:focus,
.ui.form.highlighted-inputs .ui.dropdown,
.modal.form.paper-inclusive-step > .ui.dropdown,
.ui.form.highlighted-inputs textarea {
  border: 1px solid $color-pink;
  border-radius: 5px;
  > input {
    &[type="text"] {
      border: none;
    }
  }
  &:focus {
    border: $color-light-blue-hover 1px solid !important;
  }
}

.ui.form.highlighted-inputs .ui.checkbox .box::before,
.ui.checkbox label::before,
.ui.form.highlighted-inputs .ui.checkbox input:checked ~ .box::before,
.ui.form.highlighted-inputs .ui.checkbox input:checked ~ label::before,
.ui.form.highlighted-inputs .ui.selection.active.dropdown .menu,
.ui.dropdown.highlighted-input .ui.selection.active.dropdown .menu {
  border-color: $color-pink;
}

.ui.form.highlighted-inputs .ui.radio.checkbox input:checked ~ .box::after,
.ui.form.highlighted-inputs .ui.radio.checkbox input:checked ~ label::after {
  background-color: $color-pink;
}

//search field - positions icon correctly
.ui.form .field > .ui.icon.input.search-input input {
  width: 100%;
  margin-right: -2em;
}

.ui.form .field.fix-input-container > .ui.input.fix-input-wrapper > input,
.ui.form
  .field.fix-input-container
  > .ui.input.fix-input-wrapper
  > .ui.label.currency-label {
  height: 1.75em !important;
}

// form fields
.ui.form .field {
  .ui.input > input,
  textarea,
  .ui.selection.dropdown {
    padding: 0.25em 0.75em !important;

    .text {
      text-overflow: ellipsis;
    }
  }
  .ui.search.selection.dropdown > input {
    padding: 0 0 0 0.75em !important;
  }
  .ui.right.labeled.input > input {
    padding-right: 0 !important;
  }
  .ui.input > .ui.label:not(.currency-label) {
    padding: 0.25em !important;
  }
  .ui.input > .ui.label.currency-label {
    padding: 0.2em 0 0.2em 0.2em;
  }
  .ui.left.labeled.input > .ui.label {
    line-height: 1.2em;
  }

  .ui.selection.dropdown {
    min-height: unset;
    padding-right: 24px !important;
    i.dropdown.icon {
      padding: 0.25em;
    }
    > div.text {
      font-weight: 100;
    }
    input {
      padding: 0.25em 0.75em !important;
    }

    &.multiple {
      padding-right: 1.75rem !important;
      > .ui.label {
        background-color: $color-lighter-blue;
        color: $text-color;
        border-radius: 0.2rem;
        font-weight: normal;
      }
    }
  }
}

//on a sheet all the fields should have border
.sheet-step {
  & .ui.input > input,
  div.ui.dropdown {
    border: 1px solid $color-pink;
    border-radius: 5px;
  }
  & .ui.input > input:focus {
    border-top: 1px solid $color-blue-highlight !important;
    border-radius: 5px;
  }
  > .ui.form .field div.ui.dropdown {
    > div.text {
      min-height: 1.08rem;
    }
  }
  > .ui.form > .fields > .field > .radio.checkbox > label:before,
  .ui.form > .field > .radio.checkbox > label:before {
    border-color: $color-pink;
  }

  > .ui.form > .fields > .field > .radio.checkbox > label:after,
  .ui.form > .field > .radio.checkbox > label:after {
    background-color: $color-light-purple;
    color: $color-light-purple;
  }

  div.ui.toggle.checkbox > label:before {
    background-color: #878787 !important;
  }
  div.ui.toggle.checkbox > label:after {
    background-color: $color-white !important;
  }
  div.ui.checked.toggle.checkbox > label:before {
    background-color: $color-light-green !important;
  }
  div.ui.checked.toggle.checkbox > label:after {
    background-color: $color-dark-green !important;
  }
}

//Form Labels
.ui.left.labeled::before {
  content: attr(sublabel);
  margin: auto;
  padding: 0px 5px;
}

.ui.right.labeled::after {
  content: attr(sublabel);
  margin: auto;
  padding: 0px 5px;
}

.ui.input.labeled > input {
  max-width: 80%;
}

.sheet-header.disabled .sheet-title .rename-sheet.icon.pencil,
.sheet-step .sheet-step-body .disabled.field,
.sheet-step .sheet-step-body .disabled.field > label,
.sheet-step .sheet-step-body .disabled.field input[disabled] ~ label,
.sheet-step .sheet-step-body .ui.disabled.dropdown,
.sheet-step
  .sheet-step-body
  .cin.base-picker
  .react-datepicker-wrapper
  .ui.disabled.input,
.sheet-step .sheet-step-body .ui.disabled.button,
.sheet-step .sheet-step-body .disabled.field.fix-service-container,
.sheet-step .sheet-step-body .disabled.radio.checkbox,
.cin.side-panel.notes-side-panel .ui.disabled.button,
.ui.modal.paper-inclusive-step .disabled.field,
.ui.modal.paper-inclusive-step .ui.checkbox input[disabled] ~ label,
.ui.modal.paper-inclusive-step .ui.disabled.dropdown,
.ui.modal.paper-inclusive-step .ui.disabled.input,
.ui.modal.paper-inclusive-step
  .additional-costs
  .remove-container
  .ui.disabled.button,
.ui.modal.paper-inclusive-step
  .customer-current-spend
  .current-spend-container
  .current-spend-columns
  .description
  i.disabled.icon.close,
.sheet-step
  .sheet-step-body
  .paper-inclusive-step
  .field.sheet-radio-group
  .ui.toggle.checkbox.disabled
  > label
  .sheet-step
  .sheet-step-body
  .paper-inclusive-step
  .field.sheet-radio-group
  .ui.toggle.checkbox
  input[disabled]
  ~ label
  .sheet-step
  .sheet-step-body
  .schedule-step
  .ui.button.disabled.add-new-unit,
.sheet-step .sheet-step-body .schedule-step .ui.button.disabled.add-new-lfp,
.ui.modal.large-format-edit-modal .disabled.field .ui.disabled.dropdown,
.ui.modal.large-format-edit-modal .ui.disabled.button.add-media,
.sheet-step.schedule-stability-step .ui.disabled.input,
.ui.form.product-schedule-list .disabled.field .disabled.input,
.ui.modal form.note-form .disabled.field .ui.disabled.dropdown {
  opacity: 1 !important;
  cursor: not-allowed;
  pointer-events: all !important;
}

// Fixed input styling
.ui.form .field.fix-input-container {
  &.disabled {
    opacity: 1;
    pointer-events: all;
    > label {
      opacity: 1;
    }
    > div.ui.fix-input-wrapper.input {
      opacity: 1;
      cursor: not-allowed;
    }
  }
  > .ui.input.fix-input-wrapper {
    &.with-left-label {
      > div.ui.basic.label {
        user-select: none;
        cursor: default;
        border: 1px solid $color-pink;
        border-right-width: 0;
        font-weight: normal;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      > input {
        border-left-width: 0px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.left-label--currency {
        > input {
          padding-left: 0.15rem;
        }
        > .ui.basic.label.currency-label {
          line-height: 1.25em;
        }
      }
    }

    // fluid styling
    &.fix-input-fluid {
      &.with-left-label input {
        width: calc(100% - 22px);
      }
      &.with-right-label input {
        width: calc(100% - 32px);

        border-right-width: 0;
      }
      &.with-left-label.with-right-label input {
        width: calc(100% - 54px);
      }
    }
  }

  .text-area-wrapper {
    display: flex;
    .fixed-input-indicator {
      border: none;
      position: relative;
      right: 1.2rem;
      top: 0.25rem;
    }
    textarea {
      padding-right: 1rem !important;
    }
  }
}

.fixed-input-indicator {
  height: 1.75em !important;
  border: 1px solid $color-pink;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left-style: unset;
  background-color: $color-white;

  .trigger-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0.8em;
    height: 0.8em !important;
    // consistency with .highlighted-input
    font-weight: bold;
    border-radius: 5px;
    margin: 0.05em;

    cursor: pointer !important;

    > p {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      color: white;
      font-size: 10px !important;
      font-weight: normal;
    }
  }

  &.disabled > .trigger-content {
    cursor: not-allowed !important;
  }

  &.fix-input-type-none .trigger-content {
    background-color: $color-green;
  }
  &.fix-input-type-max .trigger-content {
    background-color: $color-light-red;
  }
  &.fix-input-type-range .trigger-content {
    background-color: $color-light-red;
    > p > img {
      max-width: 40%;
    }
  }
  &.fix-input-type-min .trigger-content {
    background-color: $color-blue;
  }
  &.fix-input-type-set .trigger-content {
    background-color: $color-yellow;
  }
  &.fix-input-type-lock {
    background-color: $background-color;
    border-left-style: solid;
    height: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    padding: unset !important;
  }
}

.fix-input-wrapper:not(.is-admin) .fixed-input-indicator {
  cursor: default !important;
}

.fluid-form-dropdown,
.fluid-form-dropdown > .field {
  max-width: 100%;
  width: 100%;
}

// id:78522 - revert when cinnamon pr #1119 is closed
// .ui.form.create-sheet .form-dropdown-container {
.form-dropdown-container {
  margin-bottom: 1rem;
}

.ui.form.login-form,
.ui.form.forgotten-password-form-container {
  .form-error {
    color: $color-red;
  }
}

// Responsive labels
.field .default-grid-field-label-container {
  font-weight: bold;
  p {
    display: inline;
  }

  @include breakpoint-sm {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
  }
}

.ui.grid .column.responsive-labelled-field > .field {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: flex-end;
}

.fixed-input-responsive-label > div {
  & p {
    font-weight: bold;
    display: inline;
    &.not-bold {
      font-weight: normal;
    }
  }
}

.cin.side-panel.users-filter-side-panel,
.cin.side-panel.sheets-filter-side-panel,
.large-format-printer-modal {
  .ui.dropdown {
    min-height: 1.75rem !important;
  }
}

.cin.side-panel.users-filter-side-panel,
.cin.side-panel.sheets-filter-side-panel {
  .filters-title {
    display: flex;
    align-items: center;
    > .clear-button {
      margin-left: 0.5rem;
      cursor: pointer;
      color: $color-light-purple;
      font-size: 16px;
      &:hover {
        color: $color-pink;
      }
    }
  }

  .field {
    margin-bottom: 1rem !important;
  }
  .ui.checkbox {
    margin-bottom: 0 !important;
  }
}

.cin.side-panel.sheets-filter-side-panel {
  .ui.icon.button.close-panel {
    z-index: 10;
  }
  .ui.grid {
    margin: 0 !important;
    > .row {
      padding: 0 !important;
      > .column {
        padding: 0 0.5rem !important;
      }
    }
    > .column {
      padding: 0 0.5rem !important;
    }
  }
}

.sheet-step {
  .field input,
  .ui.dropdown .text,
  .ui.dropdown .option,
  .field textarea,
  .field .currency-label {
    font-family: Barlow !important;
    font-weight: 500;
  }
}

.short-field {
  width: 9em !important;
  .with-right-label > input {
    width: 8em !important;
  }
}

.no-disable-box {
  .ui.form {
    .field.disabled {
      label,
      .ui.disabled {
        opacity: 1;
      }
      input,
      textarea,
      .dropdown {
        border-width: 0 !important;
        .icon {
          display: none;
        }
      }
      opacity: 1;
    }
  }
}

// List filter displays:

.search-filter-display {
  display: flex;

  > .filter-display {
    margin-left: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .filter-display-item {
      background-color: $color-light-purple;
      border-radius: 0.25rem;
      color: $white;
      padding: 0 0.5rem;
      height: 1.5rem;
      font-size: 12px;
      margin: 0 0.25rem;
      max-width: 10rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .clear-button {
      margin-left: 0.5rem;
      cursor: pointer;
      color: $color-light-purple;
      font-size: 16px;
      line-height: 1.75rem;
      &:hover {
        color: $color-pink;
      }
    }
  }
}

.ui.form .fix-bool-container {
  display: flex;
  .field {
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
    &.disabled,
    &.disabled .ui.checkbox label {
      cursor: not-allowed !important;
      pointer-events: all;
    }
    .ui.checkbox.fix-input-wrapper {
      margin-top: 0.25em !important;
    }
    .fixed-input-responsive-label {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
  .fixed-input-indicator {
    border: unset;
  }
}

.ui.form .fix-checkbox-container {
  display: flex;
  margin-right: 1em;
  .field {
    &.disabled,
    &.disabled .ui.checkbox label {
      cursor: not-allowed !important;
      pointer-events: all;
    }
    .ui.checkbox.fix-input-wrapper {
      margin-right: 1.5em !important;
      margin-top: 0.25em !important;
      margin-left: 0 !important;
      label {
        padding-left: 1.25em !important;
      }
    }
    .fixed-input-responsive-label {
      margin-right: 0.25em;
    }
  }
  .fixed-input-indicator {
    border: unset;
  }
}

.ui.form .fix-service-container {
  display: flex;
  margin-bottom: 0;

  .ui.dropdown {
    height: 1.75em !important;
    width: 16rem !important;

    &.disabled {
      opacity: 1;
    }
  }

  &.with-right-label .ui.dropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 15em !important;
    border-right: none;
  }
}

.ui.form .fix-lookup-dropdown-container {
  display: flex;

  &.field.disabled {
    pointer-events: unset;
    opacity: 1;
  }

  .ui.dropdown {
    height: 1.75em !important;
    &.disabled {
      opacity: 1;
    }
    &.fix-paperweight-dropdown {
      width: 7em !important;
    }
    .menu {
      width: max-content;
      max-height: 17rem;
    }
  }
  &.with-right-label .ui.selection.dropdown {
    border-right-style: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.ui.form .fix-unit-container {
  display: flex !important;

  .ui.dropdown {
    display: flex;
    width: 4.5rem !important;
    padding-right: 1rem !important;

    &.right-label--indicator {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 3.5em !important;
      padding-right: 0em !important;
    }

    background-color: $color-white;
    height: 1.75em;
    padding-left: 0.35em;

    .dropdown.icon {
      margin-left: 0;
    }

    i {
      padding: 0.35rem 0.1rem !important;
    }

    &.disabled {
      opacity: 1;
    }
  }
}

.ui.input.fix-input-wrapper:focus-within.with-left-label > div.ui.basic.label,
.ui.selection.active.dropdown + .fixed-input-indicator,
input:focus + .fixed-input-indicator {
  border-color: $color-blue-highlight !important;
}

.ui.form.highlighted-inputs {
  .ui.with-left-label.input > input[type="text"]:focus:focus {
    border-left: none !important;
  }
  .ui.with-right-label.input > input[type="text"]:focus:focus {
    border-right: none !important;
  }
  &.sc-bdVaJa:focus-within .ui.dropdown,
  .sc-bdVaJa:focus-within .ui.dropdown,
  .sc-bdVaJa:focus-within .menu {
    border-color: $color-blue-highlight !important;
  }
}

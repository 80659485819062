.ui.modal.credit-clearance {
  width: 60rem;
  max-width: calc(100% - 1rem);
  .input-values .field .ui.input {
    width: 100% !important;
  }

  .ui.form {
    .fields {
      margin: 0;
    }

    .grid {
      display: grid;
      column-gap: 1em;
      &.two-col {
        grid-template-columns: repeat(2, 1fr);
      }
      &.three-col {
        grid-template-columns: repeat(3, 1fr);
        .inline.field {
          width: 18.5em;
        }
      }
    }

    .inline.field {
      display: grid;
      grid-template-columns: auto 1fr;
      padding: 0;
      > label {
        white-space: nowrap;
        margin: 0 0.5em 0 0 !important;
      }
      > div.read-only {
        border-style: solid;
        border-color: $color-grey-2;
        border-width: 1px;
        border-radius: 3px;
        padding: 0 0.5em;
        p {
          color: $color-grey-3;
        }
      }
    }

    .grid.two-col.contacts {
      row-gap: 1em;
      .inline.field {
        grid-template-columns: 6em 1fr;
      }
    }

    .address {
      width: 100%;
      .inline.field {
        width: 100%;
      }
    }

    .address .inline.field,
    .fields.grid.two-col.top .inline.field:first-child {
      grid-template-columns: 9em 1fr;
      label {
        text-align: right;
      }
    }

    hr {
      background-color: $color-dark-purple;
      margin: 1em 0;
      height: 5px;
    }

    .grid.two-col.schedule {
      margin-bottom: 2em !important;

      .inline.field:last-child .read-only {
        border-style: none;
        padding-left: 0;
        p {
          color: $text-color;
          font-size: 22px;
          height: 1em;
          line-height: 1em;
        }
      }
    }

    .grid.three-col.input-values {
      .ui.labeled.input {
        width: calc(100% - 1em) !important;
        &:before {
          content: none;
        }
      }
    }
  }

  .ui.form > .fields,
  .ui.form > .field {
    margin-bottom: 1em;
    .include-upgrade {
      margin-left: 25.5rem;
      margin-bottom: -2.5rem;
    }
  }
}

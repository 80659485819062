
.schedule-stability-step{

    .ui.input{
        max-width: 4em !important;
        margin: 0.1em;
    }
    .ui.form .field .ui.input > input {
        padding: 0.1em 0.1em !important;    
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
    .grid{
        display: grid !important;
        grid-template-columns: 3fr 2fr 2fr !important;
        line-height: 1em;
        h5{
            font-size: 1em;
            font-weight: bold;
        }
    }
    @media only screen and (max-width: 1600px)  {
        .grid{
            grid-template-columns: none!important;
        }
    }
    .two-column{
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        padding-top: 1em;
    }
    .vmax-grid{
        display: grid !important;
        grid-template-columns: 2fr 3fr 2fr !important;
    }
    .vmax-detail-grid{
        display: grid !important;
        grid-template-columns: 4fr 1fr !important;
        background-color: $color-white;
        padding: 0em 0.2em;
        margin: 0.2em;
        border: 1px solid $color-dark-grey;
        line-height: 1.8em;
        font-weight: bold;
        .left-border{
            border-left: 1px solid $color-dark-grey;
            padding-left: 0.4em;
        }
        
    }
    .adjustment-grid{
        display: grid !important;
        grid-template-columns: 1fr 5fr !important;
        padding: 1em 0em 0em 0em;
    }

    .white-container{
        background-color: $color-white;
        border: 1px solid $color-dark-grey;
        padding: 0.5em;
    }

    .pink{
            padding: 1em 0.5em;
            margin-right: 0.5rem;
            border-radius: 5px;
            margin-bottom: 0.5rem;
            background-color: $sheet-co-funded-background;
            font-size: 0.8em;
            h5{
                text-align: center;
                font-size: 1.2em;
                display: block;
                padding-bottom: 0.24em;
            }
    }
    .green{
        padding: 1em 0.5em;
        margin-right: 0.5rem;
        border-radius: 5px;
        margin-bottom: 0.5rem;
        background-color: $sheet-supplier-background;
        font-size: 0.8em;
        h5{
            font-size: 1.2em;
            padding-top: 0.5em;
        }
    }
    .ui.table{
        border: none;
        border-top: 1px solid $color-dark-grey;
        border-bottom: 1px solid $color-dark-grey;
        padding-top: 0px;
        margin-top: 0px;
        
        thead{
            border-right: 1px solid $color-dark-grey;
        }
        thead th{
            padding: 0.1em 1em;
            border-left: 1px solid $color-dark-grey;
            background-color: $color-grey-1; 
        }
        thead th:last-of-type{
            border-right: 1px solid $color-dark-grey;
        }
        td{
            padding: 0.1em 0.2em;
            border-left: 1px solid $color-dark-grey;
            text-align: center;
        }
        td:first-of-type{
            text-align: left;
        }
        td:last-of-type, thead{
            border-right:  1px solid $color-dark-grey;
        }
        tr.bold td{
            border-bottom: 1px solid $color-dark-grey;
        }
        tr.italic td{
            font-style: italic;
        }
        tr.light-grey td{
            background-color: $color-light-grey;
        }
        tr.highlight td{
            background-color: $color-grey-1;
            font-weight: bold;
            border-top: 1px solid $color-dark-grey;
        }
        tr.highlight-alt td{
            background-color: $color-light-pink;
            font-weight: bold;
            border-bottom: 1px solid $color-dark-grey;
            border-top: 1px solid $color-dark-grey;
        }
        tr.blank{
            background-color: none !important;
            td{
                background-color: $sheet-co-funded-background;
                border-left: none;
                border-right: none;
            }
        }
        tr.border-bottom td{
            border-bottom: 1px solid $color-dark-grey !important;
        }
    }
    .comparison-adjustment-fields-width-override{
        max-width: 10em !important;
        width: 10em !important;
        min-width: 6em !important;
        ::placeholder{
            opacity: 1 !important;
            color: rgba(0, 0, 0, 0.87) !important;
        }
    }
    .negative{
        input{
        color: red !important;
        }
    }
}
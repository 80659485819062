.ui.search.dropdown {
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: $color-grey-1;
    padding: 0.1rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: bold;
}

.defaults-settings-container {
    .row {        
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .dealer-liquidity-charges-container {
        display: flex;
        flex-direction: column;
        .liquidity-table-group {
            display: flex;
            overflow-x: auto;
            flex-wrap: nowrap;
        }
    }    

    .dealer-liquidity-section, .yield-bands-grid, .defaults-grid, .paper-defaults {
        margin-top: 1rem;
        overflow-y: hidden;    
    }

    .dealer-liquidity-section {
        margin-right: 1rem;
        width: 41rem;
        min-width: 41rem;
        overflow-x: auto;
        border-top: 5px solid #5b2779;
        border-radius: 5px;
        box-shadow: 5px 5px 22px #0000001a;
        background-color: white;
        padding: 0em 1em;

        &.funder h4 {
            color: darken($color-green, 15%);
        }
        &.dealer h4 {
            color: darken($color-yellow, 15%);
        }
        &.cofunded h4 {
            color: $color-blue;
        }

        @include breakpoint-sm {
            margin-right: 0.1rem;
            width: 100%;
        }

        .title-container {
            display: flex;
            justify-content: space-between;
            h4 {
                margin-bottom: 0.25em;
                margin-top: 0.25em;
                padding-left: 0.5em;            
            }
            .subtitle {
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 15em;
                margin-top: 0.5rem;
                overflow: hidden;
            }
            button {
                margin-right: 0;
                img {
                    padding-left: 0.5em;
                }

                &.save-button {
                    background-color: $color-light-green;
                }

                &.cancel {
                    background-color: white;
                    padding: 0 0.5rem;
                    margin: 0.5rem;
                }
            }
        }

        .ui.form .dealer-liquidity-charge-table-container { 
            margin-bottom: 1rem;
            .grid.dealer-liquidity-charge-table .row {
                margin-top: 0;
                &:nth-child(odd) {
                    background-color: #f4f4f9;
                }
                &.funder-type > p {
                    font-size: 17px;
                    font-weight: bold;
                }
                &.funder > p {                    
                    color: $color-green;
                }
                &.dealer > p {
                    color: $color-yellow;
                }
                &.cofunded > p {
                    color: $color-blue;
                }
            }                
        }
    }
}


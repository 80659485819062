$funder-section-width: 23.67rem;

// Any styles that aren't specific to one step
.load-sheet-error {
  color: $color-red;
}

// root structure styling

.sheet-structure {
  > .column {
    @include breakpoint-not-sm {
      display: flex;
    }
    @include breakpoint-sm {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

// header component - title and actions and icons

.sheet-header {
  background: white;
  padding: 0.25em 0.25em 0.25em 1em;
  margin-bottom: 0.5em;
  border-radius: 5px;
  box-shadow: 5px 5px 22px #0000001a;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include breakpoint-sm {
    flex-direction: column;
  }

  .info-actions,
  .view-buttons-impersonate {
    width: 100%;
    display: flex;
  }
  .info-actions {
    justify-content: space-between;
  }

  .view-buttons-impersonate {
    justify-content: right;
    .view-buttons {
      display: flex;
      align-items: center;
      > label {
        margin-right: 0.75em;
        font-weight: normal;
      }
      .ui.buttons {
        .ui.button.view-button,
        .ui.button.view-button.disabled.active {
          background-color: $color-grey-1;
          padding: 0.25em 1em;
          font-weight: normal;
          font-size: 13px;
          color: $text-color;
          border-style: solid;
          border-width: 1px;
          border-color: $color-grey-3;
          border-right-style: none;
          opacity: unset !important;
          &:last-of-type {
            border-right-style: solid;
          }
          &.active {
            background-color: $color-bg-dark-beige-purple;
          }
        }
      }
    }
    .impersonate {
      margin-right: 0.5em;
      .tag {
        margin: 0 0.5em 0 1em;
      }
      .header-dropdown {
        width: 12.5em;
        > div.ui.search.dropdown {
          border: 1px solid $color-grey-3;
          min-width: unset;
          margin: 0 5px 0 5px;
          width: -webkit-fill-available;
          width: -moz-available;
          input.search,
          .text {
            width: calc(100% - 1.5em);
            min-width: unset;
          }

          > div.text {
            min-width: unset;
            float: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .visible.menu.transition {
        max-height: 20em;
        overflow: auto;
        left: -10.7em;
        .item {
          white-space: nowrap;
          width: 22em;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .ui.grid {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    > .row,
    .row > .column {
      padding: 0 !important;
      margin: 0 !important;
    }
    > .row > .column:not(:last-child) {
      @include breakpoint-not-sm {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  @include breakpoint-not-sm {
    display: flex;
  }

  & div {
    display: flex;
    justify-content: center;
    &.sheet-action-buttons {
      justify-content: flex-end;
    }
  }

  & .sheet-title {
    display: block;
    & h5 {
      margin-bottom: 0;
    }
    .icon {
      margin-left: 0.5em;
      cursor: pointer;
    }
  }

  & .sheet-icon-detail {
    display: flex;
    align-items: center;
    @include breakpoint-not-sm {
      margin: 0 0.5rem;
    }
    @include breakpoint-sm {
      justify-content: flex-start;
    }

    & p {
      padding-left: 0.5em;
    }
  }

  & .history {
    align-items: center;
    @include breakpoint-sm {
      width: 33%;
      font-size: 28px;
    }
  }

  & .sheet-action-buttons {
    position: relative;

    align-items: center;
    // custom breakpoint because buttons break out of frame otherwise
    @include customBreakpoint("min-width: 1501px") {
      display: flex;
    }
    @include breakpoint-sm {
      width: 67%;
      > button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

// right panel component

.sheet-right-panel {
  width: auto;
  padding-left: 1em;
  max-width: calc(100% - 21rem);

  &.view-all-steps {
    .sheet-step {
      margin-bottom: 0.25rem;
      .action-bar {
        min-height: unset;
        .ui.button.generate-btn {
          background-color: $color-admin-green !important;
        }
        .invisible {
          background: transparent;
          padding: 0;
          margin: 0;
          div {
            height: 1.5em;
            width: 4em;
          }
        }
      }
    }
  }

  @include breakpoint-sm {
    min-width: 100%;
    padding-left: 0;
  }
}

// left panel component

.sheet-left-panel {
  width: 25%;
  flex-grow: 2;
  max-width: 20rem;
  min-width: 18rem;

  .section-1 {
    background-color: $color-bg-beige-purple;
    .top {
      padding: 0 0.5rem;
    }

    .page-view-container {
      margin-bottom: 0.25rem;
      .expand-collapse-all {
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0.1rem 2rem 0.1rem 0.1rem;
        align-items: center;
        > label {
          color: $color-dark-purple;
          font-weight: normal;
          font-size: 12px;
          cursor: pointer;
          &:hover {
            color: lighten($color-dark-purple, 10%);
          }
        }
      }
    }
  }

  @include breakpoint-sm {
    width: 100%;
    margin-bottom: 0.25em;
  }

  @include breakpoint-sm {
    min-width: 100%;
  }
}
div.sheet-passcode-modal {
  width: auto !important;
  div.dimmable {
    &.fit-loader {
      padding: 0 1.5em !important;
    }
  }
  div.header {
    text-align: center;
    &::after {
      content: ":";
    }
  }
  div.field {
    margin-bottom: 0 !important;
  }
  span.p-random-key {
    display: block;
    text-align: right;
  }
  div.custom-action-container {
    text-align: center !important; //one-off for this modal - center-aligned buttons
    padding: 0 !important;
  }
  .ffDHgC .ui.input {
    width: 100% !important;
  }
  .ui.form .field {
    max-width: 2em;
  }
  .right {
    padding-bottom: 0.5em;
    .field {
      float: right !important;
    }
  }
  .ui.input > input {
    text-align: right;
  }
  .red {
    padding-bottom: 0.2em;
  }
  .no-pin-account-page-redirect {
    text-align-last: center;
    .no-pin-text-spacing {
      margin: 0.5em;
    }
  }
  .pin-loading {
    min-height: 5em;
  }
}
.view-toggle-container {
  font-family: "Barlow";
  display: flex;
  flex-direction: row;
  align-items: stretch;
  cursor: pointer;
  .view-toggle {
    padding: 0.25rem;
    width: 50%;
    background-color: $color-grey-1;
    border-color: $color-grey-4;
    border-style: solid;
    border-width: 2px;
    display: flex;
    justify-content: center;
    &.active {
      background-color: $color-bg-dark-beige-purple;
    }
    i.icon {
      margin-left: 0.5rem;
      color: $color-white;
    }
    &:nth-child(1) {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right-width: 0;
    }
    &:nth-child(2) {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

// side panel calculation breakdown

.calculation-breakdown,
.large-format-summary {
  padding-bottom: 1em;
  h3 {
    margin-top: 2rem;
    font-size: 20px;
  }
  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 16px;
  }
  h5 {
    margin-top: 0.5em;
    font-size: 14px;
  }
  .capital-calc-item {
    padding: 1em;
    background-color: $color-lighter-blue;
    border-radius: 0.5em;
    margin-bottom: 1em;
  }

  .funder-calc {
    h4 {
      margin-top: 0;
    }
    padding: 0.25em 0.25em;
    background-color: darken($color-lighter-blue, 5%);
    border-radius: 0.5em;

    .cofunded-capital-row {
      padding: 0.25em 0.25em;
      background-color: darken($color-lighter-blue, 10%);
    }
  }

  table.paper-meters {
    font-size: 12px;
    text-align: left;
    border-spacing: 1em 0;
  }

  .cin.data-list-item {
    padding: 0.25rem;
    dt {
      font-weight: normal;
      font-size: 12px;
      padding: 0;
    }
  }
}

// wizard component - user-friendly step navigation

.sheet-wizard {
  .wizard-tab {
    display: flex;
    justify-content: space-between;
    padding: 0.75em 0.25em;
    cursor: pointer;

    @include breakpoint-sm {
      padding-left: 2.5em;
      padding-right: 2.5em;
    }

    .wizard-tab-text {
      display: block;
      width: 90%;
      padding: 0;

      > span {
        font-size: 14px;
      }

      .split-totals {
        margin: 0;
        span.number-formatter {
          display: inline-block;
          vertical-align: top;
          overflow-wrap: break-word;
          @include customBreakpoint("min-width: 375px") {
            width: 33%;
          }
          &:not(:last-child) {
            padding: 0 0.5em 0 0;
          }
          &.dealer-admin {
            color: $color-admin-green;
          }
          &.ips-admin {
            color: $color-ips-buttons;
          }
        }
      }
    }
  }

  @include breakpoint-sm {
    margin-left: 0px;
  }

  .wizard-tab.active {
    background: $color-light-purple;

    & div {
      > p {
        color: white;
      }
      > strong {
        color: white;
      }
      > span {
        color: white;
      }
    }
    i.circle.outline.icon,
    i.check.circle.outline.icon {
      color: white;
      margin-right: 0;
    }
    .split-totals .cin.number-formatter {
      &.dealer-admin {
        color: lighten($color-admin-green, 10%);
      }
      &.ips-admin {
        color: lighten($color-ips-buttons, 20%);
      }
    }
  }

  p {
    font-size: 15px;
    color: $black;
  }

  strong {
    font-family: "Barlow";
    font-size: 17px;
    color: $color-light-purple;
    font-weight: bold;
  }
}

// totaliser component - used to display totals

.ui.segment.totaliser {
  border-radius: 4px;
  font-weight: bold;
  display: block;
  padding: 0.1em 0.75em !important;
  margin: 0;

  & .totaliser-value {
    float: right;
  }

  &.services-totaliser {
    background: $color-totaliser-background;
    width: 100%;
    margin-bottom: 0.25rem;
    &::after {
      font-size: 0 !important; // Prevents the services body div always having a vertical scroll bar visible.
    }
  }

  &.paper-totaliser,
  &.paper-units-totaliser {
    background: $colour-lighter-purple;
  }

  &.paper-units-totaliser {
    display: flex;
    justify-content: space-between;
  }

  &.capital-totaliser,
  &.funding-capital-investment-totaliser {
    background: $color-dark-lilac;
    color: white !important;
  }

  &.funding-capital-investment-totaliser {
    width: 100%;
  }

  &.capital-totaliser {
    min-width: 18rem;
    margin-top: 0.1rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    width: 23.67em;
    @include customBreakpoint("max-width: 1064px") {
      width: 100% !important;
    }
    max-width: 100%;

    > span {
      color: white;
      font-weight: bold;
    }

    &.medium {
      width: 47.84em;
      @include customBreakpoint("max-width: 1096px") {
        width: 23.67em;
      }
    }
    &.long {
      width: 72em;
      @media only screen and (max-width: 1611px) {
        width: 47.84em;
        @include customBreakpoint("max-width: 1106px") {
          width: 23.67em;
        }
      }
    }
  }

  @include breakpoint-md {
    &.services-totaliser,
    &.capital-totaliser,
    &.funding-capital-investment-totaliser {
      width: 100%;
    }
  }
}

.sheet-step {
  .sheet-step-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .title {
      display: flex;
      align-items: center;
    }
  }
  & label {
    color: $black !important;
  }
  .admin-fields {
    p,
    label {
      color: $color-admin-green !important;
    }
    label::before {
      border-color: $color-admin-green !important;
    }
    label::after {
      background-color: $color-admin-green !important;
    }
  }

  & .cin.action-bar {
    margin-bottom: 0;
    .invisible {
      background: transparent;
    }
  }
  h5 {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
  }

  .field > .ui.radio.checkbox.sheet-radio-group {
    margin-bottom: 0;
    margin-right: 0;
    > label {
      padding-left: 1.1em;
    }
  }

  .ui.left.labeled.input {
    > .ui.label {
      border-color: $color-pink;
      border-style: solid;
      border-right-style: none;
      border-width: 1px;
      font-weight: normal;
      color: $text-color;
    }
  }

  .field.fix-input-container {
    margin-bottom: 0.25rem;
  }

  .ui.left.labeled.input > input,
  .field.fix-input-container
    > .ui.labeled.input.fix-input-wrapper.left-label--currency
    > input {
    padding-left: 0 !important;
  }

  @include breakpoint-not-sm {
    padding: 0.25em 1em;
  }
  @include breakpoint-sm {
    padding: 1em;
    margin: 2em;
  }

  @include breakpoint-not-sm {
    & .cin.action-bar {
      display: flex;
      .invisible {
        background: transparent;
      }
    }
  }

  & .save-button.ui.button {
    align-items: center;
    & div {
      > img {
        display: inline-block;
        padding-bottom: 5px;
        padding-left: 0.5em;
      }
    }

    > img {
      display: inline-block;
      padding-bottom: 5px;
      padding-left: 0.5em;
    }

    & p {
      color: white;
    }

    @include breakpoint-sm {
      width: 100%;
      margin: 0.25em 0;
      justify-content: center;
    }
  }

  & .close-button.ui.button {
    align-items: center;
    background-color: red;
    padding: 0.5em !important;
    margin: 0.5em !important;
    border-radius: 3px;
    font-size: 17px;

    & div {
      > img {
        display: inline-block;
        padding-left: 0.5em;
      }
    }

    > a {
      padding: 0.52em !important;
      margin: 0.52em !important;
    }

    > img {
      height: 21px;
      display: inline-block;
      padding-left: 0.5em;
    }

    & p {
      color: white;
    }
    @include breakpoint-sm {
      width: 100%;
      margin: 0.25em 0;
      justify-content: center;
    }
  }
  & .ui.primary.button.back-button {
    background-color: $color-grey-2;
    margin-left: 0;
  }

  & .ui.form > div > .inline.field > p {
    margin-right: 0.5em;
    font-size: 14px;
  }

  & .ui.form label {
    font-size: 14px;
    margin-bottom: 0 !important;
  }

  & .ui.form > .fields > .field {
    width: 100%;
  }

  .cin.page-loader {
    padding-top: 0;
    justify-content: flex-end;
    padding-right: 1em;
  }
  .ui.form > .fields > .field > div:nth-child(2) {
    margin-left: 0;
  }

  .ui.form > .twelve.fields {
    width: 75%;
    > .inline.field {
      padding-left: 0.75em;
    }
  }

  .sheet-radio-title {
    display: flex;
    &.two-line {
      min-height: 2.75em;
      display: flex;
      align-items: flex-end;
    }
    & p {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;

      &.not-bold {
        font-weight: normal;
      }

      &.emphasis {
        font-size: 17px;
        margin-top: 3px;
      }
    }
  }
  :not(div.paper-inc-not-grey-out) {
    > div.ui.toggle.checkbox {
      margin: 0 !important;
      label {
        display: inline-block;
        vertical-align: middle;
        &::before {
          height: 1rem !important;
          width: 2rem !important;
        }
        &::after {
          height: 1rem !important;
          width: 1rem !important;
        }
      }
      &.checked {
        input:checked ~ label:after {
          left: 1.15rem !important;
        }
      }
    }
  }
}

.ui.button.wizard-title-button {
  background-color: transparent;
  color: $color-light-blue;
  font-weight: bold;
  padding: 0.25em;
  margin: 0;
  margin-left: 0.75rem;
  display: flex;
  align-items: center;

  &.not-bold {
    font-weight: normal;
  }

  &:hover {
    color: $color-blue;
    background-color: transparent;
  }
  &.supplier-button {
    color: black;
    &:hover {
      color: lighten(black, 30%);
    }
  }

  &.admin-button {
    color: $color-admin-green;
    &:hover {
      color: $color-hover-admin-green;
    }
  }
  &.ip-button {
    color: $color-ips-buttons;
    &:hover {
      color: $color-hover-ips-button;
    }
  }
  & > .ui.image {
    margin-right: 0.1rem;
    height: 0.4em;
  }
}

.paper-inclusive-step .top {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

// Required styling

.required-star {
  color: red;
  font-weight: bold;
  padding-left: 0.25em;
}

// Validation message styling for saveNotice

.sheet-validation-message {
  font-weight: bold;
  margin-right: 0.5rem;
  &.sheet-validation-error {
    color: $negative-color;
  }
  &.sheet-validation-warning {
    color: $color-orange;
  }
  &.sheet-validation-info {
    color: $color-light-blue;
  }
  &.sheet-validation-success {
    color: $positive-color;
  }
}
span.calc-error {
  display: inline-block;
  color: $color-red;
  font-weight: 700;
  margin: 1em 0;
  line-height: 1rem;
}

// TypeAheads
.ui.selection.dropdown {
  > .menu > div.item {
    padding: 0.25rem 0.5rem !important;
  }
}

//inline loader
.ui.active.loader.inline-loader {
  position: relative;
  transform: none;
  left: 0;
  top: 0;
  &.centralise {
    margin: auto;
  }
}

//edit modal button
.step-edit-button {
  width: max-content;
  color: $color-light-blue !important;
  font-weight: normal !important;
  background-color: transparent !important;
  padding: 0 !important;
  height: 1.5rem;
}
div.ui.modal {
  &.paper-inclusive-step {
    width: 80vw;
  }

  @media only screen and (max-width: 1800px) {
    &.paper-inclusive-step {
      width: 95vw;
    }
  }

  &.ui.modal.custom-actions {
    div.actions {
      display: none;
    }
    .custom-action-container {
      text-align: right;
      button,
      img {
        display: inline-block;
      }
    }
    button > img {
      padding-bottom: 5px;
    }
    .print-button > img {
      padding: 0 0.3em 5px 0;
    }
  }
  div.actions {
    button.positive {
      > button {
        display: contents;
        font: inherit;
        color: inherit;
      }
    }
  }
}
div.overall-values {
  display: flex;
  text-align: center;
  > div div.field {
    > div.fix-input-wrapper {
      max-width: 5em;
    }
  }
  div.lowered {
    margin-top: 1.3em;
    margin-right: 1em;
    min-width: 11em;
  }
  div.currency-label {
    line-height: 36px;
  }
}

.preview-document-modal {
  .actions {
    padding: 0 !important;
    button {
      display: none;
    }
  }

  .action-buttons {
    justify-content: space-between;
    display: flex;
    margin-top: 15px;

    .button-on-right {
      display: flex;
      justify-content: flex-end;
      .back {
        padding: 10px;
        cursor: pointer;
        margin-top: 5px;
        font-size: 18px !important;
        font-weight: 100;
      }
      .generate-btn {
        background-color: $color-admin-green !important;
      }
    }
  }
}

.generate-document-modal {
  form.ui.form .field .ui.selection.dropdown {
    min-height: 1.5em;
    .text {
      min-height: 1.1em;
    }
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    button.ui.button {
      padding: 10px;
      cursor: pointer;
      margin-top: 5px;
      margin-bottom: 0;
      font-size: 18px !important;
      &.cancel {
        font-weight: 100;
        color: $color-grey-5;
        background-color: transparent;
        &:hover {
          color: $color-grey-5;
          background-color: transparent;
        }
      }
      &.generate-btn {
        background-color: $color-admin-green !important;
      }
    }
  }
  div.actions {
    display: none;
  }
}

.ui.table.schedule-product-table,
.ui.table.schedule-unit-table {        
    th {
        background-color: $color-light-purple-alt;
        padding: 0.25em;
    }
    td, th{
        border-right: 1px solid $color-grey-1;
        border-bottom: 1px solid $color-grey-1;        
    }    
    
    tr td {                     
        padding: 0.2em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .field > .ui.checkbox {
            margin: 0.2em 0 0;
        }
    }        
}

.schedule-unit-table{
    border-top: 1px solid $color-grey-1;
    border-left: 1px solid $color-grey-1;
    
    button.ui.button.primary.apply-to-all {
        font-size: 13px;
    }

    thead tr:nth-of-type(1) th{
        border-bottom: none;
        background-color: $color-light-purple-alt;

        &.agreed,
        &.suggested,
        &.keep {
            width: 10em;
        }       
    }
    thead tr:nth-of-type(2) th{
        background-color: $color-light-purple-alt;
        padding: 0px;
        button.primary{
            margin:auto;
            margin-bottom: 0.5em;
        }
    }
    td .field:not(.inline) > .ui.checkbox { 
        input[type=checkbox]{
            margin:auto !important;
            position:relative !important;
        }
    }
    td{
        padding: 0.2em 0.5em !important;
        background-color: $color-light-purple-alt;
        
        &.suggested .field > .ui.input > input,
        &.suggested .field > .ui.input {
            width: 9em !important;
        }
    }
    td:nth-of-type(3), td:nth-of-type(4){
        background-color: $color-white;
    }
  }



div.ui.modal.visible.upgrade-request-modal {
    .ui.form{
        padding-top:1em;
    }
    .right-align{
        text-align: center;
    }
    .ui.grid.upgrade-types {
        margin-bottom: 1em;
        .row{            
            padding: 0.2em!important;
            .column {
                display: flex;
                align-items: center;
                label { margin: 0; }
                    &.credit-available {
                        justify-content: flex-end;
                    }
                .ui.checkbox {
                    margin: 5px 0 0 0;
                    label { margin: 0; }
                }
            }
        }
    }          
    .credit-available-label {
        margin-right: 0.5em;
    }
    .unit-table-container {
        margin-bottom: 1rem;
    }
    div.retain-container > .field {
        label {
            margin-bottom: 0.25rem;
        }
        .ui.checkbox.retain {
            display: inline;
            margin: 0.25em 1em;
        }
        margin-bottom: 1em;
    }
    .ui{
        &.form td .field:not(.inline) >  &.checkbox {
            margin: 0.2em 0 0.2em 0;
        }
        &.checkbox .box, &.checkbox label{
            display: inline;
        }
        &.input > input{
            text-align: center;
        }

        &.form textarea{
            min-height: 7em;
        }
    }
    label{
        font-weight: normal;
    }
    .unit-table{
        padding: 1em 0em;
    }
    .schedule-product-table {      
        margin: auto;
        thead th{
            background-color: $color-light-purple-alt;
        }  
        td { 
            padding: 0;
        }        
    }
    label{
        font-weight: normal;
    }    
    .red-highlight{
        color: $color-red;
    }
}
.content-container.sheet-step.schedule-step {
  border-top-color: $color-system-schedule;
}

div.sheet-step .ui.form div.schedule-step {
  .system-schedule-top-row {
    justify-content: space-between;
    .admin-fields {
      margin-top: 0.1em;
      display: flex !important;
      .inline {
        margin-right: 2em;
      }
    }
  }
  .title {
    margin-bottom: 0;
  }
  .schedule-step-header {
    display: flex;
    align-items: center;
  }
  div.empty-list {
    width: 100%;
    text-align: center;
    font-weight: 700;
    padding: 2em 0;
  }
  .schedule-container {
    max-width: 100%;
    overflow: auto;
    form.ui.form {
      div.field {
        margin: 0 !important;
      }
    }
    &.empty {
      padding-bottom: 0;
      min-height: 1em;
      overflow: hidden;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $scrollbar-color;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    .fix-input-container {
      .ui.input.fix-input-wrapper > input {
        padding: 0.2em 0.5em !important;
      }
      &.tier .ui.input.fix-input-wrapper > input {
        padding-right: 0 !important;
      }
      &.tier .ui.fix-input-wrapper .fixed-input-indicator {
        height: 1.314em !important;
      }
    }
    .ui.input.fix-input-wrapper > input {
      padding: 0.2em 0.5em !important;
    }
    &.tier .ui.input.fix-input-wrapper > input {
      padding-right: 0 !important;
    }
    &.tier .ui.fix-input-wrapper .fixed-input-indicator {
      height: 1.315em !important;
    }
  }

  .fix-bool-container {
    .fixed-input-indicator {
      background: transparent;
      margin-left: 0.5em;
    }
  }

  .unit-dropdown {
    width: 4.5rem !important;
    div.text {
      width: 2.4em;
    }

    min-height: unset;
    i.dropdown.icon {
      padding: 0.25rem 0.5rem;
    }

    .menu.transition > .item {
      padding: 0.5rem !important;
      display: flex;
      justify-content: flex-start;
    }

    .field > .ui.selection.dropdown {
      padding-left: 5px !important;
    }
  }

  .schedule-item-container {
    border-left-style: solid;
    border-width: 0.35em;
    border-radius: 0.25em;
    margin-bottom: 0.25em;
    &:not(.last) {
      margin-bottom: 0.5em;
    }

    transition: border-color 1s ease 0.5s;
    &.system-schedule {
      background-color: $system-schedule-item;
    }
    &.large-format {
      background-color: $large-format-item;
    }
  }
  .ui.button.array-remover {
    background-color: unset;
    padding: 0 0.5rem;
  }

  margin-bottom: 0;
  .schedule-header,
  .schedule-item,
  .schedule-item-bottom-1 {
    align-items: center;
    font-size: 16px;
    padding: 0em 0.5em;
    & .field {
      margin-bottom: 0;
    }
    & .col3,
    .col6,
    .col7,
    .col8 {
      & div.field {
        width: 3rem !important;
      }
    }
    > * {
      display: inline-block;
    }
  }
  .schedule-header {
    font-weight: 700;
    padding-left: 1em;
    white-space: nowrap;
  }
  .schedule-item {
    &.system-schedule {
      background-color: $system-schedule-item;
    }
    &.large-format {
      background-color: $large-format-item;
    }
    &.top {
      white-space: nowrap;
      position: relative;
      width: max-content;
      border-top-right-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
      &.expanded {
        border-bottom-right-radius: 0em;
      }
    }
  }
  .bottom-label-main {
    font-weight: 700;
    margin-right: 0.25rem;
    &.not-bold {
      font-weight: normal;
    }
  }
  .bottom-label-prefix {
    margin-right: 0.25rem;
    font-weight: normal;
    &.bold {
      font-weight: bold;
    }
  }
  .input.right.labeled {
    margin-right: 1rem;
    > input {
      border-right-width: 0;
      padding-right: 0.25rem;
    }
    > .ui.label {
      display: flex;
      align-items: center;
      font-size: 16px;
      background-color: white;
      border: 1px solid $color-pink;
      border-left-width: 0;
      padding: 0;
      padding-right: 0.25rem;
    }
  }
  .col {
    text-align: center;
    > .field {
      display: inline-block;
    }
    &.tier {
      .ui.labeled.input > input {
        width: 1.5rem;
      }
      .ui.input > input {
        font-size: 12px;
      }
      .tier-labels {
        display: inline-block;
        line-height: 16px;
        top: 5px;
        position: relative;
        .bottom-label-main {
          display: block;
          font-size: 13px;
          text-align: left;
          line-height: 7px;
        }
        .bottom-label-prefix {
          font-size: 12px;
        }
        &.tier-3 {
          min-width: 5.2rem;
          > label {
            margin-left: 0.5rem;
          }
        }
      }
      opacity: 0;
      transition: opacity 0.75s ease 0.25s;
      &.visible {
        opacity: 1;
        transition: opacity 0.75s ease 0.25s;
      }
    }
  }
  .col-0 {
    width: 1.4em;
  }
  .col-1 {
    > div.field {
      vertical-align: middle;
      display: inline-flex;
      > div.selection.dropdown {
        width: 4em !important;
        padding-left: 0.5em !important;
        padding-right: 0.25em !important;
      }
    }
    width: 7rem;
    position: relative;
    white-space: normal;
  }
  .col-2 {
    width: 17.5em;
    white-space: normal;
  }
  .col-3 {
    width: 10.5rem;
    white-space: normal;
  }
  .col-4 {
    width: 10.5rem;
    //text-align: right;
    white-space: normal;
    .fix-bool-container {
      margin-left: 25%;
    }
  }
  .col-5 {
    width: 10rem;
    white-space: normal;
    .fix-bool-container {
      margin-left: 25%;
    }
  }
  .col-6 {
    width: 9rem;
    white-space: normal;
    .fix-bool-container {
      margin-left: 25%;
    }
    .flex {
      display: flex;
    }
    .tier-toner-trigger {
      margin-left: 0.7em;
      text-decoration: underline;
      color: $color-text-link;
      &:hover {
        opacity: 80%;
        cursor: pointer;
      }
    }
  }
  .col-lf-name {
    width: 10rem;
    padding-left: 4.25em;
    text-decoration: underline;
    vertical-align: middle;
    :hover {
      cursor: pointer;
      opacity: 80%;
    }
    &.large {
      width: 19rem !important;
    }
    > label {
      display: inline-block;
      max-width: 100%;
      overflow: clip;
      white-space: break-spaces;
    }
  }

  .col-7 {
    width: 12rem;
    white-space: normal;
  }
  .col-8 {
    width: 7rem;
    white-space: normal;
  }

  label.unit-label {
    font-weight: normal;
  }

  & .calculable-field {
    transition: color 0.25s;
    &.value-loading {
      display: none;
    }
  }
  .schedule-radio-buttons {
    display: flex;
  }

  .product-schedule-button-container {
    display: flex;
    justify-content: flex-end;
  }
}

div.schedule-container .array-error:last-child {
  margin-bottom: 0em !important;
}

div.schedule-container div.ui.toggle.checkbox {
  margin-bottom: 0.5rem !important;
}

div.warning-message {
  color: red;
}

div.sheet-step .ui.form div.schedule-step,
.modal-styled-popup {
  div.unit-icon {
    height: 0.8em;
    width: 0.8em;
    border-radius: 0.4em;
    border: 1px solid #404040;
    display: inline-block;
    margin-right: 0.5rem;
  }
  div.sheet-step-header {
    justify-content: start;
  }
  div.unit-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  div.unit-icon.black {
    background: transparent linear-gradient(90deg, #ffffff 0%, #000000 100%) 0%
      0% no-repeat padding-box;
  }
  div.unit-icon.colour {
    background: transparent
      linear-gradient(
        180deg,
        #ff0000 0%,
        #de5dec 21%,
        #0019ff 40%,
        #00dcff 52%,
        #00ff44 65%,
        #b3ff00 73%,
        #b4b400 86%,
        #ff7700 100%,
        #ff0000 100%
      )
      0% 0% no-repeat padding-box;
  }
  div.unit-icon.utility {
    background-color: $color-purple;
  }
}

.modal-styled-popup {
  .ui.unit-dropdown {
    width: 5rem !important;
    .unit-label-container label {
      margin-bottom: unset;
    }
  }
}

.large-format-create-modal,
.large-format-edit-modal {
  width: 100% !important;
  max-width: 65rem;
  .ui.dropdown {
    border: 1px solid $color-pink;
    padding: 0.25em 0.75em;
    min-height: unset;
    max-width: 15rem !important;
    min-width: unset !important;
    i {
      padding: 0.25em 0.75em !important;
    }
  }
  div.ui.loader.inline-loader {
    margin: 1em 0 0 10em;
    &::after {
      border-color: $color-grey-6 transparent transparent;
    }
  }
  .header-test {
    font-size: 20px;
  }
  .box {
    border-style: solid;
    border-color: #360236;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 0.5rem;

    .add-media {
      background-color: unset;
      padding: 0;
    }

    .large-format-values {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .field,
      .field > .ui.input {
        width: 8rem !important;
      }

      .roll-media-totals {
        text-align: center;
      }

      .cin.data-list-item {
        text-align: center;
        dt {
          font-size: 13px;
        }
      }

      &.printer-conf {
        .printer-input-wrapper {
          width: auto !important;
          .field {
            width: auto !important;
            label {
              display: block;
            }
          }
        }

        .values-section {
          display: contents;
          .t-market {
            width: 20em;
            .field {
              width: 20em !important;
              .input {
                width: 20em !important;
              }
            }
          }
        }
      }

      &.ink-inclusive {
        &.blue {
          background-color: $background-blue-color;
        }
        &.cream {
          background-color: $background-cream-color;
        }
        .ink-inclusive-input-wrapper {
          width: auto !important;
          .field {
            width: auto !important;
            label {
              display: block;
            }
          }
          .ui.image.ink-usage-image {
            margin: 1em 0 0.5em 1em;
            width: 10em;
            height: 10em;
            object-fit: contain;
          }
        }

        .ink-calc-container {
          &.extra-margin {
            margin-right: 4em;
          }
          .title-container {
            text-align: center;
          }
          .grid-container {
            display: flex;
            flex-wrap: wrap;
            .ui.grid.ink-calc-grid {
              background-color: $white;
              margin: 0.2em !important;
              padding: 0.2em !important;
              border: 1px solid $color-pink;
              * {
                padding: 0;
                margin: 0;
              }
              width: 14rem;
              padding: 0;
              margin: 0;
              font-size: 12px;
              .title {
                font-size: 14px;
              }
              .column {
                padding: 0;
                margin: 0;
              }
              .inline-loader::before {
                border-color: $color-grey-2;
              }
            }
          }
        }
      }

      &.roll-fed-media {
        background-color: $background-red-color;
        position: relative;
        margin-bottom: 0.5em;

        .remove-media {
          position: absolute;
          top: 0rem;
          right: 0rem;
          background-color: unset;
          padding: 0;
          margin: -0.15rem;
        }

        .totals-section {
          justify-content: center;
          display: flex;
          width: 100%;
          font-weight: bold;
          align-items: center;
          .first {
            text-align: center;
          }
          .second {
            width: 5em;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .purple {
            color: purple;
            margin: 1em;
          }
        }

        .inputs-section {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          div.ui.loader.inline-loader {
            margin-left: 5em;
          }
          div.width-dropdown-pair {
            display: flex;
            justify-content: space-evenly;
            > div.field {
              display: inline-block;
              width: 6rem !important;
              div.dropdown {
                width: 6.5rem !important;
              }
            }
          }
        }

        .values-section {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: calc(100% - 31rem) !important;
          min-width: 25rem;
        }
      }

      .ink-inclusive-values {
        width: 14em;
      }

      .media-width {
        width: 15rem !important;
        .ui.selection.dropdown {
          width: 8rem !important;
        }
      }

      .media-selected {
        width: 15rem !important;
      }

      .disabled {
        &:not(div.ui.dropdown) {
          text-align: center;
          opacity: 1;
        }
        label {
          text-align: left;
        }
        .ui.input > input {
          text-align: center;
          border: 0px !important;
          background-color: transparent;
        }
      }
      .read-only {
        opacity: 1;
        text-align: center;
        padding: 0.5em;
      }
    }
  }
  .actions .ui.positive.button {
    &:not(.large-format-save) {
      i {
        display: none;
      }
      .ui.button {
        width: 100%;
      }
    }
    .large-format-save {
      padding: 0.75rem !important;
    }
    .large-format-save-btn {
      padding: 0.7rem 0.8rem;
    }
    padding: 0 !important;
  }
}

.extras {
  display: flex;
  flex-flow: row wrap;
  > * {
    flex-grow: 0;
    &[data-spacer] {
      flex-grow: 1;
    }
  }
  .field {
    &.inline {
      margin-right: 3em;
      label {
        margin-right: 0.5em;
      }
      .ui.selection.dropdown {
        width: 5em !important;
      }
    }
    div.ui.radio {
      margin: 0 1em 0 0;
      > label {
        padding: 0 0 0 1.5em;
      }
    }
  }
}

.fix-unit-dropdown {
  width: 6rem;
}
body ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $scrollbar-color;
}
.tiered-toner-modal {
  max-width: fit-content !important;
  width: 95% !important;

  .container {
    overflow: auto hidden;
    max-width: 100%;
    .table-body {
      min-width: fit-content;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $scrollbar-color;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .headers {
    font-weight: bold;
    align-items: flex-end;
  }
  .left-border {
    border-left: 1px solid rgba(0, 0, 0, 0.4);
  }
  .out-borders {
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    border-right: 1px solid rgba(0, 0, 0, 0.4);
  }
  .col-0 {
    width: 3em;
  }
  .col-1 {
    width: 10em;
  }
  .col-2 {
    flex-grow: 0.7;
  }
  .col-3 {
    width: 7em;
  }
  .col-4 {
    width: 6em;
  }
  .col-5 {
    width: 5em;
  }
  .col-6 {
    width: 5em;
  }
  .col-7 {
    width: 5em;
  }
  .col-8 {
    display: flex;
    width: 12em;
    .col-8-b {
      .fix-input-hidden {
        width: 3.75em !important;
      }
    }
  }
  .col-9 {
    display: flex;
    width: 17em;
    .col-9-a {
      display: flex;
    }
    .col-9-d {
      display: flex;
    }
    label {
      display: flex !important;
    }
  }
  .col-10 {
    display: flex;
    width: 14em;
  }

  .headers {
    display: flex;
    min-width: fit-content;
  }
  .content {
    display: flex;
    background: $background-tiered-toner-green;
    .fix-input-container {
      margin: 2px 5px 2px 5px;

      .fix-input-wrapper {
        input {
          height: 2.4em;
          border: $color-pink 1px solid;
          margin-right: -1px;
          &:focus {
            border: $color-blue-highlight 1px solid;
          }
        }
      }
    }

    .col {
      text-align: center;
      justify-content: center;
      align-self: center;
      > .field {
        display: inline-block;
      }
      &.tier {
        .ui.labeled.input > input {
          width: 1.5rem;
          height: 1.75rem;
        }
        .ui.input > input {
          font-size: 12px;
        }
        .tier-labels {
          display: inline-block;
          line-height: 16px;
          top: 5px;
          position: relative;
          .bottom-label-main {
            display: block;
            font-size: 13px;
            text-align: left;
            line-height: 7px;
          }
          .bottom-label-prefix {
            font-size: 12px;
          }
          &.tier-3 {
            min-width: 5.2rem;
            > label {
              margin-left: 0.5rem;
            }
          }
        }

        .field.fix-input-container.tier.amount .ui.input.fix-input-wrapper {
          width: 4.75em !important;
          input {
            padding-left: 0.3em;
            padding-right: 0.1em;
          }
        }
        .field.fix-input-container.tier.amount.major-currency
          .ui.input.fix-input-wrapper {
          width: 5.25em !important;
        }

        .field.fix-input-container.tier.percentage .ui.input.fix-input-wrapper {
          width: 4.5em !important;

          input {
            padding-left: 0.75em;
            padding-right: 0.1em;
          }
        }
      }
    }
  }
}

div.modal.new-sch-item {
  div.schedule-step {
    > div.field {
      margin-bottom: 0.25em;
      > label {
        font-size: 14.8px; //Cinnamon overrides some of our labels
        min-width: 9em;
        margin: 0 !important;
      }
      &.fix-unit-dropdown {
        > label {
          display: flex;
          align-self: center;
        }
      }
    }
  }
}

.product-schedule-restrict {
  .foreground {
    position: absolute;
    z-index: 20;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 2em);
    width: fit-content;
    box-shadow: 5px 5px 22px $border-shadow-color;
  }
}

.readonly-fix-unit-dropdown {
  opacity: 1 !important;
  background-color: white;
  cursor: not-allowed;
  width: 3em !important;
  text-align: center;
  justify-content: center;
  border: 1px solid $color-purple;
  border-radius: 5px;
}

.large-format-list-unit-container {
  width: 7em;
  padding-left: 2em;
}

.schedule-item-delete-confirm-button {
  display: flex !important;
  padding: 0.7em !important;
}

.major-currency .currency-label {
  margin-right: -1px !important;
  padding-left: 0.3em;
  padding-right: 0em;
  padding-top: 0.4em !important;
  padding-bottom: 0.2em !important;
  border: #a15ea1 1px solid !important;
  border-right: 0 !important;
}

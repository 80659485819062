// place any app-specific variables here or override Cinnamon/Semantic defaults

// COLOURS

$black: #393939;
$white: #ffffff;

$color-text-link: #3553a0;
$border-shadow-color: #00001a;

$background-color: #f4f4f9;
$background-blue-color: #e2f2fc;
$background-red-color: rgb(244, 233, 238);
$background-grey: #f6f9fc;
$background-cream-color: rgb(255, 248, 229);

$background-blue: rgba(235, 236, 254, 1);
$background-yellow: rgba(244, 237, 202, 1);
$background-green: rgba(227, 234, 223, 1);

$sheet-funder-background: rgba(255, 248, 229, 1); // yellow
$sheet-supplier-background: rgba(227, 234, 223, 1); // green
$sheet-supplier-background-dark: rgb(209, 216, 205);
$sheet-co-funded-background: rgba(250, 229, 229, 1); // red
$sheet-co-funded-background-dark: rgb(224, 203, 203);

$sheet-ipsAdmin-background: rgb(226, 226, 247); // violet
$sheet-account-manager-background: rgb(232, 232, 243); // grey

$paper-inclusive-dark-red: rgb(208, 49, 41);

$color-completed-green: rgb(122, 173, 71);

$color-admin-green: rgb(0, 158, 15);
$color-hover-admin-green: rgb(33, 109, 63);
$color-ips-buttons: rgb(43, 120, 228);
$color-hover-ips-button: darken($color-ips-buttons, 25%);

$color-totaliser-background: rgb(168, 168, 255);

//greys
$color-grey: #979797;
$color-light-grey: #f1f2f3;
$color-light-grey-accent: #eae9ec;
$color-dark-grey: #3f3f3f;
$color-translucent-dark-grey: #22242626;
$table-alternate-row-grey: #f9f9fc;

//blues
$color-blue: #004a96;
$color-dull-blue: #5d79a0;
$color-blue-grey: #8190a4;
$color-light-blue: #3caae6;
$color-light-blue-hover: #8fcef2;
$color-light-blue-alt: #9fc5f8;
$color-lighter-blue: #ecf7fd;
$color-blue-highlight: #85b7d9;

//turqoises
$color-standard-turquoise: #00a0a0;
$color-standard-turquoise-light: #c3fafa;
$color-standard-turquoise-dark: #30618c;
$color-translucent-standard-turquoise: #40e0d021;
$color-white-turquoise: #d9f1f1;

//Product Schedule Meter Modal
$background-meter-modal-green: rgb(182, 215, 168);
$background-tiered-toner-green: rgb(182, 215, 168);
$system-schedule-item: rgb(159, 197, 248);
$large-format-item: rgb(162, 196, 201);

//Customer View
$sheet-customer-view-model: rgb(53, 28, 117);

$color-old-ip-purple: rgb(124, 66, 147);
$color-purple: #6b036b;
$color-alternate-purple: rgb(193, 94, 244);
$color-light-purple: #5b2779;
$color-light-purple-hover: #d6b7e8;
$color-light-purple-alt: #ccc6dc;
$colour-lighter-purple: #a8a8ff;
$color-pink: #a15ea1;
$color-light-pink: #d5a6bd;
$color-dark-purple: #360236;
$color-bg-light-purple: #e2ccee;
$color-bg-lighter-purple: #e9dcf0;
$color-bg-beige-purple: rgb(236, 233, 245);

$color-bg-dark-beige-purple: rgb(180, 167, 214);
$color-bg-light-yellow: #ffe599;
$color-white: #ffffff;
$colour-light-blue: #3caae6;

$color-dark-lilac: rgb(104, 83, 145);

$co-terminus-green: rgb(143, 188, 116);
$co-terminus-blue: rgb(69, 119, 168);

$color-grey-0: #dedede;
$color-grey-1: #cccccc;
$color-grey-2: #979797;
$color-grey-3: #707070;
$color-grey-4: #4e4e4e;
$color-grey-5: #404040;
$color-grey-6: #393939;
$light-grey: #f1f2f3;
$colour-translucent-dark-grey: #22242626;

$color-standard-blue: #546772;
$color-blue: #004a96;
$color-light-blue: #3caae6;
$color-lighter-blue: #ecf7fd;
$color-light-green: #4fb453;
$color-green: #3eab42;
$color-dark-green: #08362c;
$color-light-red: #e54141;
$color-highlight-red: #e5414136;
$color-red: #ff0000;
$color-dark-red: #af0808;
$color-error-red: #7d0000;
$color-orange: #ff7700;
$color-yellow: #ffbb00;

// Sheet section colours
$color-rates: #c5e0b3;
$color-capital: #ffd866;
$color-services: #c35b10;
$color-paper-inclusive: #cd98ff;
$color-system-schedule: #2e75b3;
$color-opal-info: #818181;
$color-schedule-stability: #fe0000;

//Capital Calc info modal
$colour-ccinfo-yellow-grey-light: #fff8e5;
$colour-ccinfo-yellow-grey-dark: #fff3d2;
$colour-ccinfo-blue-grey-light: #ece9f5;
$colour-ccinfo-blue-grey-dark: #ded9ee;
$colour-ccinfo-blue-bright: #2b78e4;
$colour-ccinfo-grey-light: #87858f;
$colour-ccinfo-grey-dark: #333333;
$colour-ccinfo-pink-bright: #9900ff;
$colour-ccinfo-green-bright: #009e2a;

$colour-ccinfo-funder-highlighted-row: rgb(255, 229, 153);
$colour-ccinfo-dealer-highlighted-row: rgb(182, 215, 168);
$colour-ccinfo-cofunded-highlighted-row: rgb(213, 166, 189);

$colour-gold: rgb(210, 176, 4);

$color-teal: #00a0a0;

$input-border-radius: 4px;
$header-height: 56px;

// BREAKPOINTS
// see: mixins/breakpoints.scss for usage

// mobile 0 - 500px
$sm-tablet-min-width: 501px; // sm tablet - 501px - 799px. Purposefully 501px so it can be tested on Desktop Chrome
$tablet-min-width: 800px; // tablet 800px - 1064px
$laptop-min-width: 1065px; // laptop 1065px - 1399px
$desktop-min-width: 1400px; // desktop 1400px - inf

// CINNAMON OVERRIDES
//http://cinnamon-docs.river.red/0.19.0/styling/overview/theme-variables

$text-color: $color-grey-5;
$import-google-fonts: true;
$font-url: "https://fonts.googleapis.com/css?family=Barlow:400,500,600,700|Source+Sans+Pro:400,500,700&subset=latin,latin-ext&display=fallback";
$font-name: "Source Sans Pro";
$font-size: "16px";

$primary-color: $color-purple;
$secondary-color: $color-teal;
$positive-color: $color-light-green;
$negative-color: $color-light-red;

$scrollbar-color: #6b036b;

$unread-notification-color: #e8e0ff;

.rates-page {
  display: block;
  @include customBreakpoint("min-width: 1200px") {
    display: flex;    
    & .right-column {
      float: right;
      width: 49%;
      margin-top: 0 !important;
    }
    & .left-column {
      float: left;
      width: 49%;
    } 
  }

  justify-content: space-between;
  .rates-section {
    overflow-x: auto;
    border-top: 5px solid $color-light-purple;
    border-radius: 5px;
    box-shadow: 5px 5px 22px #0000001a;

    background-color: white;
    padding: 0em 1em;
    & h4 {
      margin-bottom: 0.25em;
      margin-top: 0.25em;
      padding-left: 0.5em;
    }
  }
  & .dealer-liquidity-section {
    height: fit-content;
    overflow-y: hidden;    
  }
  .right-column {
    margin-top: 1em;
    > div > .ui.text.menu {
      margin-top: -0.3em;
    }

    & .rates-table > h4 {
      padding-left: 1em;
    }
    > div {
      width: 100%;
    }

    > div:first-child > .ui.text.menu {
      float: right;
      > .item {
        color: $color-light-blue;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 0.75em;
        padding-left: 0.25em;
        padding-right: 0.25em;
        margin-left: 1em;
        &.active {
          border-bottom: 3px solid $color-light-blue;
          padding-bottom: 0.5em;
        }
      }
    }
  }

  .left-column {
    > .rates-section:first-child {
      margin-bottom: 2em;
    }
    > div:first-child {
      padding-bottom: 1rem;
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include desktop {
      & h4 {
        margin-bottom: 0;
      }
    }
    & .ui.button {
      padding: 0.3rem;
      max-height: 26.5px;

      &.save-button {
        background-color: $color-light-green;
        & img {
          padding-right: 0;
          padding-left: 0.4rem;
        }
      }
    }

    & .ui.button.cancel {
      background-color: white;
      padding: 0 0.5rem;
      margin: 0.5rem;
    }

    .subtitle {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 15em;
      margin-top: 0.5rem;
      overflow: hidden;
   }
  }
}

.ui.form .grid.dealer-liquidity-charge-table {
  margin-top: 0;
  padding-bottom: 1rem;
  min-width: 40rem;
  div.ui.input > input {
    padding-left: 0.5rem !important;
    padding-right: 0.2rem !important;
  }
  > .column > .row:first-child {
    padding-top: 0;
    height: 1.8rem !important;
  }

  .four.wide.column .row.ip-syndicate, .two.wide.column .row.ip-syndicate {
    border-top-style: solid;
    border-color: $color-light-blue;
    border-width: 3px;
    > p {
      color: $color-orange;
      font-weight: bold;
    }
  }

  & .four.wide.column,
  .two.wide.column {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  & .four.wide.column {
    > .row {
      height: 2.5rem;
      display: flex;
      align-items: center;

      &.total > p {
        font-weight: bold;
      }
   
      &:first-child {
        height: 2.5rem;
      }
      > p {
        padding-left: 1.5em;
      }      
    }
  }
  & .two.wide.column {
    > .row {
      height: 2.5rem;
      align-items: center;
      display: flex;
      > .cin.number-formatter {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
      > .field > .ui.input {
        width: 90% !important;

        > input {
          border: 0.5px solid $color-pink;
          padding-right: 1em;
          width: calc(100% - 40px);
        }

        & .ui.label.label {
          border: 0.5px solid $color-pink;
          border-left-width: 0;
          padding-left: 0 !important;
          padding-right: 0.5rem;
          background-color: white;
          color: black;
        }
      }
      &:last-child > span {
        font-weight: bold;
      }
      &:first-child {
        > p {
          padding-top: 0;
          font-weight: bold;
        }
      }
    }
  }

  & .row:first-child {
    border-bottom: 3px solid $background-color;
    background-color: white !important;
  }
  & .row:nth-child(odd) {
    background-color: $background-color;
  }
}

.liquidity-charge-table-container {
  width: 100%;
  overflow-x:auto;
}

.rates-table-container {
  width: calc(100% + 2em) !important;
  min-width: 35em;
  overflow-x:auto;
  margin-left: -1em;
}

.ui.grid.rates-table-header {
  width: 100%;
  padding-top: 0.5em !important;
  margin-left: 0rem !important;
  > .row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  > .eight.column.row > .column > p {
    font-weight: bold;
  }
  > .eight.column.row > .column:first-child > p {
    padding-left: 1.25em;
  }
  & .row:first-child {
    border-bottom: 3px solid $background-color;
    background-color: white !important;
  }
}
.ui.grid.rates-table {
  width: 100%;
  height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 1rem !important;
  margin-left: 0rem !important;
  > .row {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    &:hover {
      background-color: $color-lighter-blue !important;
    }
  }
  .row:nth-child(even) {
    background-color: $background-color;
  }
  > .row > .column:first-child > p {
    padding-left: 1.25em;
  }
}

.rates-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: $background-color;
}
.rates-table::-webkit-scrollbar {
  width: 6px;
  background-color: $background-color;
}
.rates-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-light-purple;
}
.rates-table::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 10px;
  background-color: $color-light-purple;
}

.rates-filters {
  padding-left: 2em !important;
  & h4 {
    padding-left: 0 !important;
  }
  & form > .fields {
    padding-left: 0;
    margin: 0 0 1em !important;
    flex-wrap: wrap;
    > .field {
      padding-left: 0 !important;
      > .radio.checkbox {
        margin-bottom: 0 !important;
        margin-top: 0.25em !important;
      }
      > .radio.checkbox > label::after {
        background-color: $color-pink;
      }
      > .radio.checkbox > label::before {
        border-color: $color-pink;
      }
    }
    > .field:first-child {
      min-width: 9em;
      margin-right: 0.5rem;
    }
    > .field:nth-child(2) {
      min-width: 7em;
      margin-right: 0.5rem;
    }
  }
}


.rates-action-bar {
  .cin.action-bar {
    margin-bottom: 0.25rem !important;
    & .desktop-tab-headers-container {
      display: flex;
    }
  }
  .desktop-tab-headers {
    display: flex;
    align-items: center;
    @include breakpoint-sm {
      display: none;
    }    
  }
  
  a {   

    &:hover {
      color: $color-light-blue;
  }
}
}

.rates-actions {
  display: inline-block;
  button.ui.button {
    display: flex;
    margin-left: 2rem !important;
    padding: 0.3rem !important;
    background: $color-light-blue none;
    color: $white;
    font-size: 16px;
  }
}

.mobile-tablet-tab-headers {  
  @include breakpoint-not-sm {
    display: none;
  }
  padding-top: 0.5rem;
  float: right;
  .tab-headers {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.tab-container {    
  margin-left: 1em;
  display: inline-block;
  &.active {
    border-bottom-style: solid;
    border-bottom-color: $color-light-blue;
    border-bottom-width: 3px;
  }
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h4.rates-header {
  display: flex;
  align-items: center;
  .header-dropdown {
    font-size: 16px;
    margin-left: 0.5rem;
    div.ui.selection.dropdown {
      min-width: 25rem !important;
      max-width: 50rem !important;
    }
    input.search {
      padding-left: 0.25em !important;
    }
  }
}

.edit-rate-radio-group .field {
  & label {
  display: block !important;
  }
  > .ui.radio.checkbox {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
    > label {
      padding-left: 1.5rem;
    }
  }
}
.ui.disabled.checkbox.sheet-radio-group {
  & label {
    opacity: initial;
  }
}
.yields-page {
  .yields-matrix {
    margin-top: 1rem;
    .funder-section {
      padding: 0 0.5rem 0.5rem 0.5rem;
      margin-bottom: 0.5rem;
      border-style: solid;
      border-width: 0;
      border-left-width: 10px;

      h3 {
        margin-bottom: 0.25rem;
      }

      &:nth-of-type(1) {
        border-color: $color-grey-2;
      }
      &:nth-of-type(2) {
        border-color: $color-bg-light-purple;
      }
      &:nth-of-type(3) {
        border-color: rgb(232, 209, 209);
      }

      .funder-section-header {
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          margin-left: 0.5rem;
          margin-bottom: 0.25rem;
        }
      }

      .finance-type-section {
        border-width: 2px;
        border-style: solid;        
        padding: 0 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        border-color: #18276d !important;
        &:nth-of-type(1) {          
          background-color: #eaddba !important;
        }
        &:nth-of-type(2) {
          background-color: #e3c1c1   !important;
        }

        h4 {
          margin-bottom: 0.5rem;
        }

        .payment-method-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 100%;
          .payment-method-section {
            min-width: 36rem;
            max-width: 40rem;

            @include breakpoint-sm {
              width: 100%;
              min-width: unset;
              max-width: unset;
            }
            &:nth-child(1) {
              margin-right: 1rem;
            }
            &:nth-child(1) .rates-section,
            &:nth-child(1) .rates-section .rates-table-header > .row
            {
              background-color: rgb(230, 238, 250) !important;
            }
            &:nth-child(2) .rates-section,
            &:nth-child(2) .rates-section .rates-table-header > .row
             {
              background-color: rgb(228, 240, 229) !important;
            }
            .rates-section {
              margin-bottom: 0.5rem;
              .defaults-page-title { 
                h4 {
                  width: 18em;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  @include breakpoint-sm {
                    width: 14em; 
                  }
                }                
              }

              .yield-rate-form {    
                margin-top: 1rem;
                margin-bottom: 1.25rem;
                .field-row {
                  display: flex;
                  justify-content: space-around;
                }
                p.month, p.quarter {
                  width: 3em;
                  margin-bottom: 0;
                }
                p.month {
                  margin-left: 2rem;
                }
            
                .field {    
                    margin-bottom: 0;
                    .ui.input {
                      input {
                        padding: 0.05em 0.15em !important;
                        margin-top: 0.1em;
                        margin-bottom: 0.1em;
                      }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
}
.ips-rates-step {
  .override-radio-group,
  .funder-type-radio-group {
    display: inline-block !important;
    align-content: center;
    text-align: center;
    min-width: 16em;

    .field {
      display: block !important;
    }
  }
  .override-radio-title > p,
  .funder-type-radio-title > p {
    font-size: 13px;
  }

  .field {
    white-space: nowrap;
    display: flex;
    width: auto;
  }
  .field > label {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .field > .ui.radio.checkbox:nth-child(2) {
    margin-left: 0;
    margin-right: 5px;
  }

  .green {
    background-color: rgba(10, 100, 10, 0.2);
  }

  .ips-rates-radio-group {
    border: 1px solid $color-purple;
    border-radius: 10px;
    max-width: 73.25em;
    padding: 0.5rem 0.25rem 0.25rem 0.25rem !important;
  }

  .funder-type-rate-section {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 0.5rem;
    clear: both;
    width: 100%;

    .funder-type-rate-settings {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      padding-bottom: 0.25em;

      .funder-type-rate-details {
        margin-top: 0.5em;
        padding-bottom: 0.5em;
        width: $funder-section-width;

        & h5 {
          font-weight: bold;
          text-indent: 10px;
        }

        .funder-type-radio-group {
          min-width: 10em !important;
          padding-left: 1em;
        }
      }
    }

    .funder {
      background-color: $sheet-funder-background;
      border-radius: 5px;
    }
    .supplier {
      background-color: $sheet-supplier-background;
      border-radius: 5px;
    }
    .co-funded {
      background-color: $sheet-co-funded-background;
      border-radius: 5px;
    }
  }

  .override-section {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;
    padding: 0.5rem;

    .funder-specific-overrides {
      .funder-specific-radio {
        border-bottom: 1px solid black;
        padding-bottom: 5px;
        h5 {
          font-weight: bold;
          padding-left: 5px;
        }
      }
      .override-radio-group {
        min-width: 11em !important;
      }
    }
    .funder-specific-overrides:first-child {
      .funder-specific-radio {
        padding-left: 5px;
        border-left: 1px solid black;
        padding-right: 1em;
      }
    }
    .funder-specific-overrides:last-child {
      .funder-specific-radio {
        border-right: 1px solid black;
        padding-left: 1em;
        padding-right: 5px;
      }
    }

    .column:nth-of-type(3) {
      @include customBreakpoint("max-width: 1568px") {
        margin-top: 0.5rem;
      }
    }

    .column:nth-of-type(2) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;

      @include customBreakpoint("max-width: 1055px") {
        margin: 0.5rem 0.5rem 0rem 0rem;
      }
    }

    .funder {
      background-color: $sheet-funder-background;
      border-radius: 5px;
    }
    .supplier {
      background-color: $sheet-supplier-background;
      border-radius: 5px;
    }
    .co-funded {
      background-color: $sheet-co-funded-background;
      border-radius: 5px;
    }

    .override-details {
      margin-top: 0.5em;
      width: $funder-section-width;

      & h5 {
        font-weight: bold;
        text-indent: 10px;
      }
      .override-input {
        width: auto;
        text-align: center;
        > .ui.labeled.input.fix-input-wrapper {
          width: 4em !important;
          > input {
            width: 4em !important;
          }
        }
      }
      div.override-field-details {
        text-align: left;
        width: 60%;
        margin-bottom: 0.25rem;
        font-size: 13px;
        height: 25px;
        padding-left: 1em;
        &.output-group {
          width: 100%;
          font-weight: bold;
          > * {
            display: inline-block;
            font-size: 16px;
            width: 40%;
          }
          > label {
            width: 60%;
          }
          > span.number-formatter {
            //width: 40%;
            padding-left: 1.5em;
            text-align: left;
          }
          > div.inline-loader {
            transform: translate(
              -2.5em,
              0.25em
            ); //tested and looks good on all viewports
          }
        }
        div p {
          font-weight: normal;
        }
        label {
          font-weight: normal;
        }
      }
    }
    .h5 {
      font-weight: bold;
      margin-bottom: 10px !important;
    }
  }
}

.content-container.sheet-step.services-step {
  border-top-color: $color-services
}

.services-header {
  display: block;
  justify-content: flex-start;
  align-items: center;
}
.services-adders {
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  :first-child {
    margin-left: 0px !important;
  }
}

.services-top-section {
  margin-bottom: 1em;
}

.services-container,
.services-top-section {
    width: 72rem !important;
}

.services-container {
  max-width: 100%;
  overflow: auto;

  &.overflow-disable {
    overflow: unset !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $scrollbar-color;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  .services-body {
    margin-bottom: 0em !important;

    &.services-bottom {
      padding-bottom: 10px;
    }

    > .services-edit-list {
      width: 100%;
      overflow-x: auto;

      &.overflow-disable {
        overflow: unset !important;
      }

      min-width: 72rem;

      .field.array-error {
        margin-bottom: 0 !important;
      }

      > .service-field-group {
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
        line-height: 1.6em;

        .fix-input-container,
        .fix-service-container {
          margin-right: 0.5em;
        }

        .service-sku,
        .service-billing-code {
          display: flex;
          .label {
            margin-right: 2em;
            white-space: nowrap;
          }
          .value {
            width: 15em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .green-text {
          label {
            color: $color-admin-green !important;
          }
        }
        .ips-color-text {
          label {
            color: $color-ips-buttons !important;
          }
        }
        .small-margin {
          label {
            margin-right: 0.3em !important;
          }
        }
        .service-sku {
          max-width: 16em;
        }
        .service-billing-code {
          max-width: 16em;
        }
        .service-value {
          max-width: 12em;
          margin-right: 2em !important;
        }

        &.mandatory-service > .field > label,
        .fix-input-container > label,
        .fix-service-container > label {
          margin-right: 0.25em !important;
          font-size: 15px;
        }

        .ui.icon.button.icon-only-button {
          padding: 0 !important;
          margin-left: 0.5rem;
        }

        .ui.fix-input-wrapper.left-label--currency input {
          max-width: 7rem;
        }

        @include breakpoint-sm {
          flex-wrap: wrap;
          // style service dropdown to hide label and take full width
          > .field {
            &.fix-input-container
              .ui.input.fix-input-wrapper.left-label--currency
              input[type="text"] {
              max-width: 7.5rem !important;
            }
            & .ui.input.fix-input-wrapper.left-label--currency {
              max-width: 100% !important;
            }
            &:first-child {
              width: 100%;
              display: block;
            }
          }
        }
        &.mandatory-service {
          & .disabled:not(.fix-input-container) > .ui.disabled.input {
            width: 16rem !important;
            margin-right: -0.25rem;
            cursor: not-allowed;

            @include breakpoint-sm {
              width: 98% !important;
            }
          }

          & .disabled,
          & .disabled > .ui.input,
          & .disabled > label {
            opacity: 1 !important;
            pointer-events: initial;
          }
        }
      }

      & .inline.field {
        display: flex;
        align-items: start;
        margin-bottom: auto;
        margin-right: 0.75rem;
        > label {
          white-space: nowrap;
          display: flex;
          align-self: center;
          justify-content: right;
        }

        > .ui.dropdown {
          width: 16rem !important;

          @include breakpoint-sm {
            width: 95% !important;
          }
        }

        input {
          min-width: 5.25rem;
        }

        @include breakpoint-sm {
          margin-bottom: 0.5rem;
        }
      }

      button,
      button:hover,
      button:active,
      button:focus {
        background-color: transparent !important;
        margin-left: -0.5rem;
        padding-bottom: 0.75em !important;
      }

      .service-field-group:nth-last-of-type(2) {
        margin-bottom: 0.2rem !important; // Fixes bug where Y scroll is always visible.
      }
    }
  }  
}
form.service-form {
  div.group {
    > div.field {
      margin-right: auto !important;
    }
  }
}
div.modal {
  &.new-qrt-srv {
    div.form {
      div {
        overflow: inherit;
        &.services-container {
          overflow: visible;
        }
      }
    }
  }
}

.add-quarterly-service{
  max-width: 100%;
}


//////////////////////////////////////////////////////////////////////////////////////
// README - How to use these breakpoints in styling and React Components
//
// These breakpoints are replicated in Cinnamon using screenSize. See CapitalStep
// for a usage example.
// Cinnamon ScreenSize:                         SCSS size:
//  * small                                      * mobile, sm-tablet
//  * medium                                     * tablet
//  * large                                      * laptop, desktop
//
// Mixin usage example:
// 
// .some-class {
//    width: 50%;
//    @include breakpoint-sm {
//        width: 100%;
//    }
// }
// 
// If you want to:                              Then use:
// * Limit a style to only affect mobile..........@include mobile { ... }
// * Limit a style to affect < 800px..............@include breakpoint-sm { ... }
// * Limit a style to be desktop-only.............Don't use a breakpoint, override it
//                                                using breakpoint-not-desktop instead
//////////////////////////////////////////////////////////////////////////////////////

/* Param implementation */

/// Use this mixin to target mobile viewports, < 500px
@mixin mobile {
  @media only screen and (max-width: #{$sm-tablet-min-width - 1}) {
      @content;
  }
}

/// Use this mixin to target mobile viewports, < 500px
@mixin sm-tablet {
  @media only screen and (min-width: #{$sm-tablet-min-width}) and (max-width: #{$tablet-min-width - 1}) {
    @content;
  }
}

/// Use this mixin to target tablet viewports, >= 800px && < 1065px
@mixin tablet {
  @media only screen and (min-width: #{$tablet-min-width}) and (max-width: #{$laptop-min-width - 1}) {
      @content;
  }
}

/// Use this mixin to target laptop viewports, >= 1065px && < 1400px
@mixin laptop {
  @media only screen and (min-width: #{$laptop-min-width}) and (max-width: #{$desktop-min-width - 1}) {
      @content;
  }
}

/// Use this mixin to target desktop viewports, > 1400px
@mixin desktop {
  @media only screen and (min-width: #{$desktop-min-width}) {
      @content;
  }
}

/* Broad implementation */

// Applies child rules when viewport < 800px
/// Will affect...?
/// [x] mobile
/// [x] sm-tablet
/// [ ] tablet
/// [ ] laptop
/// [ ] desktop
@mixin breakpoint-sm {
  @media only screen and (max-width: #{$tablet-min-width - 1}) {
    @content;
  }
}

// Applies child rules when viewport < 1065px
/// Will affect...?
/// [x] mobile
/// [x] sm-tablet
/// [x] tablet
/// [ ] laptop
/// [ ] desktop
@mixin breakpoint-md {
  @media only screen and (max-width: #{$laptop-min-width - 1}) {
    @content;
  }
}


// Applies child rules when viewport < 1400px
/// Will affect...?
/// [x] mobile
/// [x] sm-tablet
/// [x] tablet
/// [x] laptop
/// [ ] desktop
@mixin breakpoint-not-desktop {
  @media only screen and (max-width: #{$desktop-min-width - 1}) {
      @content;
  }
}

// Applies child rules when viewport >= 500px
/// Will affect...?
/// [ ] mobile
/// [x] sm-tablet
/// [x] tablet
/// [x] laptop
/// [x] desktop
@mixin breakpoint-not-mobile {
  @media only screen and (min-width: #{$sm-tablet-min-width}) {
    @content;
  }
}

// Applies child rules when viewport >= 800px
/// Will affect...?
/// [ ] mobile
/// [ ] sm-tablet
/// [x] tablet
/// [x] laptop
/// [x] desktop
@mixin breakpoint-not-sm {
  @media only screen and (min-width: #{$tablet-min-width}) {
      @content;
  }
}

// Applies child rules when viewport >= 1065px
/// Will affect...?
/// [ ] mobile
/// [ ] sm-tablet
/// [ ] tablet
/// [x] laptop
/// [x] desktop
@mixin breakpoint-not-md {
  @media only screen and (min-width: #{$laptop-min-width}) {
    @content;
  }
}

/* Extras */

/// Use this mixin for a custom media query
@mixin customBreakpoint($param) {
  @media only screen and ($param) {
    @content;
  }
}
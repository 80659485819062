.notification-page {
  max-width: 90em !important;

  .cin.action-bar .ui.form {
    div.recipient-dd-container {
      .field {
        align-items: center;
        display: flex;
        flex-direction: row;
        div.selection.dropdown.recipient {
          width: 15rem !important;
          min-height: 1.5em;
        }
      }
    }
  }

  .unread {
    background-color: $unread-notification-color;
  }

  div.raw-msg {
    font-family: monospace;
    border-style: solid;
    padding: 1em;
    margin-top: 1em;
  }
}

.ui.modal.notification-modal {
  div.raw-msg {
    font-family: monospace;
    border-style: solid;
    padding: 1em;
    margin-top: 1em;
  }
}

.main-menu .notification-count {
  color: $color-red;
  padding-right: 10px;
}

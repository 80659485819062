.large-format-add-button {
  width: 14em;
  display: flex !important;
  background: white !important;
  > span {
    padding-left: 10px;
  }
}

.lf-list-columns {
  .min-width-7 {
    min-width: 7em;
  }
  .min-width-14 {
    min-width: 14em;
  }
}

.large-format-modal-list {
  .large-format-list-item {
    display: flex;
    min-height: 2em;
    font-weight: bold;

    .button {
      width: 1em;
      height: 1.5em;
    }

    .lf-col {
      padding-right: 5px;

      > input {
        width: 100% !important;
      }
    }
    .lf-col-0 {
      min-width: 1.5em !important;
    }
    .lf-col-1 {
      min-width: 8.25em !important;
      .dropdown {
        max-width: 8em !important;
      }
    }
    .lf-col-2 {
      max-width: 8em !important;
      width: 100% !important;
    }
    .lf-col-3 {
      max-width: 9em;
      width: 100% !important;
    }
    .lf-col-4 {
      max-width: 8em !important;
      width: 100% !important;
    }
    .lf-col-large {
      min-width: 14.25em !important;
      .dropdown {
        max-width: 14em !important;
      }
    }
  }
}

.large-format-printer-modal {
  .form-row {
    display: flex;
    .extra-padding {
      padding-right: 20px;
    }

    .printer-dropdown {
      width: 14.25em;
      .dropdown {
        max-width: 14em !important;
      }
    }

    .input-small {
      max-width: 9.5em !important;
      padding-right: 1em;
    }

    .radio-label {
      font-weight: bold;
      text-align: center;
      margin: 0em;
    }

    .radio-group {
      text-align: center;
      justify-content: center;
      padding-right: 5px;
    }
  }
}

.floating-scroll-box {
  position: relative;
  .inner-scroll {
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $scrollbar-color;
    }
    &::-webkit-scrollbar-track {
      background-color: $color-grey-1;
    }

    @include breakpoint-not-sm {
      position: absolute;
      width: 100%;
      padding-right: 0.25em;
      max-height: calc(100vh - #{$header-height} - 0.5em);
      overflow-y: auto;
      transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.67, 0.94),
        top 0.7s cubic-bezier(0.4, 0.2, 0.67, 0.94);
    }
    @include breakpoint-sm {
      transform: translateY(0) !important;
    }
  }
}

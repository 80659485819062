.agreement-filter {
    background-color: $color-bg-light-purple;
    margin: -0.5em -1em -1em -1em;
    padding: 0.25em;

    display: flex;
    justify-content: center;

    .agreement-field {
        display: flex;                
        .value {
            font-weight: bold;
            margin-left: 0.25rem;
            margin-right: 1.25rem;
        }
    }
}

.switch-button {
    font-size: 0.75rem;
    color: $color-purple;
    font-weight: bold;
    cursor: pointer;
}
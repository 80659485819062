.defaults-page-title {    
    justify-content: space-between;
    h4.title {
        margin-right: 1rem;
        margin-bottom: 0;
    }

    .ui.icon.primary.button {
        min-width: 4.5rem;
    }

    .title-bar {
        align-items: center;
        flex-wrap: wrap;        
    }

    @include breakpoint-sm {
        margin-bottom: 0.5rem;        
    }
}

.content-container {
    padding: 0.5rem;

    .ui.grid {
        margin: 0rem !important;
        padding: 0 !important;
    }

    .layout-grid {
        flex-wrap: wrap;

        .section-2 {
            max-width: 21rem;
        }

        > .column {
            padding: 0 !important;
        }

        h5 {
            margin-bottom: 0.25rem;
            margin-top: 0;
            font-weight: 500;
        }

        .field {
            .ui.selection.dropdown {
                .text {
                    min-height: 1.1em !important;
                }
            }
        }

        .ui.grid, .ui.grid > * {
            margin: 0 !important;
            padding: 0 !important;
        }

        .ui.grid > .row {
            margin-top: 0.25rem !important;
            > .column:first-child {
                padding-left: 0 !important;
            }
        }

        .field {
            margin-bottom: 0.5rem;
        }

        .section.paper-weights {
            > div:last-child { 
                margin-left: 2rem !important;                
            }
        }
            
        .min-paper-costs {
            max-width: 28rem;            
        }

        .section {
            margin-bottom: 1rem;
        }

        .paper-cost-header > .column {
            font-weight: bold;
        }
    }
}
$agreement-progress-circle-diameter: 1.5em;

.agreements-page-container .navigation-bar {
  margin-bottom: 1rem;
  height: 1.5em;
  background-color: $color-bg-beige-purple;
  .back {
    display: flex;
    * {
      color: $text-color;
    }
    a {
      font-weight: bold;
    }
  }
}

.cin.page-content.agreements {
  > .column {
    margin-top: 0 !important;
  }

  h4.title {
    text-align: center;
    font-size: 32px;
  }
}

.content-container.agreements-list {
  .ui.styled.accordion,
  .ui.fluid.accordion,
  .accordion {
    border-style: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    .accordion-title-flex {
      display: flex;
      .accordion-button-flex {
        display: flex;
      }
    }
    .accordion-create-button {
      background-color: transparent;
      margin-left: 0.5em;
      margin-top: 0.25em;
      display: flex;
      padding: 0 0 0 0;
      color: $color-light-blue;
      &:hover {
        color: darken($color-light-blue, 10%);
      }
      img {
        margin-right: 0.2em;
      }
    }
  }

  .ui.accordion,
  .accordion {
    margin: 0 !important;
  }

  .ui.styled.accordion .title {
    border-bottom: 1px solid $light-grey !important;
    padding: 0.25em !important;
  }

  .rewrite .ui.image {
    margin-left: auto;
    margin-right: auto;
  }
  .agreement-accordion-title {
    display: inline;
  }
  .agreement-accordion-title .not-bold {
    font-weight: normal;
  }
  .add-master-agreement img {
    padding-right: 0.5em !important;
  }
  div.accordion.agreements-list {
    div.accordion.agreement-schedule {
      > div.title {
        > * {
          vertical-align: middle !important;
        }
        > span {
          display: inline-block;
        }
      }
      .menu {
        left: auto !important;
      }

      div.ui.loader.inline-loader {
        margin-left: calc(100% - 50em);
      }

      .grid {
        display: grid;
        margin: auto;
        grid-template-columns: auto;
      }
      .grid-col {
        display: grid;
        margin-left: 5.85em;
        grid-template-columns: auto auto;
      }
    }
  }

  button.icon.clear-bg {
    display: inline-flex;
    padding: 0.25em;
    margin: 0 0 0 1em;
    font-weight: 700;
    color: $color-light-blue;
    background-color: $color-white;
    border: solid 1px transparent;
    transition:
      box-shadow 0.1s ease,
      border-color 0.1s ease;
    &:hover {
      color: darken($color-light-blue, 10%);
    }
    > :not(:first-child) {
      margin: 0 0 0 0.25em;
    }
  }

  .agreement-filter-button {
    & .ui.icon.primary.button {
      display: flex;
      & img {
        padding-right: 0.5em;
      }
    }
  }

  .ui.dropdown.sheet-actions .menu.visible {
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 0px 15px black;

    .ui.dropdown {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0.35em 1em 0.35em 0.25em;
      &:hover,
      &.active {
        background-color: $color-light-purple-hover;
      }
    }
  }
}

div.ui.form.create-sheet {
  .ui.input.schedule-number > input {
    font-weight: bold;
  }
}
div.ui.modal {
  &.custom-actions.customer-approval {
    div.custom-action-footer {
      margin-top: 1em;
      div.action-bar {
        margin: 0;
      }
      button {
        margin: 0.5em;
        padding: 0.8em 1.5em 0.8em;
        &.primary {
          display: inline-block;
        }
      }
    }
    .client-auth-sign-dropdown {
      width: 40em !important;
      max-width: 100% !important;
    }

    .user-list {
      display: flex;
      .field {
        width: calc(50% - 2rem);
        .ui.input {
          width: 100% !important;
          input {
            margin-right: 0.5em;
          }
        }
      }
      .ui.icon.button.icon-only-button {
        margin-top: 1.25em !important;
      }
    }

    .cin.page-loader .loader::before {
      border-color: $color-grey-2;
    }

    table.recipients {
      border-collapse: collapse;
      thead tr th,
      tbody tr td {
        padding: 0.25em 0.75em;
      }
      tbody tr:nth-child(even) {
        background-color: $table-alternate-row-grey;
      }
    }
  }
}

.content-container.agreements-list-header {
  background-color: $color-bg-beige-purple;
  padding: 0.4em;
  padding-top: 0em;

  .ui.table {
    background-color: transparent;
    border: none;
    margin: 0.4em 0em;
    tr {
      border: none;
      padding: 0em;
    }
    td {
      border: none;
      padding: 0.4em;
    }
    h2 {
      color: $black;
      font-size: inherit;
    }
    h3 {
      color: $black;
      font-size: inherit;
    }
    &.highlight {
      border: 1px solid $color-grey-6;
      background-color: $color-bg-light-purple;
    }
    .text-container {
      background-color: $color-white;
      text-align: center;
      border: 1px solid $color-white;
      border-radius: 5px;
      border-bottom: 2px solid $color-grey-1;
      font-size: inherit;
      padding: 1px;
      &:empty::before {
        content: "\00A0";
      }
    }
    div.actions-container {
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
    }
    div.field {
      input {
        text-align: center; //specifically requested
      }
      > .read-only {
        display: revert;
        max-width: unset !important; //otherwise the inline 22.5em rule kicks in
      }
    }
  }
}

.ui.grid.agreement-list-grid {
  font-weight: normal;
  font-size: 1.2em;
}

.content-container .ui.selectable.table.schedules-list {
  border-top: 1px solid $color-grey-1;
  border-left: 1px solid $color-grey-1;
  th {
    background-color: $color-light-grey;
  }
  td,
  th {
    border-right: 1px solid $color-grey-1;
    border-bottom: 1px solid $color-grey-1;
    &.center-aligned {
      text-align: center;
    }
  }
}

.agreements-list {
  .not-bold {
    font-weight: normal;
  }

  .agr-search-type {
    margin-left: 1em;
  }

  .accordion-title-flex {
    width: 100%;
  }
  .request-buttons {
    border-left: 1px solid $color-grey-1;
    border-right: 1px solid $color-grey-1;
    border-bottom: 1px solid $color-grey-1;
    display: flex;
    .ui.primary.button {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  h4.request {
    width: 6em;
    font-weight: 1.2em;
  }
  .manage-agreement-form {
    .spaced {
      padding: 10px;
    }
    .no-padding-left {
      padding-left: 0px !important;
    }
    .ffDHgC .ui.input,
    .ffDHgC .react-datepicker-wrapper,
    .field .ui.dropdown {
      width: 12em !important;
      opacity: 1 !important;
      min-height: 1.75rem !important;
    }
    .inline.field.sc-bdVaJa.ffDHgC {
      width: 12em !important;
    }
    .disabled.field,
    .ui.disabled.input,
    .ui.input:not(.disabled) input[disabled],
    .ui.form .disabled.fields .field,
    .ui.form .disabled.field,
    .ui.form .field :disabled,
    .ui.disabled.dropdown,
    .ui.form.banking-form .disabled.field,
    .ui.form.banking-form .ui.disabled.input,
    .ui.form.banking-form .ui.input:not(.disabled) input[disabled],
    .ui.form.banking-form .ui.form .disabled.fields .field,
    .ui.form.banking-form .ui.form .disabled.field,
    .ui.form.banking-form .ui.form .field :disabled,
    .ui.form.banking-form .ui.disabled.dropdown {
      opacity: 1 !important;
    }
  }

  .check-button {
    font-size: 0.8rem;
  }

  .action-button {
    font-size: 0.8em !important;
  }
  .hidden {
    visibility: hidden;
  }
}

.sheet-state-progress-view {
  .state-progress-shape {
    display: flex;

    .progress-circle {
      display: flex;
      width: $agreement-progress-circle-diameter;
      height: $agreement-progress-circle-diameter;
      border-radius: 50%;
      &.completed {
        background-color: $color-completed-green;
      }
      &.not-completed {
        background-color: grey;
      }

      .draft-text {
        margin: auto;
        align-self: center;
        color: white;
        i {
          margin: 0 !important;
          font-size: 0.75em;
        }
      }
    }
    .draft {
      margin-left: 1em;
      align-self: center;
      &.bold {
        font-weight: bold;
      }
    }
  }
  .vertical-line {
    margin-left: calc($agreement-progress-circle-diameter / 2);
    height: 1em;
    width: 2px;
    &.completed {
      background-color: $color-completed-green;
    }
    &.not-completed {
      background-color: grey;
    }
  }
}

.agreement-terms-and-conditions {
  height: 1.85rem;
  max-width: 22.5rem !important;
}

.checkbox-icon-container {
  display: flex;
  align-items: center;

  .ui.checkbox {
    margin-right: 1em;
    padding-top: 1em;
  }

  .disable-icon {
    cursor: pointer;
    margin-bottom: 0.5em;
  }
}

.document-recipients {
  .columns {
    display: grid;
    gap: 1em;
    grid-template-columns: auto auto;
  }
  .document-recipients-header {
    font-weight: bold;
    margin-bottom: 1em;
  }
}

$row-height: 2em;

div.ui.modal.capital-calc-info.custom-actions {
  width: 90rem;
  max-width: calc(100% - 2rem);

  .green-highlight {
    background-color: $colour-ccinfo-dealer-highlighted-row !important;
    font-weight: bold;
  }
  .purple-highlight {
    background-color: $colour-lighter-purple !important;
    font-weight: bold;
  }

  .blank {
    background-color: transparent !important;
  }

  div.content {
    padding: 0 1rem 1rem 1rem !important;
    font-size: 13px;
    h4 {
      font-size: 15px;
    }

    .admin-info-header {
      padding-top: 0 !important;
      columns: 3;
      > div {
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $color-dark-red;
        font-weight: bold;
      }
      @media (max-width: 1000px) {
        columns: 2;
      }
      @media (max-width: 800px) {
        columns: 1;
      }
    }

    .total-cfa-charges {
      padding: 0.5rem !important;
      margin-bottom: 0.5rem;
      border-style: solid;
      border-color: $color-grey-1;
      border-width: 1px;
      border-radius: 5px;

      .charges-list {
        border-style: solid;
        border-width: 1px;
        border-color: $color-grey-4;
        max-width: 30em;
        .charge-row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          border-width: 1px;
          border-color: $color-grey-4;
          border-bottom-style: solid;

          &:last-child {
            border-bottom-style: none;
          }

          &:nth-child(even) {
            background-color: $color-light-grey;
          }

          > div {
            padding: 0.25em 0.5em;
          }

          > div:first-child {
            border-right-width: 1px;
            border-right-style: solid;
          }
        }
      }
    }

    .cfa-charge-split,
    .investment-payout {
      padding-bottom: 0px !important;
      .columns-4 {
        grid-template-columns: 2fr 1fr 1fr 1fr !important;
      }
      &.data-grid {
        border-top: none !important;
        border-left: none !important;
      }
      div.cell:nth-child(1) {
        border-left: 1px solid $color-grey-4;
      }
      .cell {
        text-align: center;
      }
      .cell:first-of-type {
        text-align: right;
      }
      .row.row:first-child {
        background-color: transparent !important;
        div.cell {
          text-align: center;
          background-color: $colour-lighter-purple;
          border-top: 1px solid $color-grey-4;
          border-bottom: 1px $color-grey-4 solid;
          clear: both;
        }

        div.cell:nth-child(1) {
          background-color: transparent;
          border-top: none;
          border-left: none;
        }
      }
      .row:last-child {
        div.cell {
          padding-bottom: 0px !important;
        }
      }
    }

    h4.funder-types-title {
      margin: 0;
      padding: 0.5em 0 !important;
    }

    .funder-types {
      padding: 0 !important;
      .ui.steps {
        margin-bottom: 0;
        .step {
          padding: 0.25em 1em;
          background-color: $color-light-grey;
          border-radius: unset;
          &.active {
            background-color: $colour-lighter-purple;
          }
          &:after {
            content: none;
          }
        }
      }

      .data-grid {
        border-width: 1px;
        border-color: $color-grey-4;
        border-style: solid;

        &.payout {
          border-width: 1px 0px 0px 0px;
          .row {
            border-color: $color-grey-4;
            border-style: solid;
            border-width: 0px 1px;
          }
          .header {
            border-color: $color-grey-4;
            border-style: solid;
            border-width: 0px 1px 1px 1px;
          }
        }

        .row {
          &.highlighted-first-row {
            text-align: center;
            font-weight: bold;

            .cell:nth-child(1),
            .cell:nth-child(3),
            .cell:nth-child(5) {
              background-color: $colour-ccinfo-funder-highlighted-row !important;
              z-index: 10;
              line-height: 1.2;
              border-bottom-style: solid;
              border-bottom-width: 1px;
            }
            &.dealerfunded .cell:nth-child(1),
            &.dealerfunded .cell:nth-child(3),
            &.dealerfunded .cell:nth-child(5) {
              background-color: $colour-ccinfo-dealer-highlighted-row !important;
              z-index: 10;
              line-height: 1.2;
            }
            &.cofunded .cell:nth-child(1),
            &.cofunded .cell:nth-child(3),
            &.cofunded .cell:nth-child(5) {
              background-color: $colour-ccinfo-cofunded-highlighted-row !important;
              z-index: 10;
              line-height: 1.2;
            }

            &.green-highlight.cel,
            &.blue-highlight.cell {
              border: 1px solid $color-grey-4 !important;
              font-style: italic;
            }
            &.dealerfunded {
              background-color: $colour-ccinfo-dealer-highlighted-row;
            }
            &.cofunded {
              background-color: $colour-ccinfo-cofunded-highlighted-row;
            }
          }
          .supplier-bg {
            background-color: $colour-ccinfo-dealer-highlighted-row;
            border-top-style: solid;
            border-bottom-style: solid;
            border-width: 1px;
          }
          &.total {
            font-weight: bold;
          }
        }

        &.highlighted-last-row {
          .row:last-child {
            font-weight: bold;
            border-top-style: solid;
            border-width: 1px;
          }
          &.funder .row:last-child {
            background-color: $colour-ccinfo-funder-highlighted-row;
          }
          &.dealerfunded .row:last-child {
            background-color: $colour-ccinfo-dealer-highlighted-row;
          }
          &.payout.dealerfunded .row:last-child {
            background-color: $colour-ccinfo-funder-highlighted-row;
          }
          &.cofunded .row:last-child {
            background-color: $colour-lighter-purple;
          }
          &.payout.cofunded .row:last-child {
            background-color: $colour-ccinfo-funder-highlighted-row;
          }
        }

        &.funder .row:nth-child(2).highlighted {
          background-color: $colour-ccinfo-funder-highlighted-row;
          font-weight: normal;
          border-bottom-style: solid;
          border-width: 1px;
        }
        &.dealerfunded .row:nth-child(2).highlighted {
          background-color: $colour-ccinfo-dealer-highlighted-row;
          font-weight: bold;
          border-bottom-style: solid;
          border-width: 1px;
        }

        &.cofunded .row:nth-child(2).highlighted {
          background-color: $colour-ccinfo-cofunded-highlighted-row;
          font-weight: bold;
          border-bottom-style: solid;
          border-width: 1px;
        }
        &.cp-terminus-container {
          min-height: 400px;
        }
        &.coterminus {
          text-align: center;
          max-height: 16em;
          overflow-y: auto;
          padding: 0px !important;
          border-top: none;
          .header {
            position: sticky !important;
            top: 0em;
            z-index: 1;
            border-right: 1px solid;
            border-top: 1px solid;
          }
          .column-header {
            position: sticky !important;
            top: 2em;
            z-index: 1;
          }
          &.data-grid {
            border-bottom: none;
            border-right: none;
          }
          div.cell:nth-child(3) {
            border-right: 1px $color-grey-4 solid;
          }
          .row.row:last-child {
            background-color: $sheet-funder-background;
            position: sticky !important;
            bottom: 0px;
            div.cell:nth-child(1) {
              background-color: $colour-ccinfo-funder-highlighted-row;
              border-bottom: 1px $color-grey-4 solid;
              padding-bottom: 0px !important;
            }
            div.cell:nth-child(3) {
              border-left: none;
              border-right: none;
            }
          }
          &.dealerfunded {
            .row.row:last-child {
              background-color: $sheet-supplier-background;
              div.cell:nth-child(1) {
                background-color: $colour-ccinfo-dealer-highlighted-row;
              }
            }
          }
          &.cofunded {
            .row.row:last-child {
              background-color: $sheet-co-funded-background;
              div.cell:nth-child(1) {
                background-color: $colour-ccinfo-cofunded-highlighted-row;
              }
            }
          }
        }

        .header,
        .cell {
          padding: 0.25em 0.5em;
        }
        .header,
        .row {
          height: $row-height;
        }
        .header {
          font-weight: bold;
          border-width: 1px;
          border-color: $color-grey-4;
          border-bottom-style: solid;
          background-color: $colour-lighter-purple;
          text-align: center;
          &.secondary {
            background-color: $color-bg-light-yellow;
          }
        }
        .row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          background-color: $white;
          &:nth-child(odd) {
            background-color: $color-light-grey;
          }
          &.columns-1 {
            grid-template-columns: 1fr;
          }
          &.columns-3 {
            grid-template-columns: 1fr 1fr 1fr;
          }
          &.columns-4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
          &.columns-5 {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          }
          &.column-header {
            font-weight: bold;
          }
          .cell:not(:first-child) {
            border-width: 1px;
            border-color: $color-grey-4;
            border-left-style: solid;
          }
          &.admin {
            color: green;
          }
          &.ip {
            color: blue;
          }
        }
        .row.green-highlight,
        .row.blue-highlight {
          .cell {
            border-top: 1px solid black !important;
            border-bottom: 1px solid black !important;
            background-color: $colour-ccinfo-dealer-highlighted-row !important;
          }
        }
        .row.blue-highlight {
          font-weight: bold;
          .cell {
            border-top: 1px solid black !important;
            border-bottom: 1px solid black !important;
            background-color: $color-light-blue-alt !important;
          }
        }
        .row.blank {
          border: none !important;
          .cell {
            border: none !important;
          }
        }
        &.capital-investment .row,
        &.cfa-charge .row,
        &.yield-info .row,
        &.payout .row,
        &.investment .row {
          grid-template-columns: 65% 35%;
        }

        &.capital-investment {
          .row:last-child {
            bottom: 0px;
            .cell {
              padding-bottom: 0px !important;
            }
          }
        }

        &.settlements {
          max-height: 14.2em;
          overflow-y: auto;
          padding: 0px !important;
          .header {
            position: sticky !important;
            top: 0em;
            z-index: 1;
          }
          .row {
            .cell {
              text-align: center;
              vertical-align: top;
            }
          }
          .row.row:last-child {
            display: grid;
            grid-template-columns: 2fr 1fr;
            position: sticky;
            bottom: 0px;
            .cell {
              padding-bottom: 0px !important;
            }
          }
        }

        &.fmv-fields {
          margin-top: calc($row-height * 2);
        }

        &.liquidity-charges {
          .row:last-child {
            font-weight: bold;
          }
        }
      }
      @media (max-width: 1000px) {
        .breakdown-wrapper {
          max-width: 1000px;
          display: flex;
          overflow-x: auto;
          position: relative;
        }
      }

      .breakdown {
        display: grid;
        grid-template-columns: 2fr 5fr 5fr;
        grid-gap: 0.25em;
        min-width: 70em;
        padding: 0.5em 0;
        .row {
          width: 100%;
          &.columns-3 {
            grid-template-columns: 1fr 0.3em 1fr;
          }
          &.columns-5 {
            grid-template-columns: 1fr 0.3em 1fr 0.3em 1fr;
          }
        }
        .cell {
          text-align: center;
        }
        .data-grid.right-align {
          .cell {
            text-align: right;
          }
        }
        .data-grid.rate-table {
          .row:last-of-type,
          .row:nth-child(2) {
            font-weight: bold;
          }
        }
        .used-override-table.funder {
          .cell:nth-child(2),
          .cell:nth-child(4) {
            background-color: $sheet-funder-background;
          }
        }
        .used-override-table.dealerfunded {
          .cell:nth-child(2),
          .cell:nth-child(4) {
            background-color: $sheet-supplier-background;
          }
        }
        .used-override-table.cofunded {
          .cell:nth-child(2),
          .cell:nth-child(4) {
            background-color: $sheet-co-funded-background;
          }
        }
        .used-override-table {
          border-bottom: none;
          .cell:nth-child(3),
          .cell:nth-child(5) {
            &:nth-child(odd) {
              background-color: $color-light-grey;
            }
          }
          .row:last-of-type {
            .cell:nth-child(1),
            .cell:nth-child(3),
            .cell:nth-child(5) {
              border-bottom: 1px solid $color-grey-4;
            }
          }
        }

        .used-override-table.cell-top-border {
          border-top: none;
          .row:nth-child(1) {
            .cell:nth-child(1),
            .cell:nth-child(3),
            .cell:nth-child(5) {
              border-top: 1px solid $color-grey-4;
            }
          }
        }

        .data-grid.highlighted-last-row {
          .row.funder {
            background-color: $sheet-funder-background !important;
          }
          .row.dealerfunded {
            background-color: $sheet-supplier-background !important;
          }
          .row.cofunded {
            background-color: $sheet-co-funded-background !important;
          }
          border: none !important;
          .highlighted-first-row.funder,
          .highlighted-first-row.dealerfunded,
          .highlighted-first-row.cofunded,
          .cell {
            border: none !important;
          }
          .highlighted-first-row.funder,
          .highlighted-first-row.cofunded,
          .highlighted-first-row.dealerfunded {
            .cell:nth-child(1),
            .cell:nth-child(3),
            .cell:nth-child(5) {
              line-height: 1;
              border: 1px solid $color-grey-4 !important;
            }
          }
        }
      }

      .funder-type-section {
        padding: 0.75em;

        &.funder {
          background-color: $sheet-funder-background;
        }
        &.supplier {
          background-color: $sheet-supplier-background;
        }
        &.cofunded {
          background-color: $sheet-co-funded-background;
        }

        .summary {
          display: grid;
          grid-template-columns: 4fr 3fr 3fr;
          grid-gap: 2rem;
          margin-bottom: 0rem;
          @include customBreakpoint("max-width: 1100px") {
            grid-template-columns: 1fr 1fr;
          }
          @include customBreakpoint("max-width: 900px") {
            grid-template-columns: 1fr;
          }
        }

        .summary-footer {
          display: grid;
          grid-template-columns: 4fr 6fr;
          grid-gap: 0.75rem;
          margin-bottom: 1rem;
          @include customBreakpoint("max-width: 1100px") {
            grid-template-columns: 1fr;
          }
        }

        .yield-grid {
          display: grid;
          text-align: center;
          grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr;
          grid-gap: 0.75rem;
          margin-bottom: 1rem;
          @include customBreakpoint("max-width: 1100px") {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          }
          .number-formatter {
            margin-left: 1em;
          }
        }

        .coFundPercentage {
          .number-formatter {
            margin-left: 1em;
          }
        }

        @media (max-width: 600px) {
          .charges-wrapper {
            max-width: 600px;
            display: flex;
            overflow-x: auto;
            position: relative;
          }
        }

        .charges {
          display: grid;
          margin-top: 2em;
          grid-template-columns: 1fr 2fr;
          grid-gap: 1.5rem;
          @include customBreakpoint("max-width: 1100px") {
            grid-template-columns: 1fr;
          }

          .cfa-yield-container,
          .charges-left-container {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 1.5em;
          }

          .charges-right-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            &.dealer {
              grid-template-rows: 1fr 10fr;
            }
            grid-gap: 1.5em;
            @include customBreakpoint("max-width: 1000px") {
              grid-template-columns: 1fr;
              &.dealer {
                margin-top: -5em;
              }
            }
          }

          .total-ips-income {
            .cell {
              background-color: $color-light-blue-alt;
              border-bottom: 1px solid $color-grey-4;
            }
          }
        }
      }

      .total-investment {
        border-top: none;
        line-height: 1;
      }

      .six-five-grid {
        display: grid;
        grid-template-columns: 6fr 5fr;
        grid-gap: 1.5em;
      }
      .two-three-grid {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-gap: 1.5em;
      }
      .three-col-grid {
        margin-top: 1.5em;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1.5em;
      }
      .four-col-grid {
        margin-top: 1.5em;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 1.5em;
      }
      .one-col-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1.5em;
      }
      @media (max-width: 1000px) {
        .six-five-grid,
        .three-col-grid,
        .one-col-grid {
          grid-template-columns: 1fr;
        }
      }
      @media (max-width: 1450px) {
        .cofunded .three-col-grid.footer {
          grid-template-columns: 1fr 1fr;
        }
      }
      .padded {
        padding: 1em;
      }
    }
  }
}

.divided-popup {
  padding: 0 !important;
  max-width: none !important;

  .divided-popup-content {
    display: flex;
    flex-direction: column;
    padding: 1em 0 0 0;

    .main-content {
      padding: 0.5rem 2rem 1rem 2rem;
    }

    .secondary-content {
      padding: 1rem 2rem 1rem 2rem;
      background-color: $background-grey;
    }
    .tertiary-content {
      padding: 1rem 2rem 1rem 2rem;
      background-color: $background-grey;
    }
  }
}

.popup-nav-btn.ui.button {
  background-color: transparent;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
}
